import {createResource, deleteResource, getResource, updateResource} from "../actions/resource";

export const getDataSelect = ({dispatch, user}) => ({query, resource}) => {
    dispatch(getResource({
        user: user,
        query: query,
        resource: resource
    }))
};

export const createDataSelect = ({dispatch, user}) => ({params, query, resource, piggyResource, notificationMessage}) => {
    dispatch(createResource({
        user: user,
        params: params,
        query: query,
        resource: resource,
        piggyResource: piggyResource,
        notificationMessage: notificationMessage,
    }))
};

export const updateDataSelect = ({dispatch, user}) => ({params, query, resource, piggyResource, secondPiggyResource, notificationMessage}) => {
    dispatch(updateResource({
        user: user,
        params: params,
        query: query,
        resource: resource,
        piggyResource: piggyResource,
        secondPiggyResource: secondPiggyResource,
        notificationMessage: notificationMessage,
    }))
};

export const deleteDataSelect = ({dispatch, user}) => ({query, resource, piggyResource, notificationMessage}) => {
    dispatch(deleteResource({
        user: user,
        query: query,
        resource: resource,
        piggyResource: piggyResource,
        notificationMessage: notificationMessage,
    }))
}
