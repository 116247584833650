import React, {Component} from 'react';
import Select, {components, createFilter} from 'react-select';
import {getCustomStyles} from "../../styles/theme-styles"

const CustomOption = props => {
    const {data, className} = props;
    return <components.Option {...props} className={className + (data.className ? " " + data.className : "")}/>;
};

export default class FieldSelectSearch extends Component {

    constructor(props) {
        super(props);
        this.state = {}
    }

    render() {
        const pairs = this.props.values ? this.props.values : {};

        let values = Object.keys(pairs);

        if (!this.props.omitSort) {
            values = values.sort((a, b) => {
                return (pairs[a] == pairs[b]) ? 0 : ((pairs[a] > pairs[b]) ? 1 : -1);
            });
        }

        if (!!this.props.valueSort) {
            values = Object.keys(pairs).sort((a, b) => {
                return pairs[a] - pairs[b]
            });
        }

        if (!!this.props.reverseValueSort) {
            values = Object.keys(pairs).sort((a, b) => {
                return pairs[b] - pairs[a]
            });
        }

        values = values.map((key, i) => ({value: key, label: pairs[key]}));

        const filterConfig = {
            ignoreCase: true,
            ignoreAccents: true,
            trim: true,
            matchFrom: 'any',
        };
        return (
            <React.Fragment>
                <Select
                    ref={this.props.innerRef}
                    placeholder={this.props.placeholder ? this.props.placeholder : "Select..."}
                    isMulti={this.props.isMulti}
                    onChange={(selectedOption) => {
                        this.props.onChange(this.props.name, selectedOption ? (!this.props.options ? selectedOption.value : selectedOption) : "");
                    }}
                    styles={getCustomStyles()}
                    className={"form-react-select " + (this.props.className)}
                    value={!this.props.options ? (this.props.value ? {
                        value: this.props.value,
                        label: pairs[this.props.value]
                    } : null) : this.props.value}
                    isClearable={!!this.props.all}
                    isSearchable={true}
                    isDisabled={this.props.disabled}
                    name={this.props.name}
                    options={this.props.options ? this.props.options : values}
                    filterOption={createFilter(filterConfig)}
                    components={{Option: CustomOption}}
                    autoFocus={this.props.autoFocus}
                    menuPortalTarget={this.props.menuPortalTarget}
                />
                {this.props.errorMessage && (
                    <div className={"text-red-700 italic mt-1 text-sm"}>
                        {this.props.errorLabelMessage ? this.props.errorLabelMessage : "Please fill out this field."}
                    </div>
                )}
            </React.Fragment>
        );
    }
}