import {Popover, Transition} from '@headlessui/react'
import {BellIcon, CheckIcon, ExclamationIcon, XIcon} from '@heroicons/react/solid'
import React, {Fragment} from 'react'
import {getButtonType, getProp, toFrontDateTime} from "../../util/util";
import {Link} from "react-router-dom";

export default function NotificationsDropdown(props) {
    const {notifications, unreadCount, onClick, onActionClick} = props;

    const notificationList = getProp(notifications, "list", [])
        .map(it => it)
        .reverse()
        .slice(0, 5);

    function classNames(...classes) {
        return classes.filter(Boolean).join(' ')
    }

    const getButtons = (actions) => {
        if (!actions) actions = [];

        return actions.map((it, i) => {
            return (
                <button
                    className={classNames("btn justify-center max-w-26", getButtonType(it.Data?.btnType, i))}
                    onClick={() => onActionClick(it.Name, it.Data)}
                >
                    {it.Name}

                </button>
            )})
    }

    return (
        <div className=""
             onClick={onClick}
        >
            <Popover className="relative">
                {({open}) => (
                    <>
                        <div className="relative mr-3">
                            <Popover.Button
                                className="bg-secondary-100 p-1 rounded-full text-secondary-400 hover:text-secondary-500 focus:outline-none focus:ring-2 focus:ring-primary-500 focus:ring-offset-2 focus:ring-offset-secondary-100"
                            >
                                <BellIcon
                                    className={`${open ? '' : 'text-opacity-70'} h-6 w-6`}
                                    aria-hidden="true"
                                />
                            </Popover.Button>

                            {!open && !!unreadCount && (
                                <span
                                    className="flex justify-center items-center absolute -top-1.5 -right-1 h-5 w-5 p-.5 text-sm absolute right-1 top-0 bg-red-500 rounded-full text-white font-bold border-2 border-secondary-100">
                                    <span className="relative z-10">{unreadCount}</span>
                                    <span
                                        className="animate-ping absolute inline-flex h-full w-full rounded-full bg-red-400 opacity-75"/>
                                </span>
                            )}
                        </div>

                        <Transition
                            show={open}
                            as={Fragment}
                            enter="transition ease-out duration-200"
                            enterFrom="opacity-0 translate-y-1"
                            enterTo="opacity-100 translate-y-0"
                            leave="transition ease-in duration-150"
                            leaveFrom="opacity-100 translate-y-0"
                            leaveTo="opacity-0 translate-y-1"
                        >
                            <Popover.Panel
                                className={"absolute z-10 w-screen px-4 mt-3 transform -translate-x-2/3 left-1/2 sm:px-0  " + (!!notificationList && (notificationList.length > 0) ? "max-w-lg" : "max-w-sm")}>
                                <div className="overflow-hidden rounded-lg shadow-lg ring-1 ring-black ring-opacity-5">

                                    {!!notificationList && (notificationList.length > 0) && (
                                    <ul role="list" className="divide-y divide-gray-200 bg-white max-h-[80vh] overflow-y-auto">
                                        {notificationList.map((notificationItem) => (
                                            <li key={notificationItem.ID}
                                                className="py-4 flow-root px-3 py-2 transition duration-150 ease-in-out hover:bg-gray-100 focus:outline-none focus-visible:ring focus-visible:ring-orange-500 focus-visible:ring-opacity-50 cursor-pointer">
                                                <div className="flex space-x-3">
                                                    <div>
                                                        <span
                                                            className={classNames(
                                                                (notificationItem?.Metadata?.Type == 1 ? "bg-yellow-400" : (notificationItem?.Metadata?.Type == 2 ? "bg-green-500" : ( notificationItem?.Metadata?.Type == 3 ? "bg-red-500" : "bg-primary-500"))),
                                                                'h-8 w-8 rounded-full flex items-center justify-center ring-8 ring-white'
                                                            )}
                                                        >
                                                            {notificationItem?.Metadata?.Type === 1 && (
                                                                <ExclamationIcon className="h-5 w-5 text-white" aria-hidden="true" />
                                                            )}
                                                            {notificationItem?.Metadata?.Type === 2 && (
                                                                <CheckIcon className="h-5 w-5 text-white" aria-hidden="true" />
                                                            )}
                                                            {notificationItem?.Metadata?.Type === 3 && (
                                                                <XIcon className="h-5 w-5 text-white" aria-hidden="true" />
                                                            )}
                                                            {notificationItem?.Metadata?.Type > 3 && (
                                                                <BellIcon className="h-5 w-5 text-white" aria-hidden="true" />
                                                            )}
                                                        </span>
                                                    </div>

                                                    <div className="flex-1 space-y-2">
                                                        <div className="flex items-center justify-between">
                                                            <h3 className="text-sm font-medium">
                                                                {notificationItem.Title}

                                                                {notificationItem.unread && <span
                                                                    className="inline-flex items-center px-3 py-0.5 rounded-full text-xs uppercase bg-green-100 text-green-600 font-bold ml-3">New</span>}
                                                            </h3>

                                                            <p className="text-sm text-gray-500">{toFrontDateTime(notificationItem.DateTime)}</p>
                                                        </div>
                                                        <p className="text-sm text-gray-500">
                                                            {notificationItem.Message}
                                                        </p>

                                                        {notificationItem?.Metadata?.Actions && (
                                                            <div className="flex justify-end space-x-3">
                                                                {getButtons(notificationItem?.Metadata?.Actions)}
                                                            </div>
                                                        )}
                                                    </div>
                                                </div>
                                            </li>
                                        ))}
                                    </ul>
                                    )}

                                    {/*Additional menu items*/}

                                    <div className="p-4 bg-gray-50">
                                        {!!notificationList && (notificationList.length > 0) && (
                                            <Link to={"/notifications"}
                                                  className="flow-root px-2 py-2 transition duration-150 ease-in-out rounded-md hover:bg-gray-100 focus:outline-none focus-visible:ring focus-visible:ring-orange-500 focus-visible:ring-opacity-50"
                                            >
                                                <React.Fragment>
                                                    <span
                                                        className="flex items-center">
                                                        <span className="text-sm font-medium text-gray-900">
                                                          See All
                                                        </span>
                                                    </span>

                                                    <span className="block text-sm text-gray-500">
                                                        Go to the notification page
                                                    </span>
                                                </React.Fragment>
                                            </Link>
                                        )}

                                        {(!notificationList || (notificationList.length === 0)) && (
                                            <div className="text-center">
                                                <span className="text-sm text-gray-900 text-center">
                                                    No New Notifications for You. So far so good.
                                                </span>
                                            </div>
                                        )}
                                    </div>

                                </div>
                            </Popover.Panel>
                        </Transition>
                    </>
                )}
            </Popover>
        </div>
    )
}