import {Link} from "react-router-dom";

const LayoutDashboardFooter = () => (
    <footer>
        <div className="max-w-3xl mx-auto px-4 sm:px-6 lg:px-8 lg:max-w-7xl">
            <div
                className="border-t border-gray-200 py-5 text-sm text-gray-500 text-center md:text-left md:flex-row flex flex-col-reverse  items-center">
                <div className="block md:inline">&copy; 2022 Productivity | All rights reserved.</div>{' '}

                <Link
                    className="btn btn-text font-medium md:ml-auto uppercase"
                    to={"/privacy-policy"}>Privacy Policy</Link>
            </div>
        </div>
    </footer>
)

export default LayoutDashboardFooter;