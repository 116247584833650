import React, {Component} from "react";
import {getRandomID} from "../../util/util";
import FieldTimeDropdown from "./field-time-dropdown";

export default class FieldTime extends Component {
    constructor(props) {
        super(props);

        this.state = {
            selectedOption: this.props.isMilitaryTimeFormat ? "0000" : "1200am",
            isTimeValid: true,
            isDropdownVisible: false,
            isDropdownAllowed: true,
            options: []
        }

        this.inputRef = React.createRef();
        this.dropdownRef = React.createRef();
        this.randomID = getRandomID();
        // this.minutes = ["00", "15", "30", "45"];
        this.minutes = ["00"];
        this.hoursOnly = true; // make as prop
    }

    /** Lifecycle
     ================================================================= */
    componentDidMount() {
        this.getOptions();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.props.value) {
            this.focusClosestMatch();
        }
    }

    /** Data Events
     ================================================================= */
    getOptions = () => {
        const hours = 24;

        let options = [];

        for (let h = 0; h < hours; h++) {
            for (let m = 0; m < this.minutes.length; m++) {
                const thisHour = this.props.isMilitaryTimeFormat ? h : (h > 12 ? h - 12 : h);
          
                let hour = thisHour + ""; // make it a string

                if (hour.length < 2) {
                    hour = "0" + hour;
                }

                if (!this.props.isMilitaryTimeFormat && hour === "00") {
                    hour = "12"
                }

                options.push(hour + this.minutes[m] + (this.props.isMilitaryTimeFormat ? "" : h < 12 ? " am" : " pm"));

            }
        }

        this.setState({options});
    }

    renderOptions = () => {
        return this.state.options.map(option => {
            let hour = option.substring(0, 2);
            let minutes = option.substring(2, 4);
            let period = option.substring(4, 7);
            const numericValue = this.getNumbersFromString(this.props.value);
            const valueLength = numericValue && numericValue.length;
            return (
                <div
                    key={option}
                    data-value={option}
                    className={"cursor-pointer font-inherit px-2 py-1 custom-dropdown-option select-none hover:text-white hover:bg-primary-700 " + (this.state.isTimeValid && valueLength !== 3 && this.state.selectedOption === option ? " text-white bg-primary-600" : " text-secondary-600")}
                    onClick={() => this.handleOptionClick(option)}
                >
                    {hour + ":" + minutes + period}
                </div>
        )});
    }

    handleOnFocus = () => {
        this.setState({
            isDropdownVisible: this.state.isDropdownAllowed
        })
    }

    handleOptionClick = (HHmm) => {
        this.setState({
            selectedOption: HHmm,
            isDropdownVisible: false,
            isDropdownAllowed: false
        }, () => {
                this.inputRef.current.focus();
                this.props.onChange(this.props.name, this.convertToTimeFormat(this.state.selectedOption));
            }
        );
    }

    handleInputClick = () => {
        this.openDropDown(true);
    }


    onKeyDown = (e) => {
        let isDropdownVisible = true;
        let isDropdownAllowed = true;

        if (e.key === 'Enter') {
            this.formatTime();
            isDropdownVisible = false;
        }

        if (e.keyCode === 38) {
            e.preventDefault();

            const activeElementIndex = this.state.options.indexOf(this.state.selectedOption);

            const selectedOption = this.state.options[activeElementIndex - 1];

            if (activeElementIndex > 0) {
                this.setState({selectedOption: selectedOption}, () =>  this.props.onChange(this.props.name, this.convertToTimeFormat(this.state.selectedOption)));
            }
        }

        if (e.keyCode === 40) {
            e.preventDefault();
            const activeElementIndex = this.state.options.indexOf(this.state.selectedOption);

            const selectedOption = this.state.options[activeElementIndex + 1];

            if (activeElementIndex < this.state.options.length - 1) {
                this.setState({selectedOption: selectedOption}, () =>  this.props.onChange(this.props.name, this.convertToTimeFormat(this.state.selectedOption)));
            }
        }

        if (e.keyCode === 9) {
            // Tab
            this.formatTime();
            isDropdownVisible = false;
            isDropdownAllowed = false;
        }

        this.setState({
            isDropdownVisible: isDropdownVisible,
            isDropdownAllowed: isDropdownAllowed
        })
    }

    /** Helpers
     ================================================================= */
    hideDropdown = () => {
        this.setState({isDropdownVisible: false})
    }

    openDropDown = (dropdownAllowedOverride = false) => {
        this.setState({
            isDropdownVisible: dropdownAllowedOverride ? true : this.state.isDropdownAllowed
        })
    }

    getNumbersFromString = (number) => {
        const numberPattern = /\d+/g;
        number = number.match( numberPattern );
        if (!number) return 0;

        return number.join('');
    }

    convertToTimeFormat = (number) => {
        if (!number) {
            return "0000";
        }

        let time;

        const separator = ":";
        if (number.length == 3) {
            number = "0" + number;
        }

        time = number.substring(0,2) + separator + number.substring(2)

        return time;
    }

    formatHoursAndMinutesNumber = (number) => {
        const trailingZeroes = "000"; // if there are less than 4 numbers inputted

        return this.getNumbersFromString(number) + trailingZeroes;
    }

    getHoursAndMinutes = () => {
        const numberOfTimeDigits = 4;
        const trailingZeroes = "000";
        let period = this.props.isMilitaryTimeFormat ? "" : this.state.selectedOption.substring(4, 7);
        let numbersFromString = this.formatHoursAndMinutesNumber(this.props.value);
        let numericValue = this.getNumbersFromString(this.props.value);
        
        numbersFromString = numbersFromString + trailingZeroes;

        if (numericValue.length == 1 || numericValue.length == 3) {
            const leadingZero = "0";
            numbersFromString = leadingZero + numbersFromString
        }

        if (this.isStringContainingP()) {
            period = " pm"
        }

        return numbersFromString.substring(0, numberOfTimeDigits) + period;
    }

    focusClosestMatch = () => {
        const startingValue = this.getNumbersFromString(this.props.value);
        const dropdown = this.dropdownRef;
        let selected = dropdown.current && dropdown.current.querySelector(('[data-value^="' + startingValue + '"]'));

        if (selected && !this.props.isMilitaryTimeFormat && this.isStringContainingP()) {
            const numberOnlyValue = selected.dataset.value.substring(0, selected.dataset.value.length - 2);
            selected = dropdown.current.querySelector('[data-value="' + numberOnlyValue + ' pm"]');
        }

        if (selected) {
            selected.parentNode.scrollTop = selected.offsetTop;
            if (this.state.selectedOption !== selected.dataset.value) {
                this.setState({selectedOption: selected.dataset.value})
            }
        }
    }

    formatHoursAndMinutes = (hoursAndMinutes) => {
        const hourMinuteSeparator = ":";
        if (!!this.hoursOnly) {
            return hoursAndMinutes.substring(0,2) + hourMinuteSeparator + "00" + hoursAndMinutes.substring(4);
        }
        return hoursAndMinutes.substring(0,2) + hourMinuteSeparator + hoursAndMinutes.substring(2);
    }

    validateTime = (hoursAndMinutes) => {
        if (this.props.isMilitaryTimeFormat) {
            return this.validateMilitaryTime(hoursAndMinutes);
        } else {
            return this.validatePeriodTime(hoursAndMinutes);
        }
    }

    validateMilitaryTime = (hoursAndMinutes) => {
        if (hoursAndMinutes > 2400 || hoursAndMinutes < 0 || hoursAndMinutes[2] > 5) {
            this.setState({isTimeValid: false})
            return "0000";
        } else {
            this.setState({isTimeValid: true})
            return hoursAndMinutes == 24 ? "0000" : hoursAndMinutes;
        }
    }

    validatePeriodTime= (hoursAndMinutes) => {
        const timeLimit = 1259;
        const time = hoursAndMinutes.slice(0, -2)

        if (time > timeLimit || time < 0 || time[2] > 5) {
            this.setState({isTimeValid: false})
            return "1200 am";
        } else {
            this.setState({isTimeValid: true})
            return hoursAndMinutes;
        }
    }

    formatTime = () => {
        if (this.props.value) {
            let hoursAndMinutes = this.getHoursAndMinutes();

            hoursAndMinutes = this.validateTime(hoursAndMinutes)
            hoursAndMinutes = this.formatHoursAndMinutes(hoursAndMinutes);
            this.props.onChange(this.props.name, hoursAndMinutes);
        }
    }

    isStringContainingP = () => {
        return this.props.value.indexOf("p") !== -1 || this.props.value.indexOf("P") !== -1;
    }

    /** Render
     ================================================================= */
    render() {
        return (
            <div className="relative">
                {!this.state.isTimeValid && (
                    <div className="absolute bottom-5 flex flex-col mb-6">
                        <span
                            className="relative z-10 p-2 rounded-md text-xs leading-none text-white whitespace-no-wrap bg-black shadow-lg">Invalid Time</span>
                        <div className="w-3 h-3 -mt-2 ml-3 rotate-45 bg-black"></div>
                    </div>
                )}

                <input
                    autoComplete="off"
                    data-id={this.randomID}
                    ref={this.inputRef}
                    {...this.props}
                    onFocus={() => this.handleOnFocus()}
                    onKeyDown={(e) => this.onKeyDown(e)}
                    onChange={(event) => {
                        this.props.onChange(this.props.name, event.target.value);
                    }}
                    onClick={this.handleInputClick}
                    name={this.props.name}
                    value={this.props.value}
                    placeholder={this.props.placeholder}
                    disabled={this.props.disabled}
                    className={this.props.className + (this.props.errorMessage ? " is-invalid" : "")}
                />

                {this.props.errorMessage && (
                    <div className={"text-red-700 italic mt-1 text-sm"}>
                        {this.props.errorLabelMessage ? this.props.errorLabelMessage : "Please fill out this field."}
                    </div>
                )}

                {!!this.state.isDropdownVisible && (
                    <FieldTimeDropdown
                        randomID={this.randomID}
                        dropdownRef={this.dropdownRef}
                        renderOptions={this.renderOptions}
                        formatTime={this.formatTime}
                        hideDropdown={this.hideDropdown}
                    />
                )}
            </div>
        )
    }
}

