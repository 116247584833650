import React from "react";
import {Link} from "react-router-dom";
import PublicLayout from "../../components/layout";
import LocalStorage from "../../util/localStorage";
import {getHomePage} from "../../util/util";

export default function NotFound() {
    const {translate} = this.props;

    const user = LocalStorage.get("user");

    const homePagePath = !!user ? getHomePage(user) : "/"

    return (
        <PublicLayout {...this.props}>
            <div className="pt-24 px-14 pb-7 layout-min-h">
                <div className="mx-auto py-8 max-w-sm text-center text-90 text-white">
                    <div>
                        <h1 className="text-6xl mb-6">{translate("page.404.heading_main")}</h1>

                        <p className="mb-8 text-soft">
                            {translate("page.404.body")}
                        </p>

                        <Link to={homePagePath}
                              className="btn btn-primary">{translate("btn.back_to_home")}</Link>
                    </div>
                </div>
            </div>
        </PublicLayout>
    )
}