import React from 'react'

const PageHeader = (props) => (
    <div className="bg-inverse border-b-2 border-primary">
        <div className="px-4 sm:px-6 lg:mx-auto lg:px-8">
            <div
                className="py-4 sm:flex items-center justify-between">
                <div className=" min-w-0">
                    <div className="flex items-center h-10">
                        <div>
                            <div className="flex items-center">
                                <h1 className="ml-3 text-2xl font-bold leading-7 text-secondary-900 sm:leading-9 sm:truncate">
                                    {props.title}
                                </h1>
                            </div>
                        </div>
                    </div>
                </div>

                <div
                    className="flex mr-2 select-none">
                </div>

                <div className="flex mt-3 sm:mt-0 space-x-3 mt-0 md:ml-4">
                    <div className="ml-3 flex-shrink-0">
                        {props.children}
                    </div>
                </div>
            </div>
        </div>
    </div>
);

export default PageHeader;