export default class Resources {
    static User = "user";
    static Users = "users";
    static Settings = "settings";
    static UserDevices = "user/logged-devices";

    static Company = "company";
    static CompanyImage = "company/image";

    static CalendarResource = "calendar";

    static CalendarUpcoming = "calendar/upcoming";
    static CalendarStartBlock = "calendar/start-block";
    static CalendarSnoozeBlock = "calendar/snooze-block";
    static CalendarSkipBlock = "calendar/skip-block";
    static CalendarStartExercise = "calendar/start-exercise";
    static CalendarCompleteExercise = "calendar/complete-exercise";

    static LibraryResource = "library";
    static LibraryImageResource = "library/image";
    static TemplateResource = "template";
    static TemplateSingleResource = "template/single";
    static TemplateSingleItemsResource = "template/single/items";

    static NotificationsResource = "notifications";
}
