import React, {useState} from 'react';
import moment from "moment";
import {ArrowLeftIcon, ArrowRightIcon} from "@heroicons/react/solid";
import Button from "../button";
import {classNames} from "../../util/util"
import InformationCircleIcon from "@heroicons/react/outline/InformationCircleIcon";

const NUMBER_OF_DAYS_IN_WEEK = 7;

function getDaysInMonth(month, year) {
    const date = new Date(year, month, 1);
    const days = [];
    while (date.getMonth() === month) {
        days.push(new Date(date));
        date.setDate(date.getDate() + 1);
    }
    return days;
}

function getMonthsAndYears(a, b) {
    let dateStart = moment(a);
    let dateEnd = moment(b);
    let vals = [];
    while (dateEnd > dateStart || dateStart.format('M') === dateEnd.format('M')) {
        vals.push((
            <span className={"mr-1"}>
                {dateStart.format('MMMM Y')}
            </span>
        ));
        dateStart.add(1,'month');
    }
    return vals;
}

function getDaysFromTo(a, b) {
    const days = [];
    let m = moment(a)
    for (; (m.diff(b, 'days')) < 0; m.add(1, 'days')) {
        days.push(m.toDate());
    }
    m.add(1, 'days');
    days.push(m.toDate());
    return days;
}

function getFirstDayOfMonth(month, year) {
    const date = new Date(year, month, 1);
    return date.getDay(); // Returns 0 to 6, 0 denotes Sunday, 6 is Saturday
}

function renderEvent(event, onEventTap) {
    let bgClass;
    let bgHoverClass;
    let infoMessage = "Block not started";

    switch (event.CalendarStatusID) {
        case 1:
            bgClass = "bg-yellow-400 text-white";
            bgHoverClass = " transition cursor-pointer duration-500 ease hover:bg-yellow-600";
            infoMessage = "Started";
            if (event.CalendarLibraryStatusID === 1) {
                bgClass = "bg-primary-400 text-white";
                bgHoverClass = " transition cursor-pointer duration-500 ease hover:bg-primary-600";
                infoMessage = "Exercise Started";
            }
            break;
        case 2:
            bgClass = "bg-green-400 text-white";
            bgHoverClass = " transition cursor-pointer duration-500 ease hover:bg-green-600";
            infoMessage = "Completed";
            break;
        default:
            bgClass = "";
            bgHoverClass = "border text-secondary-600 transition cursor-pointer duration-500 ease hover:bg-secondary-100";
            break;
    }

    /*
    if (moment(event.Date, "YYYY-MM-DD HH:mm:ss").format("YYYY-MM-DD") < moment().format("YYYY-MM-DD") && !event.CalendarStatusID) {
        bgClass = "bg-red-400 text-white";
        bgHoverClass = " transition cursor-pointer duration-500 ease hover:bg-red-600";
        infoMessage = "Exercise Started";
    }
    */

    const timeStart = moment(event.Date, "YYYY-MM-DD HH:mm:ss").format("h A");
    const timeEnd = moment(event.Date, "YYYY-MM-DD HH:mm:ss").add(event.BlockLength, 'hours').format("h A");

    return (
        <div
            onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                e.nativeEvent.stopImmediatePropagation();
                onEventTap && onEventTap(moment(event.Date, "YYYY-MM-DD mm:dd:ss").format("YYYY-MM-DD"))
            }}
            className={"relative text-left event mx-2 text-gray-600 rounded py-1 px-2 text-sm " + bgClass + bgHoverClass}
        >
            <div className="font-bold overflow-ellipsis md:text-md sm:text-sm text-base ellipsis pr-5">
                {event.LibraryType}
            </div>

            <div className="md:block sm:hidden block">
                {timeStart}{" - "}{timeEnd}
            </div>

            {!!event.Exercise && (
                <div className="md:block sm:hidden block">
                    {event.Exercise}
                </div>
            )}

            {!!event.Description && (
                <div className="md:block sm:hidden block">
                    {event.Description}
                </div>
            )}

            <span className="group">
                <div className="hidden group-hover:flex absolute right-0 top-fix-calendar-info flex-col mb-6">
                    <span
                        className="relative z-10 p-2 rounded-md text-xs text-black leading-none whitespace-no-wrap bg-white shadow-lg">
                            {(!!infoMessage) && (
                                <>
                                    <span className="block mb-2">Block Status: </span>
                                    <span className="block mb-2">{infoMessage}</span>
                                </>
                            )}
                    </span>
                    <div className="flex flex-row-reverse">
                        <div className="w-3 h-3 -mt-2 mr-2.5 rotate-45 bg-white"></div>
                    </div>
                </div>

                <InformationCircleIcon className="w-4 h-4 absolute top-2 right-2 cursor-pointer" />
            </span>
        </div>
    )
}

function fillArrayWithNumbers(number) {
    return [...Array(number).keys()];
}

export default function Calendar({month, year, onPrevMonth, onNextMonth, onEventTap, onDayTap, events, className, customButton, isWeekView, thisWeek, isThisWeekDisabled, StartDate, EndDate}) {
    const [isDateExpanded, setIsDateExpanded] = useState(null);

    // CSS classes
    const headClasses = "px-2 pt-2 border-r xl:w-40 lg:w-30 md:w-30 sm:w-20 w-10 xl:text-sm text-xs hidden";
    const enabledClass = "border-r min-h-[20rem] max-w-[10rem] w-40 overflow-auto transition hover:bg-gray-50 cursor-pointer";
    const disabledClass = "border bg-secondary-100 min-h-[20rem] xl:w-40 lg:w-30 md:w-30 sm:w-20 w-10 overflow-auto";

    const months = [ "January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December" ]

    // Days in months calculations
    const daysInPrevMonth = getDaysInMonth((month - 1 < 0) ? 11 : month - 1, (month - 1 < 0) ? year - 1: year);
    const daysInMonth = isWeekView ? getDaysFromTo(StartDate, EndDate) : getDaysInMonth(month, year);
    const firstDayOfMonth = getFirstDayOfMonth(month, year);

    let rows = [];

    let buffer = [];
    if (!isWeekView) {
        // Fill buffer with prev month days
        buffer = fillArrayWithNumbers(firstDayOfMonth).map((it, i) => (
            <td className={disabledClass + " align-top"}>
                <div
                    className="flex flex-col mx-auto xl:w-40 lg:w-30 md:w-30 sm:w-full w-10 mx-auto overflow-hidden">
                    <div className="top h-12 border-b w-full">
                            <span
                                className="text-secondary-500">{(daysInPrevMonth.length - firstDayOfMonth + i + 1)}</span>
                    </div>
                    <div className="bottom flex-grow h-30 py-1 w-full">
                    </div>
                </div>
            </td>
        ));
    }
    let lastMonthDate;

    // Get rows for current month days, create new row from buffer each NUMBER_OF_DAYS_IN_WEEK
    rows = daysInMonth.reduce((memo, it, i) => {
        const dayDate = moment(it).format("YYYY-MM-DD");
        let eventsHtml = events[dayDate]?.sort((a,b) => (a.Date > b.Date) ? 1 : ((b.Date > a.Date) ? -1 : 0))?.map((it) => renderEvent(it, onEventTap));

        let date = moment(it).format('DD');

        const isToday = dayDate === moment().format("YYYY-MM-DD")

        const dayLabel = moment(it).format('ddd')

        lastMonthDate = it;

        buffer.push((
            <td
                key={dayDate}
                onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    e.nativeEvent.stopImmediatePropagation();
                    onDayTap && onDayTap(dayDate)
                }}
                className={enabledClass + " align-top"}>
                <div
                    className={"flex flex-col mx-auto w-full mx-auto relative min-h-[20rem]" + (isWeekView ? " calendar-tall-view" : "")}
                >
                    <div className="border-b w-full h-20 flex flex-col items-center justify-center flex-wrap">
                        <div className="w-full text-secondary-400 font-semibold text-xs uppercase">{dayLabel}</div>

                        <div className={classNames(isToday ? "w-12 h-12 text-white bg-secondary" : "", "text-secondary-500 flex items-center justify-center mx-auto rounded-full text-xl font-normal")}>
                            {date}
                        </div>
                    </div>

                    <div className={"bottom space-y-2 flex-grow h-30 py-2 w-full" + (isDateExpanded === date ? " bg-inverse shadow z-10" : "")}>
                        {eventsHtml}
                    </div>
                </div>
            </td>
        ));

        if ((buffer.length % NUMBER_OF_DAYS_IN_WEEK) === 0) {
            memo.push(
                (
                    <tr className="text-center h-20">
                        {buffer}
                    </tr>
                )
            );
            buffer = [];
        }

        return memo;
    }, []);

    // Fill last row/line with remaining days
    if (!isWeekView) {
        fillArrayWithNumbers(NUMBER_OF_DAYS_IN_WEEK - buffer.length).forEach((it, i) => {

            let date = (i + 1);

            const dayLabel = moment(lastMonthDate).add("day", i + 1).format('ddd')
            buffer.push(<td className={disabledClass}>
                <div
                    className={"flex flex-col mx-auto w-full mx-auto relative min-h-[20rem]" + (isWeekView ? " calendar-tall-view" : "")}
                >
                    <div className="border-b w-full h-20 flex flex-col items-center justify-center flex-wrap">
                        <div className="w-full text-secondary-400 font-semibold text-xs uppercase">{dayLabel}</div>

                        <div
                            className={classNames("text-secondary-500 flex items-center justify-center mx-auto rounded-full text-xl font-normal")}>
                            {date}
                        </div>
                    </div>

                    <div
                        className={"bottom space-y-2 flex-grow h-30 py-2 w-full" + (isDateExpanded === date ? " bg-inverse shadow z-10" : "")}>
                    </div>
                </div>
            </td>)
        });
    }

    rows.push(
        (
            <tr className="text-center h-20">
                {buffer}
            </tr>
        )
    );

    return (
        <div className={"bg-inverse rounded shadow " + className}>
            <div className="flex border-b py-2 px-4 sticky left-0 space-x-3">
                <div className="flex flex-end flex-wrap items-center space-x-3">
                    <div className="flex space-x-3 items-center">
                        <Button
                            onClick={thisWeek}
                            className={"btn btn-outline font-normal " + (isThisWeekDisabled ? "cursor-disabled" : "cursor-pointer")}
                            disabled={isThisWeekDisabled}
                            title="Work in progress"
                        >
                            This week
                        </Button>
                        <button
                            type="button"
                            onClick={onPrevMonth}
                            className="inline-flex items-center p-1.5 border border-transparent rounded-full text-primary hover:bg-secondary-100 focus:outline-none focus:ring-2 focus:ring-offset-2 ring-offset-white focus:ring-primary-500"
                        >
                            <ArrowLeftIcon className="h-5 w-5" aria-hidden="true" />
                        </button>
                        <button
                            type="button"
                            onClick={onNextMonth}
                            className="inline-flex items-center p-1.5 border border-transparent rounded-full text-primary hover:bg-secondary-100 focus:outline-none focus:ring-2 ring-offset-white focus:ring-secondary-100"
                        >
                            <ArrowRightIcon className="h-5 w-5" aria-hidden="true" />
                        </button>
                    </div>

                    <div className="my-2 sm:my-0">
                        {customButton}
                    </div>
                </div>

                <span className="text-lg text-secondary-800 flex items-center">
                    {!isWeekView && (
                        <>
                            {months[month]} {year}
                        </>
                    )}
                    {isWeekView && (
                        <>
                            {getMonthsAndYears(StartDate, EndDate)}
                        </>
                    )}
                </span>
            </div>
            <table className="w-full">
                <thead>
                <tr>
                    <th className={headClasses}>
                        <span className="text-secondary-400 text-xs uppercase">Sun</span>
                    </th>
                    <th className={headClasses}>
                        <span className="text-secondary-400 text-xs uppercase">Mon</span>
                    </th>
                    <th className={headClasses}>
                        <span className="text-secondary-400 text-xs uppercase">Tue</span>
                    </th>
                    <th className={headClasses}>
                        <span className="text-secondary-400 text-xs uppercase">Wed</span>
                    </th>
                    <th className={headClasses}>
                        <span className="text-secondary-400 text-xs uppercase">Thu</span>
                    </th>
                    <th className={headClasses}>
                        <span className="text-secondary-400 text-xs uppercase">Fri</span>
                    </th>
                    <th className={headClasses}>
                        <span className="text-secondary-400 text-xs uppercase">Sat</span>
                    </th>
                </tr>
                </thead>
                <tbody>
                {rows}
                </tbody>
            </table>
        </div>
    )
}