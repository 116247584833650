import React from "react";
import {ChevronLeftIcon, ChevronRightIcon} from '@heroicons/react/solid'

const Pagination = (props) => {

    let list = [];
    let pagesNum = Math.ceil(props.count / props.limit);
    let buttonLimit = (props.paginationButtonLimit) ? props.paginationButtonLimit : 3;
    for (let i = 0; i < (props.count / props.limit); ++i) {
        list.push((
            <span
                key={i}
                onClick={() => {
                    props.updateOffset(i * props.limit, i + 1);
                }}
                className={
                    "hover:cursor-pointer relative inline-flex items-center px-4 py-2 border bg-inverse text-sm font-medium hover:bg-secondary-50"
                    + (((i * props.limit) === props.offset) ? " border-secondary text-primary " : " border-secondary-300 text-secondary-700 ")
                    + (((i > (props.paginationPage - buttonLimit - 2) && i < (props.paginationPage + buttonLimit) ? "" : "display-none")))
                }
            >
                {(i + 1)}
            </span>
        ));
    }

    const addClass = props.className ? " " + props.className : "";

    return (
        <React.Fragment>
            <div
                className={"bg-inverse px-4 py-3 flex flex-wrap sm:flex-nowrap items-center justify-between border-t border-secondary-200 sm:px-6" + (addClass)}>
                <div className="flex-full text-center mb-3 sm:hidden">
                    <p className="text-sm text-secondary-700">
                        Showing <span className="font-medium">{props.offset + 1}</span> to <span
                        className="font-medium">{(props.paginationPage === pagesNum) ? props.count : (props.offset + props.limit)}</span> of{' '}
                        <span className="font-medium">{props.count}</span> results
                    </p>
                </div>

                <div className="flex-1 flex justify-between sm:hidden">
                    {(!props.hidePrevNext && props.paginationPage > 1 &&
                        <span
                            onClick={() => (props.paginationPage > 1) ? props.updateOffset((props.paginationPage - 2) * props.limit, props.paginationPage - 1) : ""}
                            className="relative inline-flex items-center px-4 py-2 border border-secondary-300 text-sm font-medium rounded-md text-secondary-700 bg-inverse hover:text-secondary-500"
                        >
                            Previous
                        </span>
                    )}


                    {(!props.hidePrevNext && props.paginationPage < pagesNum &&
                        <span
                            onClick={() => props.updateOffset((props.paginationPage) * props.limit, props.paginationPage + 1)}
                            className="ml-auto relative inline-flex items-center px-4 py-2 border border-secondary-300 text-sm font-medium rounded-md text-secondary-700 bg-inverse hover:text-secondary-500"
                        >
                            Next
                        </span>
                    )}
                </div>

                <div className="hidden sm:flex-1 sm:flex flex-wrap sm:items-center sm:justify-between">
                    {!props.hideCaption && (
                        <div className="my-2">
                            <p className="text-sm text-secondary-700">
                                Showing <span className="font-medium">{props.offset + 1}</span> to <span
                                className="font-medium">{(props.paginationPage === pagesNum) ? props.count : (props.offset + props.limit)}</span> of{' '}
                                <span className="font-medium">{props.count}</span> results
                            </p>
                        </div>
                    )}
                    <div>
                        <nav className="relative z-0 inline-flex rounded-md shadow-sm -space-x-px"
                             aria-label="Pagination">
                            {(!props.hidePrevNext && props.paginationPage > 1 &&
                                <span
                                    onClick={() => (props.paginationPage > 1) ? props.updateOffset((props.paginationPage - 2) * props.limit, props.paginationPage - 1) : ""}
                                    className="relative inline-flex items-center px-2 py-2 rounded-l-md border border-secondary-300 bg-inverse text-sm font-medium text-secondary-500 hover:bg-secondary-50"
                                >
                                    <span className="sr-only">Previous</span>
                                    <ChevronLeftIcon className="h-5 w-5" aria-hidden="true"/>
                                </span>
                            )}

                            {list}

                            {(!props.hidePrevNext && props.paginationPage < pagesNum &&
                                <span
                                    onClick={() => props.updateOffset((props.paginationPage) * props.limit, props.paginationPage + 1)}
                                    className="relative inline-flex items-center px-2 py-2 rounded-r-md border border-secondary-300 bg-inverse text-sm font-medium text-secondary-500 hover:bg-secondary-50"

                                >
                                    <span className="sr-only">Next</span>
                                    <ChevronRightIcon className="h-5 w-5" aria-hidden="true"/>
                                </span>
                            )}
                        </nav>
                    </div>
                </div>
            </div>
        </React.Fragment>
    )
}

export default Pagination;