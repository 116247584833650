import React, {Component} from 'react'
import Resources from "../../data/services/resources";
import {fillFieldsFromData, getProp} from "../../util/util";
import {Field, FieldsManager} from "../../data/services/fields";
import moment from "moment";
import ModalSaveResource from "../../components/modal/modal-save-resource";
import {ArchiveIcon, PencilIcon, PlusIcon} from "@heroicons/react/solid";
import Loader from "../../components/loader";
import ModalConfirm from "../../components/modal/modal-confirm";

export default class ItemsTab extends Component {

    constructor(props) {
        super(props);

        this.state = {
            offset: 0,
            limit: 10,
            paginationPage: 1,
            sort: "ASC",
            sortBy: "",

            query: "",
            fields: this.getFields(),
            archived: false,

            createModalOpen: false,
            editModalOpen: false,
            confirmModalOpen: false
        };
    }

    /** Lifecycle
     ================================================================= */
    componentDidMount() {
        this.fetchData();
    };

    /** Data Events
     ================================================================= */
    fetchData = () => {
        this.props.getData({query: this.getQuery(), resource: this.getResourceName()})
    };

    /** UI Events
     ================================================================= */
    handleUpdateSort = (sortBy) => {
        this.setState({
            sortBy: sortBy,
            sort: (this.state.sortBy === sortBy) ? (this.state.sort === "ASC" ? "DESC" : "ASC") : "ASC"
        }, () => this.fetchData())
    };

    handleUpdateOffset = (offset, page) => {
        this.setState({
            offset: offset,
            paginationPage: page
        }, () => this.fetchData());
    }

    handleQueryChange = (name, value) => {
        this.setState({
            query: value,
            offset: 0,
            paginationPage: 1
        }, this.fetchData)
    }

    handleToggleCreateModal = () => {
        this.setState({
            createModalOpen: !this.state.createModalOpen
        });
    }

    handleToggleEditModal = (item = null) => {
        this.setState({
            selectedItem: item,
            editModalOpen: !this.state.editModalOpen
        });
    }

    handleToggleConfirmModal = (item = null) => {
        this.setState({
            selectedItem: item,
            confirmModalOpen: !this.state.confirmModalOpen
        });
    }

    /** Helpers
     ================================================================= */
    getId = () => {
        return this.props.id;
    }

    getPrimaryKey = () => {
        return "TemplateItemID";
    }

    getResourceName = () => {
        return Resources.TemplateSingleItemsResource;
    }

    getQuery = () => {
        return {
            TemplateID: this.getId(),
            limit: this.state.limit,
            offset: this.state.offset,
            sort: this.state.sort,
            sortBy: this.state.sortBy,
            query: this.state.query,
            archived: this.state.archived ? 1 : 0
        }
    }

    getFields = (item = null) => {
        const fieldTemplates = {
            LibraryTypeID: new Field("LibraryTypeID", '', ['empty'], false, "select"),
            Date: new Field("Date", "", ['empty'], false, "time-custom", {}, {hoursOnly: "true"}),
            BlockLength: new Field("BlockLength", '', ['empty'], false, "select"),
            LibraryLength: new Field("LibraryLength", '', ['empty'], false, "select"),
            LibraryID: new Field("LibraryID", '', ['empty'], false, "select-search"),
            Description: new Field("Description", '', [''], false, "textarea")
        };

        return fillFieldsFromData(fieldTemplates, item);
    };

    /** Render
     ================================================================= */
    render() {
        const {
            resource,
            translate,
            createData, updateData, deleteData
        } = this.props

        const data = getProp(resource, "data.list", []);

        const isLoading = getProp(resource, "isLoading", false);

        const metadata =
            {
                BlockLength: {
                    1: "1 Hour",
                    2: "2 Hours",
                    3: "3 Hours",
                    4: "4 Hours",
                },
                LibraryLength: {
                    1: "1 Minutes",
                    2: "2 Minutes",
                    3: "3 Minutes",
                    4: "4 Minutes",
                    5: "5 Minutes",
                },
                LibraryID: {
                    visibleFilter: (fields) => (!fields[0]?.value),
                    api: 'api/' + Resources.LibraryResource,
                    query: (fields) => {
                        return {
                            includeRandom: 1,
                            searchFields: JSON.stringify({
                                LibraryTypeID: fields[0]?.value
                            })
                        }
                    },
                    searchMap: (item) => ({
                        value: item.LibraryID,
                        label: item.Exercise
                    })
                }
            }

        let allRows = [];
        let occupiedRow = [];

        for (let i = 0; i < 24; i++) {
            allRows[i] = i;
            occupiedRow[i] = false;
        }

        const rows = Object.keys(allRows).map((it, i) => {
            let timeLabel = '';
            if (it > 12) {
                timeLabel = (it - 12) + " PM";
            } else {
                timeLabel = it + " AM";
            }

            let currentItem = data.filter(
                ti => moment(ti.Date).format("H") == it
            ).reduce((memo, tit) => {
                memo.push(tit);
                return memo;
            }, [])

            if (currentItem[0]) {
                let max = parseInt(currentItem[0]['BlockLength']) + parseInt(it);

                for (let y = parseInt(it) + parseInt(1); y < max; y++) {
                    occupiedRow[y] = true;
                }
            }

            return (
                <tr>
                    <td className={"border-secondary-200 p-3 text-center text-secondary-900 border-r font-bold w-3/12 h-12 " + ((occupiedRow[it] || currentItem[0] ) ? "bg-secondary-100" : "bg-secondary-50")}>{timeLabel}</td>
                    {!occupiedRow[it] && (
                        <td rowSpan={currentItem[0] && currentItem[0]['BlockLength']}
                            className={"border-secondary-200  p-3 text-center text-secondary-900 border-r w-32 h-12 " + (currentItem[0] ? "bg-secondary-100" : "bg-secondary-50")}>
                            <p className="text-2xl font-bold mb-4">
                                {!!currentItem[0] && currentItem[0]['LibraryType']}
                            </p>

                            <p className="text-sm ">
                                {!!currentItem[0] && currentItem[0]['Library']}
                            </p>
                            <p className="text-sm">
                                {!!currentItem[0] && currentItem[0]['Description']}
                            </p>

                            {currentItem[0] && (
                                <div className="block text-right mt-2">
                                    <button
                                        onClick={() => this.handleToggleEditModal(currentItem[0])}
                                        className="ml-2 inline-flex items-center px-4 py-2 border border-secondary-300 rounded-md shadow-sm text-sm font-medium text-secondary-700 bg-inverse hover:bg-secondary-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-secondary-50 focus:ring-primary-500"
                                    >
                                        <PencilIcon className="-ml-1 mr-2 h-5 w-5 text-secondary-700"
                                                    aria-hidden="true"/>
                                        Edit
                                    </button>

                                    <button
                                        onClick={() => this.handleToggleConfirmModal(currentItem[0])}
                                        className="ml-2 inline-flex items-center px-4 py-2 border border-secondary-300 rounded-md shadow-sm text-sm font-medium text-secondary-700 bg-inverse hover:bg-secondary-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-secondary-50 focus:ring-primary-500"
                                    >
                                        <ArchiveIcon className="md:-ml-1 md:mr-2 h-5 w-5 text-secondary-400"
                                                     aria-hidden="true"/>

                                        <span className="hidden md:inline">Delete</span>
                                    </button>

                                </div>
                            )}
                        </td>
                    )}
                </tr>
            );
        })

        return (
            <>
                <div className="">
                    <div className="flex flex-wrap">

                        <div className="p-6 ml-auto">
                            <button
                                type="button"
                                onClick={this.handleToggleCreateModal}
                                className="btn btn-primary focus:ring-offset-inverse font-normal"
                            >
                                <PlusIcon className="-ml-1 mr-1 h-5 w-5" aria-hidden="true"/>
                                Add item
                            </button>
                        </div>

                        <table className={"min-w-full divide-y divide-secondary-200 "}>
                            <thead>
                            </thead>

                            {(!!isLoading) && (
                                <div className={"text-center"}>
                                    <Loader/>
                                </div>
                            )}

                            {(!isLoading) && (
                                <tbody className="bg-inverse divide-y divide-secondary-200">
                                {rows}
                                </tbody>
                            )}
                        </table>

                    </div>
                </div>

                <ModalSaveResource
                    title={"Edit Item"}
                    addClass="max-w-lg"
                    visible={this.state.editModalOpen}
                    onClose={this.handleToggleEditModal}
                    fields={this.getFields(this.state.selectedItem)}
                    onSubmit={(params) => {
                        if (params) {
                            delete (params.LibraryTypeID);
                            params[this.getPrimaryKey()] = this.state.selectedItem[this.getPrimaryKey()];
                            params.Date = moment(params.Date, "hh:mm a").format("YYYY-MM-DD HH:mm:ss");

                            updateData({
                                query: this.getQuery(),
                                params: params,
                                resource: this.getResourceName(),
                                piggyResource: this.getResourceName()
                            })
                            this.handleToggleEditModal(null)
                        }
                    }}
                    translate={translate}
                    metadata={metadata}
                    handleInputChange={(fields, name, value) => {
                        if (name === "LibraryTypeID") {
                            fields.LibraryID.value = null;
                            fields.LibraryID.props.key = value;
                        }
                        return FieldsManager.updateField(fields, name, value);
                    }}
                />

                <ModalSaveResource
                    title={"Add Item"}
                    addClass="max-w-lg"
                    visible={this.state.createModalOpen}
                    onClose={this.handleToggleCreateModal}
                    fields={this.getFields()}
                    onSubmit={(params) => {
                        if (params) {
                            params.TemplateID = this.getId();
                            params.Date = moment(params.Date, "hh:mm a").format("YYYY-MM-DD HH:mm:ss");

                            createData({
                                query: this.getQuery(),
                                params: params,
                                resource: this.getResourceName(),
                                piggyResource: this.getResourceName()
                            })
                            this.handleToggleCreateModal(false)
                        }
                    }}
                    translate={translate}
                    metadata={metadata}
                    handleInputChange={(fields, name, value) => {
                        if (name === "LibraryTypeID") {
                            fields.LibraryID.value = null;
                            fields.LibraryID.props.key = value;
                        }
                        return FieldsManager.updateField(fields, name, value);
                    }}
                />

                <ModalConfirm
                    title={"Confirm delete"}
                    text={"Are you sure you want to delete?"}
                    onClose={this.handleToggleConfirmModal}
                    onConfirm={() => {
                        deleteData({
                            query: Object.assign({
                                [this.getPrimaryKey()]: this.state.selectedItem[this.getPrimaryKey()]
                            }, this.getQuery()),
                            resource: this.getResourceName(),
                            piggyResource: this.getResourceName()
                        })
                        this.handleToggleConfirmModal(false)
                    }}
                    visible={this.state.confirmModalOpen}
                />

            </>
        )
    }
}
