import React, {Component} from 'react'
import LayoutDashboard from "../../components/layout-dashboard";
import {connect} from "react-redux";
import LocalStorage from "../../util/localStorage";
import {Field, FieldsManager} from "../../data/services/fields";
import Resources from "../../data/services/resources";
import {
    checkPerm,
    CREATE_PERM, DEFAULT_CRUD_STATE, DELETE_PERM,
    fillFieldsFromData,
    getProp,
    UPDATE_PERM,
} from "../../util/util";
import {PlusIcon} from "@heroicons/react/solid";
import SimpleTable from "../../components/simple-table";
import ModalSaveResource from "../../components/modal/modal-save-resource";
import ModalConfirm from "../../components/modal/modal-confirm";
import LayoutDashboardHeader from "../../components/layout-dashboard/layout-dashboard-header";
import LayoutDashboardMain from "../../components/layout-dashboard/layout-dashboard-main";
import {createDataSelect, deleteDataSelect, getDataSelect} from "../../data/selectors/resourceSelectors";

class TemplatesPage extends Component {

    constructor(props) {
        super(props);

        this.state = {
            // List read
            ...DEFAULT_CRUD_STATE,
            sortBy: "Name",

            // Fields
            fields: this.getFields(),

            // Modals
            selectedItem: null,
            createModalOpen: false,
            editModalOpen: false,
            viewModalOpen: false,
            createContactModelOpen: false,
            confirmModalOpen: false,
            LocationID: 0,
            remountFields: 0

        };
    }

    /** Lifecycle
     ================================================================= */
    componentDidMount() {
        this.fetchData();
    };

    /** Data Events
     ================================================================= */
    fetchData = () => {
        this.props.getData({query: this.getQuery(), resource: this.getResourceName()});
    };

    /** UI Events
     ================================================================= */
    handleUpdateSort = (sortBy) => {
        this.setState({
            sortBy: sortBy,
            sort: (this.state.sortBy === sortBy) ? (this.state.sort === "ASC" ? "DESC" : "ASC") : "ASC"
        }, () => this.fetchData())
    };

    handleUpdateOffset = (offset, page) => {
        this.setState({
            offset: offset,
            paginationPage: page
        }, () => this.fetchData());
    }

    handleToggleCreateModal = () => {
        this.setState({
            createModalOpen: !this.state.createModalOpen
        })
    }

    handleToggleConfirmModal = (item = null) => {
        this.setState({
            selectedItem: item,
            confirmModalOpen: !this.state.confirmModalOpen
        })
    }

    handleQueryChange = (name, value) => {
        this.setState({
            query: value,
            offset: 0,
            paginationPage: 1
        }, this.fetchData)
    }

    handleTemplateClick = item => {
        this.props.history.push( "template/" + item.TemplateID);
    }

    handleInputChange = (fields, name, value) => {
        return FieldsManager.updateField(fields, name, value);
    }

    /** Fields/Data Definitions
     ================================================================= */
    getQuery = () => {
        return {
            limit: this.state.limit,
            offset: this.state.offset,
            sort: this.state.sort,
            sortBy: this.state.sortBy,
            query: this.state.query,
            archived: this.state.archived ? 1 : 0
        }
    }

    getFields = (item = null, excludeFields = null, includeFields = null) => {
        const fieldTemplates = {
            Name: new Field("Name", '', ['empty'], false, "text"),
            Description: new Field("Description", '', [''], false, "textarea"),
        };

        if (excludeFields) {
            excludeFields.forEach((item) => {
                delete fieldTemplates[item];
            });
        }

        if (includeFields) {
            for (const [key] of Object.entries(fieldTemplates)) {
                if (!includeFields.includes(key)) {
                    delete fieldTemplates[key];
                }
            }
        }

        return fillFieldsFromData(fieldTemplates, item);
    };

    getPrimaryKey = () => {
        return "TemplateID";
    }

    getResourceName = () => {
        return Resources.TemplateResource;
    }

    /** Render
     ================================================================= */
    render() {
        const {translate, history, location, resource, createData, deleteData} = this.props;

        const data = getProp(resource, "data.list", []);
        const count = getProp(resource, "data.count", 0);

        const isLoading = getProp(resource, "isLoading", false);

        const metadata = "";

        const tableFields = ["Name", "Description"];

        return (
            <LayoutDashboard
                onHandleQueryChange={this.handleQueryChange}
                history={history}
                translate={translate}
                location={location}
            >
                {/** Page content
                 ============================================================*/}
                <LayoutDashboardMain>
                    <LayoutDashboardHeader
                        title={translate("page.title.templates")}
                    >
                        {checkPerm(this.getResourceName(), CREATE_PERM) && (
                            <div className="ml-auto">
                                <button
                                    type="button"
                                    onClick={this.handleToggleCreateModal}
                                    className="btn btn-primary focus:ring-offset-inverse"
                                >
                                    <PlusIcon className="-ml-1 mr-1 h-5 w-5" aria-hidden="true"/>
                                    {translate("btn.add")}
                                </button>
                            </div>
                        )}
                    </LayoutDashboardHeader>

                    <SimpleTable
                        data={data}
                        count={count}
                        tableContainerClass="align-middle min-w-full overflow-x-auto md:shadow overflow-hidden relative"

                        fields={this.getFields(null, null, tableFields)}
                        translate={translate}
                        isLoading={isLoading}

                        limit={this.state.limit}
                        offset={this.state.offset}
                        paginationPage={this.state.paginationPage}
                        onOffsetChange={this.handleUpdateOffset}

                        sort={this.state.sort}
                        sortBy={this.state.sortBy}
                        onSortChange={this.handleUpdateSort}

                        onEdit={checkPerm(Resources.TemplateSingleResource, UPDATE_PERM) && this.handleTemplateClick}
                        onDelete={checkPerm(this.getResourceName(), DELETE_PERM) && this.handleToggleConfirmModal}
                    />
                </LayoutDashboardMain>

                {/** Modals
                 ============================================================*/}
                <ModalSaveResource
                    title={"Add Template"}
                    addClass="max-w-lg"
                    visible={this.state.createModalOpen}
                    onClose={this.handleToggleCreateModal}
                    fields={this.getFields()}
                    onSubmit={(params) => {
                        if (params) {
                            createData({
                                params: params,
                                query: this.getQuery(),
                                resource: this.getResourceName(),
                                piggyResource: this.getResourceName()
                            })
                            this.handleToggleCreateModal(false)
                        }
                    }}
                    translate={translate}
                    metadata={metadata}
                />

                <ModalConfirm
                    title={"Confirm delete"}
                    text={"Are you sure you want to delete?"}
                    onClose={this.handleToggleConfirmModal}
                    onConfirm={() => {
                        deleteData({
                            query: Object.assign({
                                [this.getPrimaryKey()]: this.state.selectedItem[this.getPrimaryKey()]
                            }, this.getQuery()),
                            resource: this.getResourceName(),
                            piggyResource: this.getResourceName()
                        })
                        this.handleToggleConfirmModal(false)
                    }}
                    visible={this.state.confirmModalOpen}
                />
            </LayoutDashboard>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        resource: state.resource
    };
};

const mapDispatchToProps = (dispatch) => {
    const user = LocalStorage.get("user")

    return {
        getData: getDataSelect({dispatch, user}),
        createData: createDataSelect({dispatch, user}),
        deleteData: deleteDataSelect({dispatch, user})
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(TemplatesPage);
