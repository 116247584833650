import React, {Component} from 'react'
import LayoutDashboard from "../../components/layout-dashboard";
import {connect} from "react-redux";
import {CogIcon, UserIcon} from "@heroicons/react/solid";
import ProfileTab from "./profileTab";
import SettingsTab from "./settingsTab";

function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

class SettingsPage extends Component {

    constructor(props) {
        super(props);

        this.state = {
            subNavigation: [
                {
                    name: 'Profile',
                    description: 'Edit basic profile settings, like name or timezone, start and end of a day.',
                    href: '#',
                    icon: UserIcon
                },
                {
                    name: 'Settings',
                    description: 'Edit basic settings, like snooze time.',
                    href: '#',
                    icon: CogIcon
                }
            ],
            selectedTab: 'Profile'
        };
    }

    /** Render
     ================================================================= */
    render() {
        const {translate} = this.props;

        return (
            <LayoutDashboard footerBorder={true} {...this.props}>
                <main className="flex flex-col md:flex-row custom-min-h-page-2">
                    {/* Secondary sidebar */}
                    <nav
                        aria-label="Sections"
                        className="flex-shrink-0 md:w-96 bg-white border-r border-primary-gray-200 xl:flex xl:flex-col sm:page-content-height"
                    >
                        <div className="flex-shrink-0 h-16 px-6 border-b border-primary-gray-200 flex items-center">
                            <p className="text-lg font-medium text-primary-gray-900">Preferences</p>
                        </div>
                        <div className="flex-1 min-h-0 overflow-y-auto">
                            {this.state.subNavigation.map((item, i) => (
                                <a
                                    key={item.name}
                                    href={item.href}
                                    className={classNames(
                                        (this.state.selectedTab === item.name) ? 'bg-primary-50 bg-opacity-50' : 'hover:bg-primary-50 hover:bg-opacity-50',
                                        'flex p-6 border-b border-gray-200'
                                    )}
                                    aria-current={(this.state.selectedTab === item.name) ? 'page' : undefined}
                                    onClick={() => {
                                        this.setState({
                                            selectedTab: item.name
                                        })
                                    }}
                                >
                                    <item.icon className="flex-shrink-0 -mt-0.5 h-6 w-6 text-gray-400"
                                               aria-hidden="true"/>
                                    <div className="ml-3 text-sm">
                                        <p className="font-medium text-gray-900">{item.name}</p>
                                        <p className="mt-1 text-gray-500">{item.description}</p>
                                    </div>
                                </a>
                            ))}
                        </div>
                    </nav>

                    <div className="flex-1 xl:overflow-y-auto">
                        <div className="mx-auto py-10 px-4 sm:px-6 lg:py-12 lg:px-8">
                            {(this.state.selectedTab === 'Profile') && (
                                <ProfileTab
                                    dispatch={this.props.dispatch}
                                    translate={translate}
                                    resource={this.props.resource}
                                />
                            )}

                            {(this.state.selectedTab === 'Settings') && (
                                <SettingsTab
                                    dispatch={this.props.dispatch}
                                    translate={translate}
                                    resource={this.props.resource}
                                />
                            )}
                        </div>
                    </div>
                </main>
            </LayoutDashboard>
        )
    }
}

export default connect(state => state)(SettingsPage);