import {combineReducers} from "redux";
import userReducer from "./user";
import resourceReducer from "./resource";
import secondResourceReducer from "./secondResource";
import thirdResourceReducer from "./thirdResource";
import uiReducer from "./ui";
import dialogResourceReducer from "./dialogResource";
import messagesReducer from "./messaging";
import notificationsReducer from "../../package/notification/reducers/notifications";
import sidebarResourceReducer from "./sidebarResource";

const appReducer = combineReducers({
    user: userReducer,
    resource: resourceReducer,
    ui: uiReducer,
    secondResource: secondResourceReducer,
    thirdResource: thirdResourceReducer,
    dialogResource: dialogResourceReducer,
    sidebarResource: sidebarResourceReducer,
    messages: messagesReducer,
    notifications: notificationsReducer
});

const rootReducer = (state, action) => {
    if (action.type === 'LOGOUT') {
        return {
            ui: {},
            user: {},
            resource: {},
            secondResource: {},
            thirdResource: {},
            dialogResource: {},
            sidebarResource: {},
            messages: {},
            notifications: {}
        }
    }
    return appReducer(state, action);
};

export default rootReducer;
