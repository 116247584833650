import React, {Component} from "react";
import connect from "react-redux/es/connect/connect";
import View from "./index.jsx";

class NotFoundView extends Component {

    constructor(props) {
        super(props);
        this.state = {};
        this.view = View;
    }

    render() {
        return this.view();
    }
}

export default connect(state => state)(NotFoundView);