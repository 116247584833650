import {Dialog, Transition} from "@headlessui/react";
import React, {Component, Fragment} from "react";
import {fieldsToHtml, scrollErrorIntoView, classNames} from "../../util/util";
import {FieldsManager} from "../../data/services/fields";
import {XIcon} from "@heroicons/react/outline";

export default class ModalSaveResource extends Component {

    constructor(props) {
        super(props);
        this.state = {
            fields: this.props.fields
        }

        this.cancelButtonRef = React.createRef();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if ((prevProps.visible !== this.props.visible) && !!this.props.visible) {
            this.setState({
                fields: this.props.fields
            })
        }
    }

    handleInputChange = (name, value) => {
        const fields = this.props.handleInputChange
            ? this.props.handleInputChange(this.state.fields, name, value)
            : FieldsManager.updateField(this.state.fields, name, value);

        this.setState({fields: fields});
    };

    getValidFields = () => {
        let validatedFields = FieldsManager.validateFields(this.state.fields);
        validatedFields = !!this.props.validateFields ? this.props.validateFields(validatedFields) : validatedFields;

        if (FieldsManager.checkFieldsForErrors(validatedFields)) {
            return FieldsManager.getFieldKeyValues(validatedFields);
        } else {
            this.setState({fields: validatedFields});

            scrollErrorIntoView(validatedFields);
        }
    }

    render() {
        const {onSubmit, onClose, visible, translate, title, addClass, metadata, onCustomActions, headerText} = this.props;

        const fieldsHtml = fieldsToHtml(Object.values(Object.assign({}, this.state.fields)), translate, this.handleInputChange, metadata);

        return (
            <Transition show={visible} as={Fragment}>
                <Dialog
                    as="div"
                    id="modal"
                    className="fixed inset-0 z-20 overflow-y-auto"
                    initialFocus={this.cancelButtonRef}
                    static
                    open={visible}
                    onClose={onClose}
                >
                    <div className="min-h-screen px-4 text-center">
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0"
                            enterTo="opacity-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100"
                            leaveTo="opacity-0"
                        >
                            <Dialog.Overlay className="fixed inset-0 bg-black bg-opacity-30 transition-opacity"/>
                        </Transition.Child>

                        {/* This element is to trick the browser into centering the modal contents. */}
                        <span
                            className="inline-block h-screen align-middle"
                            aria-hidden="true"
                        >
                        &#8203;
                        </span>
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0 scale-95"
                            enterTo="opacity-100 scale-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100 scale-100"
                            leaveTo="opacity-0 scale-95"
                        >
                            {/*removed class because of the date popups being cut off: overflow-hidden*/}
                            <div
                                className={classNames("theme-dark-popup inline-block w-full p-6 my-8 text-left align-middle transition-all transform bg-inverse shadow-xl rounded-2xl", addClass)}>
                                <div className="flex items-start justify-between">
                                    <Dialog.Title
                                        className="text-lg font-medium text-gray-900">{title}</Dialog.Title>
                                    <div className="ml-3 h-7 flex items-center">
                                        <button
                                            className="bg-white rounded-md text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500"
                                            onClick={() => onClose(false)}
                                        >
                                            <span className="sr-only">Close panel</span>
                                            <XIcon className="h-6 w-6" aria-hidden="true"/>
                                        </button>
                                    </div>
                                </div>

                                <div className={"space-y-8 " + (fieldsHtml.length > 0 ? "divide-y divide-secondary-200" : "")}>
                                    <div className={"mt-5 mb-5"}>
                                        {headerText}
                                    </div>

                                    <div key={this.props.bodyKey} className="mt-6 sm:mt-5 space-y-6 sm:space-y-5">
                                        {fieldsHtml}
                                    </div>


                                    <div className="pt-5">
                                        <div className="flex justify-end">
                                            {!!onCustomActions && onCustomActions()}

                                            <button type="button"
                                                    className="btn btn-outline focus:ring-offset-inverse hover:cursor-pointer"
                                                    onClick={(e) => onClose()}
                                            >
                                                {translate("btn.close")}
                                            </button>

                                            <button
                                                type="submit"
                                                className="btn btn-primary ml-3 focus:ring-offset-inverse hover:cursor-pointer"
                                                onClick={() => onSubmit(this.getValidFields())}
                                            >
                                                {translate("btn.save")}
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Transition.Child>
                    </div>
                </Dialog>
            </Transition>
        );
    }
}
