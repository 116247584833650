import React, {Component} from 'react'
import {AnnotationIcon, PencilIcon} from '@heroicons/react/solid'
import LayoutDashboard from "../../components/layout-dashboard";
import {connect} from "react-redux";
import LocalStorage from "../../util/localStorage";
import Resources from "../../data/services/resources";
import {DEFAULT_CRUD_STATE, fillFieldsFromData, getProp, stateToCrud} from "../../util/util";
import {getSecondResource} from "../../data/actions/secondResource";
import Loader from "../../components/loader";
import ItemsTab from "./ItemsTab";
import ModalSaveResource from "../../components/modal/modal-save-resource";
import {Field} from "../../data/services/fields";
import LayoutDashboardMain from "../../components/layout-dashboard/layout-dashboard-main";
import {
    createDataSelect,
    deleteDataSelect,
    getDataSelect,
    updateDataSelect
} from "../../data/selectors/resourceSelectors";

class TemplateSinglePage extends Component {

    constructor(props) {
        super(props);

        this.state = {
            ...DEFAULT_CRUD_STATE,
            sortBy: "",
            sort: "",

            editModalOpen: false,
        };

        this.assessmentTabRef = React.createRef();
    }

    /** Lifecycle
     ================================================================= */
    componentDidMount() {
        this.fetchData();
    };

    componentDidUpdate = (prevProps, prevState) => {
        if (this.getId() !== prevProps.match.params.id) {
            this.fetchData();
        }
    };

    /** Data Events
     ================================================================= */
    fetchData = () => {
        this.props.getInfoData({query: this.getQuery()})
    };

    /** UI Events
     ================================================================= */
    handleToggleEditModal = () => {
        this.setState({
            editModalOpen: !this.state.editModalOpen
        });
    };

    /** Helpers
     ================================================================= */
    getId = () => {
        return this.props.match.params && this.props.match.params.id;
    };

    getQuery = () => {
        return {
            TemplateID: this.getId(),
            ...(stateToCrud(this.state))
        }
    };

    getFields = (item = null) => {
        const fieldTemplates = {
            Name: new Field("Name", '', ['empty'], false, "text"),
            Description: new Field("Description", '', [''], false, "textarea")
        };

        return fillFieldsFromData(fieldTemplates, item);
    };

    /** Render
     ================================================================= */
    render() {
        const {
            resource, infoData,
            history, translate, location,
            getData, createData, updateData, deleteData
        } = this.props

        const Template = getProp(infoData, "data", {});
        const isLoading = getProp(infoData, "isLoading", null)

        return (
            <LayoutDashboard
                history={history}
                translate={translate}
                location={location}
            >
                {/* Page heading */}
                <LayoutDashboardMain>
                    <header className="border-b">
                        <div
                            className="custom-max-width mx-auto px-4 p-4 sm:p-6 lg:p-8 xl:flex xl:items-center xl:justify-between"
                        >
                            {(!!isLoading) && (
                                <Loader/>
                            )}

                            {(!isLoading) && (
                                <React.Fragment>
                                    <div className="flex-1 min-w-0">
                                        <h1 className="flex-1 text-lg font-medium">
                                            {Template.Name}
                                        </h1>

                                        <div
                                            className="mt-1 flex flex-col sm:flex-row sm:flex-wrap sm:mt-0 sm:space-x-8">
                                            <div className="mt-2 flex items-center text-sm text-secondary-500">
                                                <AnnotationIcon
                                                    className="flex-shrink-0 mr-1.5 h-5 w-5 text-secondary-400"
                                                    aria-hidden="true"/>
                                                {Template.Description}
                                            </div>
                                        </div>
                                    </div>

                                    <div className="mt-5 flex xl:mt-0 xl:ml-4">
                                        <span className="hidden sm:block">
                                            <button
                                                onClick={this.handleToggleEditModal}
                                                className="btn btn-outline mt-5 sm:mt-0"
                                            >
                                                <PencilIcon className="-ml-1 mr-2 h-5 w-5 text-secondary-400"
                                                            aria-hidden="true"/>
                                                Edit
                                            </button>
                                        </span>
                                    </div>
                                </React.Fragment>
                            )}
                        </div>
                    </header>


                    <div className="">
                        {/* Content */}
                        <div className="">
                            <ItemsTab
                                id={this.getId()}
                                getData={getData}
                                createData={createData}
                                updateData={updateData}
                                deleteData={deleteData}
                                translate={translate}
                                resource={resource}
                            />
                        </div>
                    </div>
                </LayoutDashboardMain>

                <ModalSaveResource
                    title={"Edit Template"}
                    addClass="max-w-lg"
                    visible={this.state.editModalOpen}
                    onClose={this.handleToggleEditModal}
                    fields={this.getFields(Template)}
                    onSubmit={(params) => {
                        if (params) {
                            params.TemplateID = this.getId();

                            updateData({
                                query: this.getQuery(),
                                params: params,
                                resource: Resources.TemplateResource,
                                secondPiggyResource: Resources.TemplateSingleResource
                            })
                            this.handleToggleEditModal(null)
                        }
                    }}
                    translate={this.props.translate}
                />
            </LayoutDashboard>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        resource: state.resource,
        infoData: state.secondResource,
    };
};

const mapDispatchToProps = (dispatch) => {
    const user = LocalStorage.get("user")

    return {
        getData: getDataSelect({dispatch, user}),
        createData: createDataSelect({dispatch, user}),
        updateData: updateDataSelect({dispatch, user}),
        deleteData: deleteDataSelect({dispatch, user}),

        getInfoData: ({query}) => {
            dispatch(getSecondResource({
                user: user,
                query: query,
                resource: Resources.TemplateSingleResource
            }))
        }
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(TemplateSinglePage);
