import React, {Component, Fragment} from "react";
import {connect} from "react-redux";
import {ClockIcon} from "@heroicons/react/solid";
import {Transition} from "@headlessui/react";
import {deleteSidebarResource, getSidebarResource, updateSidebarResource} from "../../data/actions/sidebarResource";
import {getProp} from "../../util/util";
import LocalStorage from "../../util/localStorage";
import Resources from "../../data/services/resources";
import moment from "moment";
import Loader from "../loader";
import ReplyIcon from "@heroicons/react/solid/ReplyIcon";
import Timer from "./timer";

class BlockWatcher extends Component
{
    constructor(props) {
        super(props);
        this.state = {
            show: true,
            extended: true,
            timerExpired: {}
        };
    }

    componentDidMount() {
        this.fetchData(false);
        setInterval(() => {
            this.fetchData(true)
        }, 3000);
    }

    startBlock = (item) => {
        this.props.dispatch(updateSidebarResource({
            user: LocalStorage.get("user"),
            query: this.getQuery(),
            params: {
                CalendarID: item.CalendarID
            },
            resource: Resources.CalendarStartBlock,
            piggyResource: this.getResourceName(),
            notificationMessage: "Block Started"
        }));
    }

    skipBlock = (item) => {
        this.props.dispatch(deleteSidebarResource({
            user: LocalStorage.get("user"),
            query: Object.assign({}, this.getQuery(), {
                CalendarID: item.CalendarID
            }),
            params: {
                CalendarID: item.CalendarID
            },
            resource: Resources.CalendarSkipBlock,
            piggyResource: this.getResourceName(),
            notificationMessage: "Block Skip"
        }));
    }

    startExercise = (item) => {
        this.props.dispatch(updateSidebarResource({
            user: LocalStorage.get("user"),
            query: this.getQuery(),
            params: {
                CalendarID: item.CalendarID
            },
            resource: Resources.CalendarStartExercise,
            piggyResource: this.getResourceName(),
            notificationMessage: "Exercise Started"
        }));
    }

    completeExercise = (item) => {
        this.props.dispatch(updateSidebarResource({
            user: LocalStorage.get("user"),
            query: this.getQuery(),
            params: {
                CalendarID: item.CalendarID
            },
            resource: Resources.CalendarCompleteExercise,
            piggyResource: this.getResourceName(),
            notificationMessage: "Block Completed"
        }));
    }

    handleToggleStartModal = (item = null) => {
        this.setState({
            selectedItem: item,
            startModalOpen: !this.state.startModalOpen
        })
    }

    fetchData = (silent) => {
        if (!!LocalStorage.get('user')) {
            this.props.dispatch(getSidebarResource({
                user: LocalStorage.get('user'),
                query: this.getQuery(),
                silent: silent,
                resource: this.getResourceName()
            }));
        }
    }

    getResourceName = () => {
        return Resources.CalendarUpcoming;
    }

    getQuery = () => {
        return {
            offset: 0,
            limit: 100,
            searchFields: JSON.stringify({
                StartDate: [
                    "Date", ">=", moment().format("YYYY-MM-DD HH:mm:ss")
                ],
                EndDate: [
                    "Date", "<=", moment().format("YYYY-MM-DD") + " 23:59:00"
                ]
            })
        }
    }

    renderBlock = (block, isAnyBlockRunning) => {
        const timeStart = moment(block.Date, "YYYY-MM-DD HH:mm:ss").format("h A");
        const timeEnd = moment(block.Date, "YYYY-MM-DD HH:mm:ss").add(block.BlockLength, 'hours').format("h A");

        const diffSec = moment().utc().diff(moment.utc(block['LibraryStarted']), 'seconds');

        const timerOffset = new Date();
        timerOffset.setSeconds(timerOffset.getSeconds() + (60 * block.LibraryLength) - diffSec);

        const expired = ((60 * block.LibraryLength) - diffSec) < 0;

        return (
            <>
                <hr className={"mt-2 mb-1"}/>
                <p className="text-sm font-medium text-gray-700">{block.LibraryType}</p>
                <p className="mt-1 text-sm text-gray-500">{block.Exercise}</p>
                <p className="mt-1 text-sm text-gray-500">{block.Description}</p>
                <p>{timeStart}{" - "}{timeEnd}</p>

                <div className="flex mt-4 space-x-3">
                    {!isAnyBlockRunning && !block['CalendarStatusID'] && (
                        <>
                            <button
                                onClick={(e) => {
                                    e.preventDefault();
                                    e.stopPropagation();
                                    e.nativeEvent.stopImmediatePropagation();

                                    this.startBlock(block);
                                }}
                                className="inline-flex items-center px-4 py-2 border border-secondary-300 rounded-md shadow-sm text-sm font-medium text-white bg-green-500 hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-secondary-50 focus:ring-primary-500"
                            >
                                <ClockIcon className="-ml-1 mr-2 h-5 w-5 text-white"
                                           aria-hidden="true"/>
                                Start
                            </button>
                            <button
                                onClick={(e) => {
                                    e.preventDefault();
                                    e.stopPropagation();
                                    e.nativeEvent.stopImmediatePropagation();

                                    this.skipBlock(block);
                                }}
                                className="inline-flex items-center px-4 py-2 border border-secondary-300 rounded-md shadow-sm text-sm font-medium text-secondary-700 bg-inverse hover:bg-secondary-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-secondary-50 focus:ring-primary-500"
                            >
                                <ReplyIcon className="-ml-1 mr-2 h-5 w-5 text-secondary-400" style={{transform: "scale(-1,1)"}}
                                           aria-hidden="true"/>
                                Skip
                            </button>
                        </>
                    )}

                    {!isAnyBlockRunning && (block['CalendarStatusID'] === 1 && block['CalendarLibraryStatusID'] !== 1 ) && (
                        <button
                            onClick={(e) => {
                                e.preventDefault();
                                e.stopPropagation();
                                e.nativeEvent.stopImmediatePropagation();
                                this.startExercise(block);
                            }}
                            className="flex-grow inline-flex items-center px-4 py-2 border border-secondary-300 rounded-md shadow-sm text-sm font-medium text-white bg-green-500 hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-secondary-50 focus:ring-primary-500"
                        >
                            <ClockIcon className="-ml-1 mr-2 h-5 w-5 text-white"
                                       aria-hidden="true"/>
                            Start Exercise
                        </button>
                    )}

                    {(block['CalendarStatusID'] === 1 && block['CalendarLibraryStatusID'] === 1) && (
                        <>
                            <div>
                                {(!expired && !this.state.timerExpired[block.CalendarID]) && (
                                    <Timer
                                        expiryTimestamp={timerOffset}
                                        onExpire={() => {
                                            let timerExpired = this.state.timerExpired;
                                            timerExpired[block.CalendarID] = true;
                                            this.setState({
                                                timerExpired: timerExpired
                                            })
                                        }}
                                    />
                                )}

                                {!(!expired && !this.state.timerExpired[block.CalendarID]) && (
                                    <p className={"mb-1"}>Countdown passed.</p>
                                )}

                                <button
                                    onClick={(e) => {
                                        e.preventDefault();
                                        e.stopPropagation();
                                        e.nativeEvent.stopImmediatePropagation();
                                        this.completeExercise(block);
                                    }}
                                    className="flex-grow inline-flex items-center px-4 py-2 border border-secondary-300 rounded-md shadow-sm text-sm font-medium text-white bg-green-500 hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-secondary-50 focus:ring-primary-500"
                                >
                                    <ClockIcon className="-ml-1 mr-2 h-5 w-5 text-white"
                                               aria-hidden="true"/>
                                    Set Exercise as Completed
                                </button>
                            </div>
                        </>
                    )}
                </div>
            </>
        )
    }

    render()  {
        const list = getProp(this.props, "sidebarResource.data.list", []);
        const isLoading = getProp(this.props, "sidebarResource.isLoading", false);

        const isAnyBlockRunning = list?.reduce((memo, it) => {
            return memo || (it['CalendarStatusID'] === 1 && it['CalendarLibraryStatusID'] === 1)
        }, false);

        const blocks = list?.slice(0, 10)
            .filter(it => !it.CalendarStatusID || (it.CalendarStatusID == 1))
            .map((it) => {
            return this.renderBlock(it, isAnyBlockRunning);
        });

        if (!LocalStorage.get('user')?.Contact) {
            return null;
        }

        return (
            <div className="block-watcher-sidebar">
                <div
                    className="w-full space-y-4 flex items-start"
                >
                    <div className="mt-4 p-4 bg-white shadow-lg rounded-l-lg">
                        <ClockIcon className="h-6 w-6 text-green-400" aria-hidden="true"/>
                    </div>
                    <div
                        className="max-w-sm w-full bg-white shadow-lg rounded-bl-lg pointer-events-auto "
                        id=""
                    >
                        <div className="p-4">
                            <div className="flex items-start">
                                <div className="ml-3 w-0 flex-1 pt-0.5 overflow-y-scroll h-350">
                                    <p className="text-sm font-medium text-gray-900">{"Upcoming blocks"}</p>

                                    {(!!isLoading) && (
                                        <div className={"text-center"}>
                                            <Loader/>
                                        </div>
                                    )}

                                    {!isLoading && blocks}

                                    {!isLoading && (!blocks || blocks.length === 0) && (<span>No upcoming or active blocks.</span>)}
                                </div>

                            </div>
                        </div>
                    </div>

                </div>
            </div>
        );
    }
}

export default connect(state => state)(BlockWatcher);
