import React, {Component} from 'react'
import {getResource, updateResource} from "../../data/actions/resource";
import LocalStorage from "../../util/localStorage";
import {fieldsToHtml, fillFieldsFromData, getProp, getTimeFormat} from "../../util/util";
import {Field, FieldsManager} from "../../data/services/fields";
import Resources from "../../data/services/resources";
import Loader from "../../components/loader";
import Button from "../../components/button";
import moment from 'moment';

export default class SettingsTab extends Component {

    constructor(props) {
        super(props);

        this.state = {
            fields: this.getFields(),
            canSubmit: false
        };
    }

    /** Lifecycle
     ================================================================= */
    componentDidMount() {
        this.fetchData();
    };

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.resource.isLoading && !this.props.resource.isLoading) {
            this.setState({
                fields: this.getFields(getProp(this.props, "resource.data", {})),
                canSubmit: false
            })
        }
    }

    /** Data Events
     ================================================================= */
    fetchData = () => {
        this.props.dispatch(getResource({
            user: LocalStorage.get("user"),
            query: {},
            resource: this.getResourceName()
        }))
    };

    onSubmit = () => {
        if (this.state.canSubmit) {
            this.setState({fields: FieldsManager.validateFields(this.state.fields)}, () => {
                if (FieldsManager.checkFieldsForErrors(this.state.fields)) {
                    this.setState({canSubmit: false}, () => {
                        this.props.dispatch(updateResource({
                            user: LocalStorage.get("user"),
                            params: Object.assign({}, FieldsManager.getFieldKeyValues(this.state.fields)),
                            resource: this.getResourceName(),
                            piggyResource: this.getResourceName()
                        }));
                    });
                }
            });
        }
    }

    /** UI Events
     ================================================================= */
    handleInputChange = (name, value) => {
        let fields = FieldsManager.updateField(this.state.fields, name, value)
        if (name === "StartTime" || name === "EndTime") {
            let StartTime = moment(fields.StartTime.value, getTimeFormat())
            let EndTime = moment(fields.EndTime.value, getTimeFormat())

            if (moment(EndTime).isBefore(StartTime) || fields.StartTime.value === fields.EndTime.value) {
                let fieldToOverride = name === "StartTime" ? "EndTime" : "StartTime"
                fields[fieldToOverride].value = ''
            }
        }

        this.setState({canSubmit: true, fields: fields});
    };

    handleCancel = () => {
        const data = getProp(this.props, "resource.data", null);

        this.setState({
            fields: this.getFields(data),
            canSubmit: false
        })
    };


    /** Helpers
     ================================================================= */
    getResourceName = () => {
        return Resources.Settings;
    }

    getFields = (item = null) => {
        const fieldTemplates = {
            SnoozeMinutes: new Field("SnoozeMinutes", 10, ['empty'], false, "select"),

            StartTime: new Field("StartTime", "", ['empty'], false, "time-custom", {}, {hoursOnly: "true"}),
            EndTime: new Field("EndTime", "", ['empty'], false, "time-custom", {}, {hoursOnly: "true"}),

            DailyReminderOn: new Field("DailyReminderOn", "", [], false, "checkbox", {}),
            DailyReminderTime: new Field("DailyReminderTime", "", [], false, "time-custom", {}, {hoursOnly: "true"}),
        }

        return fillFieldsFromData(fieldTemplates, item);
    };

    /** Render
     ================================================================= */
    render() {
        const {translate} = this.props;

        const isLoading = getProp(this.props, "resource.isLoading", false);

        return (
            <div className="">
                {isLoading && (
                    <div className={"text-center"}>
                        <Loader/>
                    </div>
                )}

                {!isLoading && (
                    <>
                        <form className="space-y-8 divide-y divide-gray-200">
                            {fieldsToHtml(Object.values(Object.assign({}, this.state.fields)), translate, this.handleInputChange, {
                                SnoozeMinutes: {
                                    5: "5 Minutes",
                                    10: "10 Minutes",
                                    15: "15 Minutes",
                                },
                            })}
                        </form>

                        <div className="pt-5">
                            <div className="flex justify-end">
                                <Button type="outline"
                                        className="btn btn-outline focus:ring-offset-inverse"
                                        disabled={!this.state.canSubmit}
                                        onClick={this.handleCancel}
                                >
                                    {translate("btn.cancel")}
                                </Button>

                                <Button
                                    type="submit"
                                    className="btn btn-primary ml-3 focus:ring-offset-inverse"
                                    disabled={!this.state.canSubmit}
                                    onClick={this.state.canSubmit && this.onSubmit}
                                >
                                    {translate("btn.save")}
                                </Button>
                            </div>
                        </div>
                    </>
                )}
            </div>
        )
    }
}
