
import React from 'react';
import { useTimer } from 'react-timer-hook';

const zeroPad = (num, places) => {
    let zero = places - num.toString().length + 1;
    return Array(+(zero > 0 && zero)).join("0") + num;
}

const Timer = ({expiryTimestamp, onExpire = null}) => {
    const {
        seconds,
        minutes,
        hours,
        days,
        isRunning,
        start,
        pause,
        resume,
        restart
    } = useTimer({ expiryTimestamp: expiryTimestamp, onExpire: onExpire});

    return (
        <div style={{textAlign: 'left'}}>
            <p>Timer:</p>
            <div style={{fontSize: '40px'}}>
                <span>{zeroPad(minutes, 2)}</span>:<span>{zeroPad(seconds, 2)}</span>
            </div>
        </div>
    );
};

export default Timer;