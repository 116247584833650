import React, {Component} from "react";
import {createBrowserHistory} from "history";
import {Redirect, Route as NeutralRoute, Router, Switch} from "react-router-dom";
import Login from "./views/login";
import ResetPassword from "./views/reset-password";
import ResetPasswordConfirm from "./views/reset-password-confirm";
import LocalStorage from "./util/localStorage";
import NotFoundView from "./views/not-found";
import RegisterView from "./views/register";
import UsersPage from "./views/users-page";
import PreferencesPage from "./views/preferences-page";
import CalendarView from "./views/calendar";
import TemplatesPage from "./views/templates";
import LibraryPage from "./views/library";
import TemplateSinglePage from "./views/template-single";
import CalendarItemsPage from "./views/calendar-single";
import {checkPerm, READ_PERM} from "./util/util";
import Resources from "./data/services/resources";
import NotificationsPage from "./views/notifications-page";
import LoggedDevicesPage from "./views/logged-devices-page";
import PrivacyPolicyPage from "./views/privacy-policy-page";
import BlockWatcher from "./components/block-watcher";

const PrivateRoute = ({component: Component, ...rest}) => (
    <NeutralRoute
        {...rest}
        render={props =>
            (!!LocalStorage.get('user')) ? (<Component {...props}/>) : (
                <Redirect to={{
                    pathname: "/login",
                    state: {from: props.location}
                }}/>
            )
        }
    />
);

const PublicRoute = ({component: Component, ...rest}) => (
    <NeutralRoute
        {...rest}
        render={props =>
            (!LocalStorage.get('user')) ? (<Component {...props}/>) : (
                <Redirect to={{
                    pathname:  (checkPerm(Resources.CalendarResource, READ_PERM) ? "/calendar" : "/users"),
                    state: {from: props.location}
                }}/>
            )
        }
    />
);

class RouterListener extends Component {

    constructor(props) {
        super(props);

        this.unlisten = null;
        this.state = {}
    }

    componentDidMount() {
        this.unlisten = this.props.history.listen((location, action) => {

        });
    }

    componentWillUnmount() {
        if (this.unlisten) {
            this.unlisten();
        }
    }

    render() {
        return (
            <React.Fragment>
                {this.props.children}
            </React.Fragment>
        );
    }
}

export default (store, translator) => {
    function getComponent(Component, props) {
        return (
            <Component {...props} translate={(key, args) => translator.translate(key, args)}/>
        );
    }

    const history = createBrowserHistory();
    store.history = history;

    return (
        <Router history={history}>
            <RouterListener history={history} dispatch={store.dispatch} {...store.getState()}
                            translate={(key, args) => translator.translate(key, args)}>
                <Switch>
                    {/* Login, register, and reset password routes */}
                    <PublicRoute exact path="/" component={(props) => getComponent(Login, props)}/>
                    <PublicRoute exact path="/login" component={(props) => getComponent(Login, props)}/>

                    <PublicRoute exact path="/register" component={(props) => getComponent(RegisterView, props)}/>

                    <PublicRoute exact path="/reset-password"
                                 component={(props) => getComponent(ResetPassword, props)}/>
                    <NeutralRoute exact path="/reset-password-confirm/:email/:token/:is_new"
                                  component={(props) => getComponent(ResetPasswordConfirm, props)}/>
                    <NeutralRoute exact path="/reset-password-confirm/:email/:token"
                                  component={(props) => getComponent(ResetPasswordConfirm, props)}/>

                    <PrivateRoute exact path="/calendar" component={(props) => getComponent(CalendarView, props)}/>
                    <PrivateRoute exact path="/calendar-items/:id" component={(props) => getComponent(CalendarItemsPage, props)}/>

                    <PrivateRoute exact path="/templates" component={(props) => getComponent(TemplatesPage, props)}/>
                    <PrivateRoute exact path="/template/:id" component={(props) => getComponent(TemplateSinglePage, props)}/>
                    <PrivateRoute exact path="/library" component={(props) => getComponent(LibraryPage, props)}/>

                    <PrivateRoute exact path="/preferences" component={(props) => getComponent(PreferencesPage, props)}/>
                    <PrivateRoute exact path="/users" component={(props) => getComponent(UsersPage, props)}/>
                    <PrivateRoute exact path="/devices" component={(props) => getComponent(LoggedDevicesPage, props)}/>

                    <PrivateRoute exact path="/privacy-policy"
                                  component={(props) => getComponent(PrivacyPolicyPage, props)}/>

                    <PrivateRoute exact path="/notifications" component={(props) => getComponent(NotificationsPage, props)}/>
                    <NeutralRoute component={(props) => getComponent(NotFoundView, props)}/>
                </Switch>

                <BlockWatcher
                    translate={(key, args) => translator.translate(key, args)}
                />
            </RouterListener>
        </Router>
    );
}
