import React, {Component} from 'react'
import LayoutDashboard from "../../components/layout-dashboard";
import {connect} from "react-redux";
import {createResource, deleteResource, getResource, updateResource} from "../../data/actions/resource";
import LocalStorage from "../../util/localStorage";
import {Field} from "../../data/services/fields";
import Resources from "../../data/services/resources";
import {checkPerm, CREATE_PERM, fillFieldsFromData, getProp} from "../../util/util";
import {PlusIcon} from "@heroicons/react/solid";
import SimpleTable from "../../components/simple-table";
import ModalSaveResource from "../../components/modal/modal-save-resource";
import ModalConfirm from "../../components/modal/modal-confirm";
import icons from "../../util/icons";
import DialogDefault from "../../components/dialog-default";
import LayoutDashboardHeader from "../../components/layout-dashboard/layout-dashboard-header";
import LayoutDashboardMain from "../../components/layout-dashboard/layout-dashboard-main";
import ArchivedSwitch from "../../components/archived-switch";

class UsersPage extends Component {

    constructor(props) {
        super(props);

        this.state = {
            offset: 0,
            limit: 10,
            paginationPage: 1,
            sort: "ASC",
            sortBy: "FirstName",

            query: "",
            archived: false,

            fields: this.getFields(),
            selectedItemFields: this.getSelectedItemFields(),

            selectedItem: null,
            viewModalOpen: false,
            editModalOpen: false,
            createModalOpen: false,
            createContactModelOpen: false,
            confirmModalOpen: false,
        };
    }

    /** Lifecycle
     ================================================================= */
    componentDidMount() {
        this.fetchData();
    };

    /** Data Events
     ================================================================= */
    fetchData = () => {
        this.props.dispatch(getResource({
            user: LocalStorage.get("user"),
            query: this.getQuery(),
            resource: this.getResourceName()
        }))
    };

    /** UI Events
     ================================================================= */
    handleUpdateSort = (sortBy) => {
        this.setState({
            sortBy: sortBy,
            sort: (this.state.sortBy === sortBy) ? (this.state.sort === "ASC" ? "DESC" : "ASC") : "ASC"
        }, () => this.fetchData())
    };

    handleUpdateOffset = (offset, page) => {
        this.setState({
            offset: offset,
            paginationPage: page
        }, () => this.fetchData());
    }

    handleToggleEditModel = (item = null) => {
        this.setState({
            selectedItem: item,
            editModalOpen: !this.state.editModalOpen
        })
    }

    handleToggleCreateModal = () => {
        this.setState({
            createModalOpen: !this.state.createModalOpen
        })
    }

    handleToggleCreateContactModal = () => {
        this.setState({
            createContactModelOpen: !this.state.createContactModelOpen
        })
    }

    handleToggleConfirmModal = (item = null) => {
        this.setState({
            selectedItem: item,
            confirmModalOpen: !this.state.confirmModalOpen
        })
    }

    handleQueryChange = (name, value) => {
        this.setState({
            query: value,
            offset: 0,
            paginationPage: 1
        }, this.fetchData)
    }

    handleViewModal = (item) => {
        this.setState({
            selectedItem: item,
            viewModalOpen: !this.state.viewModalOpen
        });
    }

    handleRestore = (it) => {
        this.props.dispatch(updateResource({
            user: LocalStorage.get("user"),
            query: this.getQuery(),
            params: {
                ArchivedDate: 1,
                [this.getPrimaryKey()]: it[this.getPrimaryKey()]
            },
            resource: Resources.Users,
            piggyResource: Resources.Users
        }));
    }

    /** Helpers
     ================================================================= */
    getPrimaryKey = () => {
        return "ContactID";
    }

    getResourceName = () => {
        return Resources.Users;
    }

    getQuery = () => {
        return {
            limit: this.state.limit,
            offset: this.state.offset,
            sort: this.state.sort,
            sortBy: this.state.sortBy,
            query: this.state.query,
            archived: this.state.archived ? 1 : 0
        }
    }

    getFields = (item = null, excludeFields = null) => {
        const fieldTemplates = {
            FirstName: new Field("FirstName", '', ['empty'], false, "text"),
            LastName: new Field("LastName", '', ['empty'], false, "text"),
            Email: new Field("Email", '', ['empty', 'email'], !!item, "text"),
            RoleID: new Field("RoleID", '', ['empty'], false, "select")
        };

        if (excludeFields) {
            excludeFields.forEach((item) => {
                delete fieldTemplates[item];
            });
        }

        return fillFieldsFromData(fieldTemplates, item);
    };

    getSelectedItemFields = () => {
        return {
            login_time: new Field("login_time", '', [], false, 'datetime'),
            os: new Field("os", '', ['empty'], false, "custom", {
                render: (item) => (
                    <td>
                        <span className='svg-sm mr-2 flex p-3'>{this.returnOSIcon(item.os)} <span className='capitalize'>{item.os}</span></span>
                    </td>
                )
            }),
            Browser: new Field("Browser", '', ['empty'], false, "custom", {
                render: (item) => (
                    <td>
                        <span className='svg-sm mr-2 flex p-3'>{this.returnBrowserIcon(item.Browser)} <span className='capitalize'>{item.Browser}</span></span>
                    </td>
                )
            }),
            user_agent: new Field("user_agent", '', ['empty'], false, "text"),
            location: new Field("location", '', ['empty'], false, "text"),
            ip: new Field("ip", '', ['empty'], false, "text")
        };
    };

    returnOSIcon = (os) => {
        switch (os){
            case "linux":
                return icons.linux
            case "mac":
                return icons.mac
            case "windows":
                return icons.windows
            case "iPad":
                return icons.iPad
            case "iPhone":
                return icons.iPhone
            case "Android":
                return icons.Android
            default:
                return icons.QuestionMark
        }
    }

    returnBrowserIcon = (browser) => {
        let icon
        if (browser) {
            ["Chrome", "Safari", "Firefox", "Opera", "Explorer", "Edge"].forEach(it => {
                if (browser.includes(it)) {
                    icon = icons[it]
                }
            })
        }
        if (icon) {
            return icon
        } else {
            return icons.QuestionMark
        }
    }

    /** Render
     ================================================================= */
    render() {
        const {translate} = this.props;

        const data = getProp(this.props, "resource.data.list", []);
        const count = getProp(this.props, "resource.data.count", 0);

        const isLoading = getProp(this.props, "resource.isLoading", false);

        const metadata = {
            RoleID: {
                1: "Super Admin",
                2: "User"
            }
        };

        return (
            <LayoutDashboard
                onHandleQueryChange={this.handleQueryChange}
                {...this.props}>
                <LayoutDashboardMain>
                    <LayoutDashboardHeader
                        title={translate("page.title.users")}
                    >
                        {checkPerm(Resources.LibraryResource, CREATE_PERM) && (
                            <div className="ml-auto">
                                <div className="inline-block">
                                <ArchivedSwitch
                                    classNameContainer="h-9 mb-2"
                                    translate={translate}
                                    value={this.state.archived}
                                    onChange={(val) => this.setState({archived: val}, this.fetchData)}
                                />
                                </div>

                                <div className={"inline-block ml-2"}>
                                    <button
                                        type="button"
                                        onClick={this.handleToggleCreateModal}
                                        className="btn btn-primary focus:ring-offset-inverse"
                                    >
                                        <PlusIcon className="-ml-1 mr-1 h-5 w-5" aria-hidden="true"/>
                                        {translate("btn.add")}
                                    </button>
                                </div>


                            </div>
                        )}
                    </LayoutDashboardHeader>

                    {/* Page content */}

                        <SimpleTable
                            tableContainerClass="align-middle min-w-full overflow-x-auto md:shadow overflow-hidden relative"

                            data={data}
                            count={count}

                            fields={this.state.fields}
                            translate={this.props.translate}
                            isLoading={isLoading}

                            limit={this.state.limit}
                            offset={this.state.offset}
                            paginationPage={this.state.paginationPage}
                            onOffsetChange={this.handleUpdateOffset}

                            sort={this.state.sort}
                            sortBy={this.state.sortBy}
                            onSortChange={this.handleUpdateSort}

                            onView={this.handleViewModal}
                            onEdit={this.handleToggleEditModel}
                            onDelete={this.handleToggleConfirmModal}

                            onRestore={this.handleRestore}
                        />

                    <DialogDefault
                        title={translate("text.LastTenLogins")}
                        widthClass="max-w-4xl"
                        visible={this.state.viewModalOpen}
                        blurBackdrop={true}
                        onClose={this.handleViewModal}
                        translate={translate}
                    >
                        <SimpleTable
                            data={Array.isArray(this.state.selectedItem?.Logins) ? this.state.selectedItem?.Logins : []}

                            fields={this.state.selectedItemFields}
                            translate={this.props.translate}
                            isLoading={isLoading}
                            limit={this.state.selectedItem?.Logins?.length ?? 0}
                            count={this.state.selectedItem?.Logins?.length ?? 0}
                            offset={0}
                            paginationPage={1}
                        />
                    </DialogDefault>

                    <ModalSaveResource
                        title={"Edit User"}
                        className="max-w-lg"
                        visible={this.state.editModalOpen}
                        onClose={this.handleToggleEditModel}
                        fields={this.getFields(this.state.selectedItem)}
                        onSubmit={(params) => {
                            if (params) {
                                params[this.getPrimaryKey()] = this.state.selectedItem[this.getPrimaryKey()];
                                this.props.dispatch(updateResource({
                                    user: LocalStorage.get("user"),
                                    query: this.getQuery(),
                                    params: params,
                                    resource: this.getResourceName(),
                                    piggyResource: this.getResourceName()
                                }));
                                this.handleToggleEditModel(null)
                            }
                        }}
                        translate={this.props.translate}
                        metadata={metadata}
                    />

                    <ModalSaveResource
                        title={"Add User"}
                        className="max-w-lg"
                        visible={this.state.createModalOpen}
                        onClose={this.handleToggleCreateModal}
                        fields={this.getFields()}
                        onSubmit={(params) => {
                            if (params) {
                                this.props.dispatch(createResource({
                                    user: LocalStorage.get("user"),
                                    query: this.getQuery(),
                                    params: params,
                                    resource: this.getResourceName(),
                                    piggyResource: this.getResourceName()
                                }));
                                this.handleToggleCreateModal(false)
                            }
                        }}
                        translate={this.props.translate}
                        metadata={metadata}
                    />


                    <ModalConfirm
                        title={"Confirm delete"}
                        text={"Are you sure you want to delete?"}
                        onClose={this.handleToggleConfirmModal}
                        onConfirm={() => {
                            this.props.dispatch(deleteResource({
                                user: LocalStorage.get("user"),
                                query: Object.assign({
                                    [this.getPrimaryKey()]: this.state.selectedItem[this.getPrimaryKey()]
                                }, this.getQuery()),
                                resource: this.getResourceName(),
                                piggyResource: this.getResourceName()
                            }));
                            this.handleToggleConfirmModal(false)
                        }}
                        visible={this.state.confirmModalOpen}
                    />

                </LayoutDashboardMain>
            </LayoutDashboard>
        )
    }
}

export default connect(state => state)(UsersPage);
