import React, {Component} from 'react'
import LayoutDashboard from "../../components/layout-dashboard";
import {connect} from "react-redux";
import {createResource, deleteResource, getResource, updateResource} from "../../data/actions/resource";
import LocalStorage from "../../util/localStorage";
import {Field, FieldsManager} from "../../data/services/fields";
import Resources from "../../data/services/resources";
import {
    checkPerm,
    CREATE_PERM,
    DEFAULT_CRUD_STATE,
    DELETE_PERM,
    fillFieldsFromData,
    getProp,
    UPDATE_PERM,
} from "../../util/util";
import {PlusIcon, VideoCameraIcon} from "@heroicons/react/solid";
import SimpleTable from "../../components/simple-table";
import ModalSaveResource from "../../components/modal/modal-save-resource";
import ModalConfirm from "../../components/modal/modal-confirm";
import LayoutDashboardMain from "../../components/layout-dashboard/layout-dashboard-main";
import LayoutDashboardHeader from "../../components/layout-dashboard/layout-dashboard-header";
import Env from "../../util/env";
import ModalVideoPlayer from "../../components/modal/modal-video-player";
import Button from "../../components/button";
import ArchivedSwitch from "../../components/archived-switch";
import {createDataSelect, deleteDataSelect, getDataSelect} from "../../data/selectors/resourceSelectors";

class LibraryPage extends Component {

    constructor(props) {
        super(props);

        this.state = {
            // List read
            ...DEFAULT_CRUD_STATE,
            sortBy: "Exercise",

            // Fields
            fields: this.getFields(),

            // Modals
            selectedItem: null,
            createModalOpen: false,
            editModalOpen: false,
            viewModalOpen: false,
            createContactModelOpen: false,
            confirmModalOpen: false,
            viewVideoModalOpen: false,
        };
    }

    /** Lifecycle
     ================================================================= */
    componentDidMount() {
        this.fetchData();
    };

    /** Data Events
     ================================================================= */
    fetchData = () => {
        this.props.getData({query: this.getQuery(), resource: this.getResourceName()});
    };

    /** UI Events
     ================================================================= */
    handleUpdateSort = (sortBy) => {
        this.setState({
            sortBy: sortBy,
            sort: (this.state.sortBy === sortBy) ? (this.state.sort === "ASC" ? "DESC" : "ASC") : "ASC"
        }, () => this.fetchData())
    };

    handleUpdateOffset = (offset, page) => {
        this.setState({
            offset: offset,
            paginationPage: page
        }, () => this.fetchData());
    }

    handleToggleCreateModal = () => {
        this.setState({
            createModalOpen: !this.state.createModalOpen
        })
    }

    handleToggleConfirmModal = (item = null) => {
        this.setState({
            selectedItem: item,
            confirmModalOpen: !this.state.confirmModalOpen
        })
    }

    handleToggleEditModal = (item = null) => {
        this.setState({
            selectedItem: item,
            editModalOpen: !this.state.editModalOpen
        })
    }

    handleQueryChange = (name, value) => {
        this.setState({
            query: value,
            offset: 0,
            paginationPage: 1
        }, this.fetchData)
    }

    handleInputChange = (fields, name, value) => {
        return FieldsManager.updateField(fields, name, value);
    }

    handleViewVideoModal = (item = null) => {
        this.setState({
            selectedItem: item,
            viewVideoModalOpen: !this.state.viewVideoModalOpen
        })
    }

    handleArchiveSwitch = (val) => {
        this.setState({archived: val, offset: 0, paginationPage: 1}, () => this.fetchData())
    }

    /** Fields/Data Definitions
     ================================================================= */
    getQuery = () => {
        return {
            limit: this.state.limit,
            offset: this.state.offset,
            sort: this.state.sort,
            sortBy: this.state.sortBy,
            query: this.state.query,
            archived: this.state.archived ? 1 : 0
        }
    }

    getFields = (item = null, excludeFields = null, includeFields = null) => {
        const fieldTemplates = {
            Exercise: new Field("Exercise", '', ['empty'], false, "text"),
            LibraryTypeID: new Field("LibraryTypeID", '', [''], false, "select"),
            Description: new Field("Description", '', [''], false, "textarea"),
            ImagePath: new Field('ImagePath', "", [], false, 'video')
        };

        if (excludeFields) {
            excludeFields.forEach((item) => {
                delete fieldTemplates[item];
            });
        }

        return fillFieldsFromData(fieldTemplates, item);
    };

    getPrimaryKey = () => {
        return "LibraryID";
    }

    getResourceName = () => {
        return Resources.LibraryResource;
    }

    /** Render
     ================================================================= */
    render() {
        const {translate} = this.props;

        const data = getProp(this.props, "resource.data.list", []);
        const count = getProp(this.props, "resource.data.count", 0);

        const isLoading = getProp(this.props, "resource.isLoading", false);

        const metadata = "";

        return (
            <LayoutDashboard
                onHandleQueryChange={this.handleQueryChange}
                history={this.props.history}
                translate={this.props.translate}
                location={this.props.location}
            >

                {/** Page content
                 ============================================================*/}
                <LayoutDashboardMain>
                    <LayoutDashboardHeader
                        title={translate("page.title.library")}
                    >
                        {checkPerm(Resources.LibraryResource, CREATE_PERM) && (
                            <div className="ml-auto">
                                <div className="inline-block">
                                    <ArchivedSwitch
                                        classNameContainer="h-9 mb-2"
                                        translate={translate}
                                        value={this.state.archived}
                                        onChange={(val) => this.handleArchiveSwitch(val)}
                                    />
                                </div>

                                <div className={"inline-block ml-2"}>
                                    <button
                                        type="button"
                                        onClick={this.handleToggleCreateModal}
                                        className="btn btn-primary focus:ring-offset-inverse"
                                    >
                                        <PlusIcon className="-ml-1 mr-1 h-5 w-5" aria-hidden="true"/>
                                        {translate("btn.add")}
                                    </button>
                                </div>

                            </div>
                        )}
                    </LayoutDashboardHeader>

                    <SimpleTable
                        tableContainerClass="align-middle min-w-full overflow-x-auto md:shadow overflow-hidden relative"

                        data={data}
                        count={count}

                        fields={this.getFields(null, ["ImagePath"])}
                        translate={this.props.translate}
                        isLoading={isLoading}

                        limit={this.state.limit}
                        offset={this.state.offset}
                        paginationPage={this.state.paginationPage}
                        onOffsetChange={this.handleUpdateOffset}

                        sort={this.state.sort}
                        sortBy={this.state.sortBy}
                        onSortChange={this.handleUpdateSort}

                        onEdit={checkPerm(this.getResourceName(), UPDATE_PERM) && this.handleToggleEditModal}
                        onDelete={checkPerm(this.getResourceName(), DELETE_PERM) && this.handleToggleConfirmModal}
                        onRestore={checkPerm(this.getResourceName(), UPDATE_PERM) && this.handleToggleConfirmModal}

                        onCustomAction={(it) => {
                          return (<Button
                                onClick={() => (it.ImagePath) ? this.handleViewVideoModal(it) : null}
                                type="button"
                                className={"btn btn-outline font-normal " + (it.ImagePath ? "cursor-pointer": "")}
                                disabled={!it.ImagePath}
                            >
                                <VideoCameraIcon className="md:-ml-1 md:mr-2 h-5 w-5 text-secondary-400" aria-hidden="true"/>
                                <span className="hidden md:inline">Play Video</span>
                            </Button>);
                        }}
                    />

                    {/** Modals
                    ============================================================*/}

                    <ModalVideoPlayer
                        title={"View video"}
                        className="max-w-2xl"
                        visible={this.state.viewVideoModalOpen}
                        onClose={this.handleViewVideoModal}
                        translate={this.props.translate}
                        filePath={Env.getApiUrl('api/' + Resources.LibraryImageResource, {
                            [this.getPrimaryKey()]: this.state.selectedItem && this.state.selectedItem[this.getPrimaryKey()],
                            token: LocalStorage.get('user').access_token,
                            video: 1,// Needed as flag in order for backend to stream instead of download
                            name: this.state.selectedItem?.ImagePath
                        })}
                        bookmarks={[]}
                    />

                    <ModalSaveResource
                        title={"Edit Library"}
                        addClass="max-w-lg"
                        visible={this.state.editModalOpen}
                        onClose={this.handleToggleEditModal}
                        fields={this.getFields(this.state.selectedItem)}
                        onSubmit={(params) => {

                            if (params) {
                                params[this.getPrimaryKey()] = this.state.selectedItem[this.getPrimaryKey()];
                                params.id = this.state.selectedItem[this.getPrimaryKey()];
                                this.props.dispatch(updateResource({
                                    user: LocalStorage.get("user"),
                                    query: this.getQuery(),
                                    params: params,
                                    resource: this.getResourceName(),
                                    piggyResource: this.getResourceName(),

                                    file: params?.ImagePath ? params?.ImagePath[0] : null,
                                    fileResource: Resources.LibraryImageResource
                                }));
                                this.handleToggleEditModal(null)
                            }
                        }}
                        translate={this.props.translate}
                        metadata={metadata}
                    />

                    <ModalSaveResource
                        title={"Add Library"}
                        addClass="max-w-lg"
                        visible={this.state.createModalOpen}
                        onClose={this.handleToggleCreateModal}
                        fields={this.getFields()}
                        onSubmit={(params) => {
                            if (params) {
                                this.props.dispatch(createResource({
                                    user: LocalStorage.get("user"),
                                    query: this.getQuery(),
                                    params: params,
                                    resource: this.getResourceName(),
                                    piggyResource: this.getResourceName(),

                                    file: params?.ImagePath ? params?.ImagePath[0] : null,
                                    fileResource: Resources.LibraryImageResource
                                }));
                                this.handleToggleCreateModal(false)
                            }
                        }}
                        translate={this.props.translate}
                        metadata={metadata}
                    />

                    <ModalConfirm
                        title={`Confirm ${!!this.state.selectedItem?.ArchivedDate ? 'restore' : 'delete'}`}
                        text={`Are you sure you want to ${!!this.state.selectedItem?.ArchivedDate ? 'restore' : 'delete'}?`}
                        onClose={this.handleToggleConfirmModal}
                        onConfirm={() => {
                            if (!!this.state.selectedItem.ArchivedDate) {
                                this.props.dispatch(updateResource({
                                    user: LocalStorage.get('user'),
                                    params: {
                                        [this.getPrimaryKey()]: this.state.selectedItem[this.getPrimaryKey()],
                                        ArchivedDate: 1
                                    },
                                    query: this.getQuery(),
                                    resource: this.getResourceName(),
                                    piggyResource: this.getResourceName()
                                }));
                            } else {
                                this.props.dispatch(deleteResource({
                                    user: LocalStorage.get("user"),
                                    query: Object.assign({
                                        [this.getPrimaryKey()]: this.state.selectedItem[this.getPrimaryKey()]
                                    }, this.getQuery()),
                                    resource: this.getResourceName(),
                                    piggyResource: this.getResourceName()
                                }));
                            }
                            this.handleToggleConfirmModal(false)
                        }}
                        visible={this.state.confirmModalOpen}
                    />
                </LayoutDashboardMain>
            </LayoutDashboard>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        resource: state.resource
    };
};

const mapDispatchToProps = (dispatch) => {
    const user = LocalStorage.get("user")

    return {
        getData: getDataSelect({dispatch, user})
    }
};

export default connect(
        mapStateToProps,
        mapDispatchToProps
    )
(LibraryPage);
