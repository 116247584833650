import React from 'react';

const IconSort = (props) => {
    let {className, sort, active} = props;
    className = className ? " " + className : "";

    return (
        <svg className={"h-5 w-5 text-secondary-400 inline-block" + className} xmlns="http://www.w3.org/2000/svg"
             viewBox="0 0 20 20"
             fill="currentColor">
            <path className={"fill-current" + (active && sort === 'DESC' ? " text-gold" : "")}
                  d="m 15,8 a 1,1 0 1 0 -2,0 v 5.586 l -1.293,-1.293 a 1,1 0 0 0 -1.414,1.414 l 3,3 a 1,1 0 0 0 1.414,0 l 3,-3 A 1,1 0 0 0 16.293,12.293 L 15,13.586 Z"
                  id="path14"/>
            <path className={"fill-current" + (active && sort === 'ASC' ? " text-gold" : "")}
                  d="m 5,12 a 1,1 0 1 0 2,0 V 6.414 L 8.293,7.707 A 1,1 0 0 0 9.707,6.293 l -3,-3 a 1,1 0 0 0 -1.414,0 l -3,3 A 1,1 0 0 0 3.707,7.707 L 5,6.414 Z"
                  id="path2"/>
        </svg>
    )
}

export default IconSort;