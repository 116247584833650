const secondThirdReducer = (state = {}, action) => {
    switch (action.type) {
        case 'GET_THIRD_RESOURCE':
            return Object.assign({}, state, {
                data: null,
                isLoading: true,
                error: false,
                errorMessage: null
            });
        case 'DONE_GET_THIRD_RESOURCE':
            return Object.assign({}, state, {
                data: action.data,
                isLoading: false,
                error: false,
                errorMessage: null
            });
        case 'ERROR_GET_THIRD_RESOURCE':
            return Object.assign({}, state, {
                data: null,
                isLoading: false,
                error: true,
                errorMessage: action.data
            });
        case 'CLEAR_THIRD_RESOURCE':
            return Object.assign({}, state, {
                data: null,
            });
        default:
            return state;
    }
};

export default secondThirdReducer;
