import {call, put, takeLatest} from "redux-saga/effects";
import Api from "../services/api";
import {checkUserHelper} from "../services/api-util";
import {
    doneCreateSidebarResource,
    doneDeleteSidebarResource,
    doneGetSidebarResource,
    doneUpdateSidebarResource,
    errorCreateSidebarResource,
    errorDeleteSidebarResource,
    errorGetSidebarResource,
    errorUpdateSidebarResource,
} from "../actions/sidebarResource";
import {pushNotification} from "../actions/ui";

export function* getSidebarResourceCall(action) {
    let user = null;
    if (action.data.user) {
        user = yield* checkUserHelper(action.data.user);
    }

    let result = yield call(Api.getResource, user, action.data.query, action.data.resource);

    if (result && result.status === 0) {
        result.data.silent = action.data.silent;
        yield put(doneGetSidebarResource(result.data));
    } else {
        yield put(errorGetSidebarResource(result.data));
    }
}

export function* watchGetSidebarResource() {
    yield takeLatest('GET_SIDEBAR_RESOURCE', getSidebarResourceCall);
}

export function* createSidebarResourceCall(action) {
    const user = yield* checkUserHelper(action.data.user);

    const result = yield call(Api.createResource, user, action.data.params, action.data.resource);

    if (result && result.status === 0) {
        if (action.data.piggyResource) {
            const resPiggy = yield call(Api.getResource, user, action.data.query, action.data.piggyResource);
            if (resPiggy && resPiggy.status === 0) {
                yield put(doneGetSidebarResource(resPiggy.data));
                yield put(doneCreateSidebarResource(result.data));
            }
        } else {
            yield put(doneCreateSidebarResource(result.data));
        }

        yield put(pushNotification(action));
    } else {
        yield put(errorCreateSidebarResource(result.data));
    }
}

export function* watchCreateSidebarResourceCall() {
    yield takeLatest('CREATE_SIDEBAR_RESOURCE', createSidebarResourceCall);
}

export function* updateSidebarResourceCall(action) {
    const user = yield* checkUserHelper(action.data.user);

    const result = yield call(Api.updateResource, user, action.data.params, action.data.resource);

    if (result && result.status === 0) {
        if (action.data.piggyResource) {
            const resPiggy = yield call(Api.getResource, user, action.data.query, action.data.piggyResource);
            if (resPiggy && resPiggy.status === 0) {
                yield put(doneGetSidebarResource(resPiggy.data));
                yield put(doneUpdateSidebarResource(result.data));
            }
        } else {
            yield put(doneUpdateSidebarResource(result.data));
        }

        yield put(pushNotification(action));
    } else {
        yield put(errorUpdateSidebarResource(result.data));
    }
}

export function* watchUpdateSidebarResource() {
    yield takeLatest('UPDATE_SIDEBAR_RESOURCE', updateSidebarResourceCall);
}

export function* deleteSidebarResourceContactCall(action) {
    const user = yield* checkUserHelper(action.data.user);

    const result = yield call(Api.deleteResource, user, action.data.query, action.data.resource);

    if (result && result.status === 0) {
        if (action.data.piggyResource) {
            const resPiggy = yield call(Api.getResource, user, action.data.piggyQuery ?? action.data.query, action.data.piggyResource);
            if (resPiggy && resPiggy.status === 0) {
                yield put(doneGetSidebarResource(resPiggy.data));
                yield put(doneDeleteSidebarResource(result.data));
            }
        } else {
            yield put(doneDeleteSidebarResource(result.data));
        }

        yield put(pushNotification(action));
    } else {
        yield put(errorDeleteSidebarResource(result.data));
    }
}

export function* watchDeleteSidebarResource() {
    yield takeLatest('DELETE_SIDEBAR_RESOURCE', deleteSidebarResourceContactCall);
}
