import React, {Component} from 'react'
import {getResource, updateResource} from "../../data/actions/resource";
import LocalStorage from "../../util/localStorage";
import {fieldsToHtml, fillFieldsFromData, getLookup, getProp} from "../../util/util";
import {Field, FieldsManager} from "../../data/services/fields";
import Resources from "../../data/services/resources";
import Loader from "../../components/loader";
import Button from "../../components/button";
import FieldCheckbox from "../../components/field-checkbox";

let timezone = require('./timezone.json')
let dateTimeFormat = require('./datetimeformat.json')

export default class AccountTab extends Component {

    constructor(props) {
        super(props);

        this.state = {
            fields: this.getFields(),
            canSubmit: false,
            NotificationSetting: {}
        };
    }

    /** Lifecycle
     ================================================================= */
    componentDidMount() {
        this.fetchData();
    };

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.resource.isLoading && !this.props.resource.isLoading) {
            this.setState({
                fields: this.getFields(getProp(this.props, "resource.data.Contact", {})),
                canSubmit: false,
                NotificationSetting: this.getNotificationSetting()
            })
        }
    }

    /** Data Events
     ================================================================= */
    fetchData = () => {
        this.props.dispatch(getResource({
            user: LocalStorage.get("user"),
            query: {},
            resource: this.getResourceName()
        }))
    };

    onSubmit = () => {
        if (this.state.canSubmit) {
            this.setState({fields: FieldsManager.validateFields(this.state.fields)}, () => {
                if (FieldsManager.checkFieldsForErrors(this.state.fields)) {
                    this.setState({canSubmit: false}, () => {

                        let user = LocalStorage.get('user');
                        user.Contact.Timezone = this.state.fields.Timezone.value;
                        user.Contact.DateTimeFormat = this.state.fields.DateTimeFormat.value;
                        LocalStorage.set('user', user);

                        // destruction multi dimensional array to a single array
                        const NotificationSettingArray = [];
                        this.state.NotificationSetting.forEach((arrays, index) => arrays.forEach((item, i) => NotificationSettingArray.push(FieldsManager.getFieldKeyValues(item))))

                        this.props.dispatch(updateResource({
                            user: LocalStorage.get("user"),
                            params: Object.assign({}, FieldsManager.getFieldKeyValues(this.state.fields), {NotificationSetting: NotificationSettingArray}),
                            resource: this.getResourceName(),
                            piggyResource: this.getResourceName()
                        }));
                    });
                }
            });
        }
    }

    /** UI Events
     ================================================================= */
    handleInputChange = (name, value) => {
        this.setState({canSubmit: true, fields: FieldsManager.updateField(this.state.fields, name, value)});
    };

    handleCancel = () => {
        const data = getProp(this.props, "resource.data.Contact", null);

        this.setState({
            fields: this.getFields(data),
            canSubmit: false
        })
    };

    handleNotificationSettingChange = (id, val) => {
        let NotificationSetting = this.state.NotificationSetting;

        NotificationSetting[id] = val;

        this.setState({
            NotificationSetting: NotificationSetting,
            canSubmit: true
        })
    }

    handleCheckboxChange = (i, j, name, value) => {
        let fields = this.state.NotificationSetting[i][j];
        const newItems = this.state.NotificationSetting;
        newItems[i][j] = FieldsManager.updateField(fields, name, value);
        this.setState({NotificationSetting: newItems, canSubmit: true})
    };


    /** Helpers
     ================================================================= */
    getResourceName = () => {
        return Resources.User;
    }

    getFields = (item = null) => {
        const fieldTemplates = {
            FirstName: new Field('FirstName', "", ['empty']),
            LastName: new Field('LastName', "", ['empty']),
            Phone: new Field('Phone', "", []),
            Timezone: new Field('Timezone', "", ['empty'], false, 'select'),
            DateTimeFormat: new Field('DateTimeFormat', "", ['empty'], false, 'select')
        }

        return fillFieldsFromData(fieldTemplates, item);
    };

    getNotificationSetting = () => {
        const NotificationSetting = getProp(this.props.resource, 'data.NotificationSetting', []);
        const NotificationSettingFields = [];
        NotificationSetting.forEach((array, index) => {
            const secondDimensionArray = []
            array.forEach((item, i) => {
                secondDimensionArray.push({
                    NotificationChannel: new Field('NotificationChannel', item.NotificationChannel, ['']),
                    NotificationChannelID: new Field('NotificationChannelID', item.NotificationChannelID, ['']),
                    NotificationLevel: new Field('NotificationLevel', item.NotificationLevel, ['']),
                    NotificationLevelID: new Field('NotificationLevelID', item.NotificationLevelID, ['']),
                    Value: new Field('Value', item.Value, [], false, 'checkbox')
                })
            })
            NotificationSettingFields.push(secondDimensionArray)
        })
        return NotificationSettingFields;
    }

    /** Render
     ================================================================= */
    render() {
        const {translate} = this.props;

        const isLoading = getProp(this.props, "resource.isLoading", false);

        const metadata = {
            Timezone: timezone,
            DateTimeFormat: dateTimeFormat
        };

        const NotificationChannel = Object.values(getLookup("NotificationChannel", "NotificationChannelID", "NotificationChannel"));
        NotificationChannel.unshift('')
        const NotificationLevel = Object.values(getLookup("NotificationLevel", "NotificationLevelID", "NotificationLevel"));

        return (
            <div className="">
                {isLoading && (
                    <div className={"text-center"}>
                        <Loader/>
                    </div>
                )}

                {!isLoading && (
                    <>
                        <form className="space-y-8 divide-y divide-gray-200">
                            {fieldsToHtml(Object.values(Object.assign({}, this.state.fields)), translate, this.handleInputChange, metadata)}

                            <fieldset className="space-y-5">
                                <legend className="sr-only">Notifications</legend>
                                <p className={"mb-1 mt-2 font-bold"}>Notification settings</p>
                                <p className="mb-1 text-sm text-secondary-500">Select on which channel(s) to receive notifications.</p>
                                <div className="container mx-auto pt-10">
                                    <div className="grid grid-cols-5 gap-6">
                                        {NotificationChannel.map((channel, index) => (
                                            <div className=" text-sm font-bold text-secondary-500 text-center mb-5">
                                                {channel}
                                            </div>
                                        ))}
                                    </div>
                                    {this.state.NotificationSetting.length > 0 && this.state.NotificationSetting.map((row, i) => (
                                        <div className="grid   grid-cols-5 gap-6 mt-2" key={i}>
                                            <div className=" mb-3 text-sm font-bold text-secondary-500 text-right">
                                                {NotificationLevel[i]}
                                            </div>
                                            {row.map((col, j) => (
                                                    <div
                                                        className="text-center">
                                                        <FieldCheckbox
                                                            className=""
                                                            onChange={(name, value) => this.handleCheckboxChange(i, j, name, value)}
                                                            {...this.state.NotificationSetting[i][j].Value}
                                                        />
                                                        <label className="custom-switch-btn"/>
                                                    </div>
                                                )
                                            )}
                                        </div>
                                    ))}
                                </div>
                            </fieldset>
                        </form>

                        <div className="pt-5">
                            <div className="flex justify-end">
                                <Button type="outline"
                                        className="btn btn-outline focus:ring-offset-inverse"
                                        disabled={!this.state.canSubmit}
                                        onClick={this.handleCancel}
                                >
                                    {translate("btn.cancel")}
                                </Button>

                                <Button
                                    type="submit"
                                    className="btn btn-primary ml-3 focus:ring-offset-inverse"
                                    disabled={!this.state.canSubmit}
                                    onClick={this.state.canSubmit && this.onSubmit}
                                >
                                    {translate("btn.save")}
                                </Button>
                            </div>
                        </div>
                    </>
                )}
            </div>
        )
    }
}
