import React, {Fragment} from 'react'
import {Transition} from '@headlessui/react'
import {CheckCircleIcon} from '@heroicons/react/outline'
import {XIcon} from '@heroicons/react/solid'
import {classNames, getButtonType} from "../../util/util"

export default function Notification({id, show, onClose, title, message, actions, onActionClick}) {
    const buttons = actions.map((it, i) => {
        return (
            <button
                className={classNames("btn justify-center flex-grow", getButtonType(it.Data?.btnType, i))}
                onClick={() => onActionClick(it.Name, it.Data, id)}
            >
                {it.Name}
            </button>
        )})

    return (
        <>
            {/* Notification panel, dynamically insert this into the live region when it needs to be displayed */}
            <Transition
                show={show}
                as={Fragment}
                enter="transform ease-out duration-300 transition"
                enterFrom="translate-y-2 opacity-0 sm:translate-y-0 sm:translate-x-2"
                enterTo="translate-y-0 opacity-100 sm:translate-x-0"
                leave="transition ease-in duration-100"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
            >
                <div
                    className="js-notification max-w-sm w-full bg-white shadow-lg rounded-lg pointer-events-auto ring-1 ring-black ring-opacity-5 overflow-hidden"
                    id=""
                >
                    <div className="p-4">
                        <div className="flex items-start">

                            <div className="flex-shrink-0">
                                <CheckCircleIcon className="h-6 w-6 text-green-400" aria-hidden="true"/>
                            </div>

                            <div className="ml-3 w-0 flex-1 pt-0.5">
                                <p className="text-sm font-medium text-gray-900">{title}</p>
                                <p className="mt-1 text-sm text-gray-500">{message}</p>

                                {!!buttons.length && (
                                    <div className="flex mt-4 space-x-3">
                                        {buttons}
                                    </div>
                                )}
                            </div>

                            <div className="ml-4 flex-shrink-0 flex">
                                <button
                                    className="bg-white rounded-md inline-flex text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                                    onClick={(e) => {
                                        e.preventDefault();
                                        e.stopPropagation();
                                        e.nativeEvent.stopImmediatePropagation();
                                        onClose()
                                    }}
                                >
                                    <span className="sr-only">Close</span>

                                    <XIcon className="h-5 w-5" aria-hidden="true"/>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </Transition>
        </>
    )
}