import React, {Component} from 'react'
import LayoutDashboard from "../../components/layout-dashboard";
import {connect} from "react-redux";
import {getResource} from "../../data/actions/resource";
import LocalStorage from "../../util/localStorage";
import {Field, FieldsManager} from "../../data/services/fields";
import Resources from "../../data/services/resources";
import {getProp} from "../../util/util";
import SimpleTable from "../../components/simple-table";
import icons from "../../util/icons";
import PageHeader from "../../components/layout-dashboard/page-header";

class LoggedDevicesPage extends Component {

    constructor(props) {
        super(props);

        this.state = {
            offset: 0,
            limit: 100,
            paginationPage: 1,
            sort: "ASC",
            sortBy: "",

            query: "",
            archived: false,

            fields: this.getFields()
        };
    }

    /** Lifecycle
     ================================================================= */
    componentDidMount() {
        this.fetchData();
    };

    /** Data Events
     ================================================================= */
    fetchData = () => {
        this.props.dispatch(getResource({
            user: LocalStorage.get("user"),
            query: this.getQuery(),
            resource: this.getResourceName()
        }))
    };

    /** UI Events
     ================================================================= */
    handleUpdateSort = (sortBy) => {
        this.setState({
            sortBy: sortBy,
            sort: (this.state.sortBy === sortBy) ? (this.state.sort === "ASC" ? "DESC" : "ASC") : "ASC"
        }, () => this.fetchData())
    };

    handleUpdateOffset = (offset, page) => {
        this.setState({
            offset: offset,
            paginationPage: page
        }, () => this.fetchData());
    }

    handleQueryChange = (name, value) => {
        this.setState({
            query: value
        }, this.fetchData)
    }

    handleSearchSelectQueryChange = (name, value) => {
        this.setState({searchFields: FieldsManager.updateField(this.state.searchFields, name, value)}, this.fetchData);
    }

    /** Helpers
     ================================================================= */
    getPrimaryKey = () => {
        return "user_id";
    }

    getResourceName = () => {
        return Resources.UserDevices;
    }

    getValidSearchFields = () => {
        let fields = FieldsManager.validateFields(this.state.searchFields);

        if (FieldsManager.checkFieldsForErrors(fields)) {
            return FieldsManager.getFieldKeyValues(this.state.searchFields);
        } else {
            this.setState({searchFields: fields});
        }
    }

    getQuery = () => {
        return {
            limit: this.state.limit,
            offset: this.state.offset,
            sort: this.state.sort,
            sortBy: this.state.sortBy,
            query: this.state.query
        }
    }

    returnOSIcon = (os) => {
        switch (os){
            case "linux":
                return icons.linux
            case "mac":
                return icons.mac
            case "windows":
                return icons.windows
            case "iPad":
                return icons.iPad
            case "iPhone":
                return icons.iPhone
            case "Android":
                return icons.Android
            default:
                return icons.QuestionMark
        }
    }

    returnBrowserIcon = (browser) => {
        let icon
        if (browser) {
            ["Chrome", "Safari", "Firefox", "Opera", "Explorer", "Edge"].forEach(it => {
                if (browser.includes(it)) {
                    icon = icons[it]
                }
            })
        }
        if (icon) {
            return icon
        } else {
            return icons.QuestionMark
        }
    }

    getFields = () => {
        /*
        $d['os'] = strtok($device['user_agent'], " ");
            preg_match('/\((.*?)\)/', $device['user_agent'], $match);
            $d['host'] = $match[1];
            $d['Browser'] = explode(", ",$device['user_agent'])[1];
            $d['user_agent'] = $device['user_agent'];
            $d['ip'] = $device['ip'];
            $d['location'] = $device['location'];
            $dev['list'][] = $d;
         */
        return {
            os: new Field("os", '', ['empty'], false, "custom", {
                render: (item) => (
                    <td>
                        <span className='svg-sm mr-2 flex p-3'>{this.returnOSIcon(item.os)} <span className='capitalize'>{item.os}</span></span>

                    </td>
                )
            }),
            Browser: new Field("Browser", '', ['empty'], false, "custom", {
                render: (item) => (
                    <td>
                        <span className='svg-sm mr-2 flex p-3'>{this.returnBrowserIcon(item.Browser)} <span className='capitalize'>{item.Browser}</span></span>
                    </td>
                )
            }),
            user_agent: new Field("user_agent", '', ['empty'], false, "text"),
            ip: new Field("ip", '', ['empty'], false, "text"),
            location: new Field("location", '', ['empty'], false, "text")
        };
    };

    /** Render
     ================================================================= */
    render() {
        const {translate} = this.props;

        const data = getProp(this.props, "resource.data.list", []);
        const count = getProp(this.props, "resource.data.count", 0);

        const isLoading = getProp(this.props, "resource.isLoading", false);

        return (
            <LayoutDashboard {...this.props}>
                <div className="space-y-6 sm:px-6 lg:px-0 lg:col-span-12">
                    <PageHeader
                        title={translate("page.title.devices")}
                    />

                    {/* Page content */}
                    <div className="p-4 sm:p-6 lg:p-8">
                        <SimpleTable
                            data={data}
                            count={count}

                            fields={this.state.fields}
                            translate={this.props.translate}
                            isLoading={isLoading}

                            limit={this.state.limit}
                            offset={this.state.offset}
                            paginationPage={this.state.paginationPage}
                            onOffsetChange={this.handleUpdateOffset}

                            sort={this.state.sort}
                            sortBy={this.state.sortBy}
                            onSortChange={this.handleUpdateSort}
                            removePagination={true}
                        />
                    </div>
                </div>
            </LayoutDashboard>
        )
    }
}

export default connect(state => state)(LoggedDevicesPage);
