export function getSidebarResource(data) {
    return {
        type: 'GET_SIDEBAR_RESOURCE',
        data: data
    };
}

export function doneGetSidebarResource(data) {
    return {
        type: 'DONE_GET_SIDEBAR_RESOURCE',
        data: data
    };
}

export function errorGetSidebarResource(data) {
    return {
        type: 'ERROR_GET_SIDEBAR_RESOURCE',
        data: data
    };
}

export function getSidebarResourceDetails(data) {
    return {
        type: 'GET_SIDEBAR_RESOURCE_DETAILS',
        data: data
    };
}

export function doneGetSidebarResourceDetails(data) {
    return {
        type: 'DONE_GET_SIDEBAR_RESOURCE_DETAILS',
        data: data
    };
}

export function errorGetSidebarResourceDetails(data) {
    return {
        type: 'ERROR_GET_SIDEBAR_RESOURCE_DETAILS',
        data: data
    };
}

export function createSidebarResource(data) {
    return {
        type: 'CREATE_SIDEBAR_RESOURCE',
        data: data
    };
}

export function doneCreateSidebarResource(data) {
    return {
        type: 'DONE_CREATE_SIDEBAR_RESOURCE',
        data: data
    };
}

export function errorCreateSidebarResource(data) {
    return {
        type: 'ERROR_CREATE_SIDEBAR_RESOURCE',
        data: data
    };
}

export function updateSidebarResource(data) {
    return {
        type: 'UPDATE_SIDEBAR_RESOURCE',
        data: data
    };
}

export function doneUpdateSidebarResource(data) {
    return {
        type: 'DONE_UPDATE_SIDEBAR_RESOURCE',
        data: data
    };
}

export function errorUpdateSidebarResource(data) {
    return {
        type: 'ERROR_UPDATE_SIDEBAR_RESOURCE',
        data: data
    };
}

export function deleteSidebarResource(data) {
    return {
        type: 'DELETE_SIDEBAR_RESOURCE',
        data: data
    };
}

export function doneDeleteSidebarResource(data) {
    return {
        type: 'DONE_DELETE_SIDEBAR_RESOURCE',
        data: data
    };
}

export function errorDeleteSidebarResource(data) {
    return {
        type: 'ERROR_DELETE_SIDEBAR_RESOURCE',
        data: data
    };
}

export function resetSidebarResourceMessage() {
    return {
        type: 'RESET_SIDEBAR_RESOURCE_MESSAGE'
    }
}