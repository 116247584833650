import React, {Component} from "react";

export default class FieldText extends Component {

    render() {
        const addClass = this.props.className ? " " + this.props.className : "";

        return (
            <React.Fragment>
                <input
                    type={this.props.type ?? "text"}
                    onKeyDown={(e) => this.props.handleKeyDown ? this.props.handleKeyDown(e) : null}
                    onChange={(event) => {
                        this.props.onChange(this.props.name, event.target.value);
                    }}
                    name={this.props.name}
                    value={this.props.value}
                    placeholder={this.props.placeholder}
                    disabled={this.props.disabled}
                    id={this.props.id}
                    className={addClass}
                />

                {this.props.errorMessage && (
                    <div className={"text-red-700 italic mt-1 text-sm"}>
                        {this.props.errorLabelMessage ? this.props.errorLabelMessage : "Please fill out this field."}
                    </div>
                )}
            </React.Fragment>
        )
    }
}