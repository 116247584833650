import React, {Component} from 'react'
import LayoutDashboard from "../../components/layout-dashboard";
import {connect} from "react-redux";
import LocalStorage from "../../util/localStorage";
import {Field, FieldsManager} from "../../data/services/fields";
import Resources from "../../data/services/resources";
import {
    checkPerm, classNames,
    CREATE_PERM, DEFAULT_CRUD_STATE,
    fieldsToHtml,
    fillFieldsFromData,
    getProp,
    toFrontDate,
    toFrontTime,
} from "../../util/util";
import {PaperClipIcon, PencilIcon, PlusIcon} from "@heroicons/react/solid";
import Loader from "../../components/loader";
import ModalSaveResource from "../../components/modal/modal-save-resource";
import ModalConfirm from "../../components/modal/modal-confirm";
import {getSecondResource} from "../../data/actions/secondResource";
import SimpleTable from "../../components/simple-table";
import LayoutDashboardMain from "../../components/layout-dashboard/layout-dashboard-main";
import LayoutDashboardHeader from "../../components/layout-dashboard/layout-dashboard-header";
import {ClockIcon} from "@heroicons/react/outline";
import InformationCircleIcon from "@heroicons/react/outline/InformationCircleIcon";
import ReplyIcon from "@heroicons/react/solid/ReplyIcon";
import TrashIcon from "@heroicons/react/solid/TrashIcon";
import moment from 'moment';
import {Switch} from "@headlessui/react";
import {
    createDataSelect,
    deleteDataSelect,
    getDataSelect,
    updateDataSelect
} from "../../data/selectors/resourceSelectors";

class CalendarItemsPage extends Component {

    constructor(props) {
        super(props);

        this.state = {
            // List read
            ...DEFAULT_CRUD_STATE,
            sortBy: "Date",

            // Fields
            fields: this.getFields(),
            searchFields: this.getSearchFields(false),

            // Modals
            createModalOpen: false,
            editModalOpen: false,
            startModalOpen: false,
            skipModalOpen: false,
            startExerciseModalOpen: false,
            completeExerciseModalOpen: false,
            confirmModalOpen: false,

            // Other
            showAll: false
        };
    }

    /** Lifecycle
     ================================================================= */
    componentDidMount() {
        this.fetchData();
    };

    componentDidUpdate(prevProps, prevState, snapshot) {
       if (this.props.sidebarResource.isLoading !== prevProps.sidebarResource.isLoading) {
            this.fetchData();
        }
    }

    /** Data Events
     ================================================================= */
    fetchData = () => {
        this.props.getData({query: this.getQuery(), resource: this.getResourceName()});
    }

    fetchSecondData = () => {
        this.props.dispatch(getSecondResource({
            user: LocalStorage.get("user"),
            query: this.getSearchQuery(),
            resource: Resources.TemplateSingleItemsResource
        }))
    }

    /** UI Events
     ================================================================= */
    handleToggleCreateModal = (item = null) => {
        this.setState({
            selectedItem: item,
            createModalOpen: !this.state.createModalOpen
        })
    }

    handleToggleEditModal = (item = null) => {
        this.setState({
            selectedItem: item,
            editModalOpen: !this.state.editModalOpen
        })
    }

    handleToggleSkipModal = (item = null) => {
        this.setState({
            selectedItem: item,
            skipModalOpen: !this.state.skipModalOpen
        })
    }

    handleToggleStartModal = (item = null) => {
        this.setState({
            selectedItem: item,
            startModalOpen: !this.state.startModalOpen
        })
    }

    handleToggleStartExerciseModal = (item = null) => {
        this.setState({
            selectedItem: item,
            startExerciseModalOpen: !this.state.startExerciseModalOpen
        })
    }

    handleToggleCompleteExerciseModal = (item = null) => {
        this.setState({
            selectedItem: item,
            completeExerciseModalOpen: !this.state.completeExerciseModalOpen
        })
    }

    handleToggleConfirmModal = (item = null) => {
        this.setState({
            selectedItem: item,
            confirmModalOpen: !this.state.confirmModalOpen
        })
    }

    handleSearchInputChange = (name, value) => {
        this.setState({searchFields: FieldsManager.updateField(this.state.searchFields, name, value)}, this.fetchSecondData);
    }

    handleCellClick = (cellData = null, time) => {
        // Ignore if cell already has some data or if lacking create permission
        if (!!cellData || !checkPerm(Resources.CalendarResource, CREATE_PERM)) {
            return;
        }

        time = this.props.match.params.id + " " + time;

        this.setState({
            selectedItem: {Date: time},
            createModalOpen: !this.state.createModalOpen
        })
    }

    handleUpdateSort = (sortBy) => {
        this.setState({
            sortBy: sortBy,
            sort: (this.state.sortBy === sortBy) ? (this.state.sort === "ASC" ? "DESC" : "ASC") : "ASC"
        }, () => this.fetchSecondData())
    };

    handleUpdateOffset = (offset, page) => {
        this.setState({
            offset: offset,
            paginationPage: page
        }, () => this.fetchSecondData());
    }

    /** Fields/Data Definitions
     ================================================================= */
    getQuery = () => {
        return {
            limit: 100,
            offset: 0,
            searchFields: JSON.stringify({
                StartDate: [
                    "Date", ">=", moment(this.props.match.params.id).format("YYYY-MM-DD") + " 00:00:00"],
                EndDate: [
                    "Date", "<=", moment(this.props.match.params.id).format("YYYY-MM-DD") + " 23:59:00"
                ]
            })
        }
    }

    getSearchQuery = () => {
        return {
            sort: this.state.sort,
            sortBy: this.state.sortBy,
            query: this.state.query,
            TemplateID: this.state.searchFields.TemplateID.value.value,
        }
    }

    getFields = (item = null, excludeFields = null) => {
        const fieldTemplates = {
            LibraryTypeID: new Field("LibraryTypeID", '', ['empty'], false, "select"),
            Date: new Field("Date", "", ['empty'], false, "time-custom", {}, {hoursOnly: "true"}),
            BlockLength: new Field("BlockLength", '', ['empty'], false, "select"),
            LibraryLength: new Field("LibraryLength", '', ['empty'], false, "select"),
            LibraryID: new Field("LibraryID", '', ['empty'], false, "select-search"),
            Description: new Field("Description", '', [''], false, "textarea")
        };

        if (excludeFields) {
            excludeFields.forEach((item) => {
                delete fieldTemplates[item];
            });
        }

        return fillFieldsFromData(fieldTemplates, item);
    };

    getFieldsDeeper = (item = null, excludeFields = null) => {
        const fieldTemplates = {
            LibraryTypeID: new Field("LibraryTypeID", '', ['empty'], true, "select"),
            LibraryLength: new Field("LibraryLength", '', ['empty'], false, "select"),
            LibraryID: new Field("LibraryID", '', ['empty'], false, "select-search"),
            Description: new Field("Description", '', [''], false, "textarea")
        };

        if (excludeFields) {
            excludeFields.forEach((item) => {
                delete fieldTemplates[item];
            });
        }

        return fillFieldsFromData(fieldTemplates, item);
    };

    getSearchFields = () => {
        return {
            TemplateID: new Field("TemplateID", '', [''], false, 'select-search')
        };
    }

    getPrimaryKey = () => {
        return "CalendarID";
    }

    getResourceName = () => {
        return Resources.CalendarResource;
    }

    /** Helpers
     ================================================================= */
    formatTimeLabel = (label) => {
        let time = label.split(' ');
        if (time[0].length === 1) {
            time[0] = "0" + time[0] + ":00";
        }
        time = time.join(' ');

        return moment(time, "hh:mm a").format("HH:mm:ss");
    }

    /** Render
     ================================================================= */
    render() {
        const {translate, history, location, createData, updateData, deleteData} = this.props;

        const data = getProp(this.props, "resource.data.list", []);

        const isLoading = getProp(this.props, "resource.isLoading", false);

        const settings = getProp(this.props, "resource.data.Settings", {});
        const templateData = getProp(this.props, 'secondResource.data.list', []);
        const isLoadingSecond = getProp(this.props, "secondResource.isLoading", false);
        const countSecond = getProp(this.props, "secondResource.data.count", 0);
        const tableFields = ["LibraryTypeID", "Date", "BlockLength", "LibraryLength", "LibraryID", "Description"];

        const metadata =
            {
                BlockLength: {
                    1: "1 Hour",
                    2: "2 Hours",
                    3: "3 Hours",
                    4: "4 Hours",
                },
                LibraryLength: {
                    1: "1 Minutes",
                    2: "2 Minutes",
                    3: "3 Minutes",
                    4: "4 Minutes",
                    5: "5 Minutes",
                },
                LibraryID: {
                    visibleFilter: (fields) => (!fields[0]?.value),
                    api: 'api/' + Resources.LibraryResource,
                    query: (fields) => {
                        return {
                            includeRandom: 1,
                            searchFields: JSON.stringify({
                                LibraryTypeID: fields[0]?.value
                            })
                        }
                    },
                    searchMap: (item) => ({
                        value: item.LibraryID,
                        label: item.Exercise
                    })
                }
            }

        let allRows = [];
        let occupiedRow = [];
        let timeLabels = [];

        for (let i = 0; i < 24; i++) {
            if (
                ((i >= moment(settings.StartTime).format("H")) && (i <= moment(settings.EndTime).format("H")))
                || (!!this.state.showAll)
            ) {
                allRows[i] = i;
                occupiedRow[i] = false;
            }
        }

        const isAnyBlockRunning = data?.reduce((memo, it) => {
            return memo || (it['CalendarStatusID'] === 1 && it['CalendarLibraryStatusID'] === 1)
        }, false);

        const rows = Object.keys(allRows).map((it, i) => {
            let timeLabel = '';
            if (it > 12) {
                timeLabel = (it - 12) + " PM";
            } else {
                timeLabel = it + " AM";
            }

            timeLabels.push(this.formatTimeLabel(timeLabel));

            let currentItem = data.filter(
                ti => moment(ti.Date).format("H") == it
            ).reduce((memo, tit) => {
                memo.push(tit);
                return memo;
            }, []);

            if (currentItem[0]) {
                let max = parseInt(currentItem[0]['BlockLength']) + parseInt(it);

                for (let y = parseInt(it) + parseInt(1); y < max; y++) {
                    occupiedRow[y] = true;
                }
            }

            return (
                <tr>
                    <td className={"border-secondary-200 p-3 text-center text-secondary-900 border-r font-bold w-3/12 h-12 " + ((occupiedRow[it] || currentItem[0]) ? "bg-secondary-100" : "bg-secondary-50")}>{timeLabel}</td>
                    {!occupiedRow[it] && (
                        <td rowSpan={currentItem[0] && currentItem[0]['BlockLength']}
                            className={"border-secondary-200 p-3 text-secondary-900 border-r w-32 h-12 " + (currentItem[0] ? "bg-secondary-100" : "bg-secondary-50 hover:bg-secondary-100 cursor-pointer")}
                            onClick={() => this.handleCellClick(currentItem[0], timeLabels[i])}
                        >
                            <div className="flex">
                                <div className="flex-grow pr-2">
                                    <p className="text-lg font-bold mb-3">
                                        {!!currentItem[0] && currentItem[0]['LibraryType']}
                                    </p>

                                    <p className="text-sm ">
                                        {!!currentItem[0] && currentItem[0]['Library']}
                                    </p>
                                    <p className="text-sm">
                                        {!!currentItem[0] && currentItem[0]['Description']}
                                    </p>
                                </div>

                                {currentItem[0] && (
                                    <div className="flex flex-col w-26 space-y-3">

                                        {!currentItem[0]['CalendarStatusID'] && (
                                            <>
                                                <p className="text-sm text-center">Block actions</p>

                                                {!isAnyBlockRunning && (moment().format("YYYY-MM-DD") === this.props.match.params.id) && (
                                                    <>
                                                        <button
                                                            onClick={() => this.handleToggleStartModal(currentItem[0])}
                                                            className="inline-flex items-center px-4 py-2 border border-secondary-300 rounded-md shadow-sm text-sm font-medium text-white bg-green-500 hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-secondary-50 focus:ring-primary-500"
                                                        >
                                                            <ClockIcon className="-ml-1 mr-2 h-5 w-5 text-white"
                                                                       aria-hidden="true"/>
                                                            Start
                                                        </button>
                                                        <button
                                                            onClick={() => this.handleToggleSkipModal(currentItem[0])}
                                                            className="inline-flex items-center px-4 py-2 border border-secondary-300 rounded-md shadow-sm text-sm font-medium text-secondary-700 bg-inverse hover:bg-secondary-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-secondary-50 focus:ring-primary-500"
                                                        >
                                                            <ReplyIcon className="-ml-1 mr-2 h-5 w-5 text-secondary-400" style={{transform: "scale(-1,1)"}}
                                                                         aria-hidden="true"/>
                                                            Skip
                                                        </button>
                                                    </>
                                                )}

                                                {(moment().format("YYYY-MM-DD") <= this.props.match.params.id) && (
                                                    <button
                                                        onClick={() => this.handleToggleEditModal(currentItem[0])}
                                                        className="inline-flex items-center px-4 py-2 border border-secondary-300 rounded-md shadow-sm text-sm font-medium text-secondary-700 bg-inverse hover:bg-secondary-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-secondary-50 focus:ring-primary-500"
                                                    >
                                                        <PencilIcon className="-ml-1 mr-2 h-5 w-5 text-secondary-400"
                                                                    aria-hidden="true"/>
                                                        Edit
                                                    </button>
                                                )}

                                                <button
                                                    onClick={() => this.handleToggleConfirmModal(currentItem[0])}
                                                    className="inline-flex items-center px-4 py-2 border border-secondary-300 rounded-md shadow-sm text-sm font-medium text-secondary-700 bg-inverse hover:bg-secondary-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-secondary-50 focus:ring-primary-500"
                                                >
                                                    <TrashIcon className="md:-ml-1 md:mr-2 h-5 w-5 text-secondary-400"
                                                               aria-hidden="true"/>

                                                    <span className="hidden md:inline">Delete</span>
                                                </button>
                                            </>
                                        )}
                                    </div>
                                )}
                            </div>

                            {currentItem[0] && (
                                <div className="flex space-x-3 mt-3">
                                    {!isAnyBlockRunning && (currentItem[0]['CalendarStatusID'] === 1 && currentItem[0]['CalendarLibraryStatusID'] !== 1 ) && (
                                        <button
                                            onClick={() => this.handleToggleStartExerciseModal(currentItem[0])}
                                            className="flex-grow inline-flex items-center px-4 py-2 border border-secondary-300 rounded-md shadow-sm text-sm font-medium text-white bg-green-500 hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-secondary-50 focus:ring-primary-500"
                                        >
                                            <ClockIcon className="-ml-1 mr-2 h-5 w-5 text-white"
                                                       aria-hidden="true"/>
                                            Start Exercise
                                        </button>
                                    )}

                                    {(currentItem[0]['CalendarStatusID'] === 1 && currentItem[0]['CalendarLibraryStatusID'] === 1) && (
                                        <button
                                            onClick={() => this.handleToggleCompleteExerciseModal(currentItem[0])}
                                            className="flex-grow inline-flex items-center px-4 py-2 border border-secondary-300 rounded-md shadow-sm text-sm font-medium text-white bg-green-500 hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-secondary-50 focus:ring-primary-500"
                                        >
                                            <ClockIcon className="-ml-1 mr-2 h-5 w-5 text-white"
                                                       aria-hidden="true"/>
                                            Complete Exercise
                                        </button>
                                    )}
                                </div>
                            )}

                            <div className="flex justify-between items-center mt-3">
                                {!!currentItem[0] && !!currentItem[0]['CalendarStatusID'] && (
                                    <div className={"relative h-7 mt-2"}>
                                        <span className="block mb-2">Block - {currentItem[0]['CalendarStatus']}</span>
                                    </div>
                                )}

                                {!!currentItem[0] && !!currentItem[0]['CalendarStatusID'] && (
                                    <div className={"relative h-7 mt-2"}>
                                        <span className="group">
                                            <div className="hidden group-hover:flex absolute right-0 bottom-0 flex-col mb-6 z-30">
                                                <span
                                                    className="relative z-10 p-2 rounded-md text-xs leading-none text-black whitespace-nowrap bg-white shadow-lg">
                                                        <span className="block mb-2 font-bold">Block - {currentItem[0]['CalendarStatus']}</span>

                                                        {(currentItem[0]['CalendarBlockStarted'] || currentItem[0]['CalendarBlockCompleted']) && (
                                                            <div className={"mt-2 pt-2 border-secondary-200 border-t-2"}>
                                                                <span className="block mb-2">{currentItem[0]['CalendarBlockStarted'] ? ("Block Started At " + toFrontTime(currentItem[0]['CalendarBlockStarted'])) : ""}</span>
                                                            </div>
                                                        )}

                                                        {(currentItem[0]['CalendarLibraryStatusID'] || currentItem[0]['LibraryStarted']) && (
                                                            <div >
                                                                <span className="block mb-2">{currentItem[0]['LibraryStarted'] ? ("Exercise Started At " + toFrontTime(currentItem[0]['LibraryStarted'])) : ""}</span>
                                                                <span className="block mb-2">{currentItem[0]['CalendarBlockCompleted'] ? ("Block with Exercise Completed At " + toFrontTime(currentItem[0]['CalendarBlockCompleted'])) : ""}</span>
                                                            </div>
                                                        )}
                                                </span>
                                                <div className="flex flex-row-reverse">
                                                    <div className="w-3 h-3 -mt-2 mr-2.5 rotate-45 bg-white"></div>
                                                </div>
                                            </div>
                                            <InformationCircleIcon className="w-8 h-8 absolute top-0 right-0 cursor-pointer text-green-500" />
                                        </span>
                                    </div>
                                )}
                            </div>
                        </td>
                    )}
                </tr>
            );
        })

        const searchFieldsHtml = fieldsToHtml(Object.values(Object.assign({}, this.state.searchFields)), translate, this.handleSearchInputChange, {
            TemplateID: {
                api: 'api/' + Resources.TemplateResource,
                query: {},
                searchMap: (item) => ({
                    value: item.TemplateID,
                    label: item.Name
                })
            }
        });

        return (
            <LayoutDashboard
                history={history}
                translate={translate}
                location={location}
            >

                {/** Page content
                 ============================================================*/}
                <LayoutDashboardMain>
                    <LayoutDashboardHeader
                        title={"Date: " + toFrontDate(this.props.match.params.id)}
                        isLoading={!!isLoading}
                    >
                        <div className="ml-auto">
                            <div className="inline-block">
                                <Switch.Group as="div" className={"flex items-center"}>
                                    <Switch
                                        checked={this.state.showAll}
                                        onChange={() => {
                                            this.setState({
                                                showAll: !this.state.showAll
                                            })
                                        }}
                                        className={classNames(
                                            !!this.state.showAll ? 'bg-primary-600' : 'bg-gray-200',
                                            'relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500'
                                        )}
                                    >
                                        <span className="sr-only">{translate("field.show_whole_day")}</span>
                                        <span
                                            aria-hidden="true"
                                            className={classNames(
                                                !!this.state.showAll ? 'translate-x-5' : 'translate-x-0',
                                                'pointer-events-none inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200'
                                            )}
                                        />
                                    </Switch>
                                    <Switch.Label as="span" className="ml-3">
                                        <span className="text-sm font-medium text-gray-900 mr-2">{translate("field.show_whole_day")}</span>
                                    </Switch.Label>
                                </Switch.Group>
                            </div>

                            {checkPerm(Resources.CalendarResource, CREATE_PERM) && (
                                <div className={"inline-block ml-2"}>
                                    <button
                                        type="button"
                                        onClick={this.handleToggleCreateModal}
                                        className="btn btn-primary"
                                    >
                                        <PlusIcon className="-ml-1 mr-1 h-5 w-5" aria-hidden="true"/>
                                        {translate("btn.add")}
                                    </button>
                                </div>
                            )}
                        </div>
                    </LayoutDashboardHeader>

                    <div className="min-h-[20rem]">
                        <div className="grid gap-4 grid-cols-12">
                            <div className="sm:col-span-5 col-span-12">
                                <table className={"min-w-full divide-y divide-secondary-200 "}>
                                    <thead>
                                    </thead>
                                    {(!!isLoading) && (
                                        <div className={"text-center"}>
                                            <Loader/>
                                        </div>
                                    )}
                                    {(!isLoading) && (
                                        <tbody className="bg-inverse divide-y divide-secondary-200">
                                        {rows}
                                        </tbody>
                                    )}
                                </table>
                            </div>

                            <div className="sm:col-span-7 col-span-12 min-h-[25rem]">
                                <div className="w-full">
                                    {searchFieldsHtml}
                                </div>

                                <div className="p-2">
                                    {templateData && !isLoadingSecond && this.state.searchFields.TemplateID.value.value && (
                                        <SimpleTable
                                            data={templateData}

                                            fields={this.getFields(null, null, tableFields)}
                                            translate={this.props.translate}
                                            isLoading={isLoadingSecond}

                                            limit={this.state.limit}
                                            offset={this.state.offset}



                                            onCustomAction={(it) => {
                                                return (<button
                                                    onClick={() => this.handleToggleCreateModal(it)}
                                                    type="button"
                                                    className="mr-2 inline-flex items-center px-4 py-2 items-center px-4 py-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-primary-600 hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500"
                                                >
                                                    <PaperClipIcon className="md:-ml-1 md:mr-2 h-5 w-5 text-white" aria-hidden="true"/>
                                                    <span className="hidden md:inline">Copy Block to Calendar</span>
                                                </button>);
                                            }}
                                        />
                                    )}
                                    {isLoadingSecond && (
                                        <div className="text-center">
                                            <Loader/>
                                        </div>
                                    )}
                                    {!isLoadingSecond && !this.state.searchFields.TemplateID.value.value && (
                                        <p>Please select Template to filter for Blocks </p>
                                    )}
                                </div>

                            </div>
                        </div>
                    </div>
                </LayoutDashboardMain>

                {/** Modals
                 ============================================================*/}
                <ModalSaveResource
                    title={"Start Block"}
                    addClass="max-w-lg"
                    visible={this.state.startModalOpen}
                    onClose={this.handleToggleStartModal}
                    fields={this.getFieldsDeeper(this.state.selectedItem)}
                    onSubmit={(params) => {
                        if (params) {
                            params[this.getPrimaryKey()] = this.state.selectedItem[this.getPrimaryKey()];

                            updateData({
                                query: this.getQuery(),
                                params: params,
                                resource: Resources.CalendarStartBlock,
                                piggyResource: this.getResourceName()
                            })
                            this.handleToggleStartModal(null)
                        }
                    }}

                    translate={this.props.translate}
                    metadata={metadata}
                    handleInputChange={(fields, name, value) => {
                        if (name === "LibraryTypeID") {
                            fields.LibraryID.value = null;
                            fields.LibraryID.props.key = value;
                        }
                        return FieldsManager.updateField(fields, name, value);
                    }}
                />

                <ModalSaveResource
                    title={"Start Exercise"}
                    addClass="max-w-lg"
                    visible={this.state.startExerciseModalOpen}
                    onClose={this.handleToggleStartExerciseModal}
                    fields={this.getFieldsDeeper(this.state.selectedItem, ['LibraryLength'])}
                    onSubmit={(params) => {
                        if (params) {
                            params[this.getPrimaryKey()] = this.state.selectedItem[this.getPrimaryKey()];

                            updateData({
                                query: this.getQuery(),
                                params: params,
                                resource: Resources.CalendarStartExercise,
                                piggyResource: this.getResourceName(),
                                notificationMessage: "Exercise Started"
                            })
                            this.handleToggleStartExerciseModal(null)
                        }
                    }}
                    translate={this.props.translate}
                    metadata={metadata}
                    handleInputChange={(fields, name, value) => {
                        if (name === "LibraryTypeID") {
                            fields.LibraryID.value = null;
                            fields.LibraryID.props.key = value;
                        }
                        return FieldsManager.updateField(fields, name, value);
                    }}
                />

                <ModalSaveResource
                    title={"Confirm completion"}
                    headerText={"This will complete current block and exercise. Are you sure?"}
                    fields={this.getFieldsDeeper(this.state.selectedItem, ['LibraryTypeID', 'Date', 'BlockLength', 'LibraryLength', 'LibraryID', 'Description'])}
                    addClass="max-w-lg"
                    visible={this.state.completeExerciseModalOpen}
                    onClose={this.handleToggleCompleteExerciseModal}
                    onSubmit={(params) => {
                        if (params) {
                            params[this.getPrimaryKey()] = this.state.selectedItem[this.getPrimaryKey()];

                            updateData({
                                query: this.getQuery(),
                                params: params,
                                resource: Resources.CalendarCompleteExercise,
                                piggyResource: this.getResourceName(),
                                notificationMessage: "Block Completed"
                            })
                            this.handleToggleCompleteExerciseModal(null)
                        }
                    }}
                    translate={this.props.translate}
                    metadata={metadata}
                />

                <ModalConfirm
                    title={"Confirm skip"}
                    text={"Are you sure you want to skip?"}
                    onClose={this.handleToggleSkipModal}
                    onConfirm={() => {
                        deleteData({
                            query: Object.assign({
                                [this.getPrimaryKey()]: this.state.selectedItem[this.getPrimaryKey()]
                            }, this.getQuery()),
                            resource: Resources.CalendarSkipBlock,
                            piggyResource: this.getResourceName(),
                            notificationMessage: "Block Skipped"
                        })
                        this.handleToggleSkipModal(false)
                    }}
                    visible={this.state.skipModalOpen}
                />

                <ModalConfirm
                    title={"Confirm delete"}
                    text={"Are you sure you want to delete?"}
                    onClose={this.handleToggleConfirmModal}
                    onConfirm={() => {
                        deleteData({
                            query: Object.assign({
                                [this.getPrimaryKey()]: this.state.selectedItem[this.getPrimaryKey()]
                            }, this.getQuery()),
                            resource: this.getResourceName(),
                            piggyResource: this.getResourceName()
                        })
                        this.handleToggleConfirmModal(false)
                    }}
                    visible={this.state.confirmModalOpen}
                />

                <ModalSaveResource
                    title={"Add Item"}
                    addClass="max-w-lg"
                    visible={this.state.createModalOpen}
                    onClose={this.handleToggleCreateModal}
                    fields={this.getFields(this.state.selectedItem)}
                    onSubmit={(params) => {
                        if (params) {
                            params.Date = moment(this.props.match.params.id + " "  + params.Date, "YYYY-MM-DD hh:mm A").format("YYYY-MM-DD HH:mm:ss");
                            createData({
                                query: this.getQuery(),
                                params: params,
                                resource: this.getResourceName(),
                                piggyResource: this.getResourceName()
                            })
                            this.handleToggleCreateModal(false)
                        }
                    }}
                    translate={this.props.translate}
                    metadata={metadata}
                    handleInputChange={(fields, name, value) => {
                        if (name === "LibraryTypeID") {
                            fields.LibraryID.value = null;
                            fields.LibraryID.props.key = value;
                        }
                        return FieldsManager.updateField(fields, name, value);
                    }}
                />

                <ModalSaveResource
                    title={"Edit Item"}
                    addClass="max-w-lg"
                    visible={this.state.editModalOpen}
                    onClose={this.handleToggleEditModal}
                    fields={this.getFields(this.state.selectedItem)}
                    onSubmit={(params) => {
                        if (params) {
                            delete (params.LibraryTypeID);
                            params[this.getPrimaryKey()] = this.state.selectedItem[this.getPrimaryKey()];

                            params.Date = moment(this.props.match.params.id + " " + params.Date, "YYYY-MM-DD hh:mm A").format("YYYY-MM-DD HH:mm:ss");

                            updateData({
                                query: this.getQuery(),
                                params: params,
                                resource: this.getResourceName(),
                                piggyResource: this.getResourceName()
                            })
                            this.handleToggleEditModal(null)
                        }
                    }}
                    translate={this.props.translate}
                    metadata={metadata}
                    handleInputChange={(fields, name, value) => {
                        if (name === "LibraryTypeID") {
                            fields.LibraryID.value = null;
                            fields.LibraryID.props.key = value;
                        }
                        return FieldsManager.updateField(fields, name, value);
                    }}
                />
            </LayoutDashboard>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        resource: state.resource,
        secondResource: state.secondResource,
        sidebarResource: state.sidebarResource,
    };
};

const mapDispatchToProps = (dispatch) => {
    const user = LocalStorage.get("user")

    return {
        getData: getDataSelect({dispatch, user}),
        createData: createDataSelect({dispatch, user}),
        updateData: updateDataSelect({dispatch, user}),
        deleteData: deleteDataSelect({dispatch, user}),

        dispatch: dispatch
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(CalendarItemsPage);
