import React from "react";

const LayoutDashboardMain = (props) => {
    const {children} = props;

    return (
        <main className="-mt-24 pb-8">
            <div className="mx-auto px-4 sm:px-6 lg:max-w-7xl lg:px-8">
                <div className="rounded-lg shadow bg-inverse border-b-2 border-primary overflow-hidden">
                    {children}
                </div>
            </div>
        </main>
    )
}

export default LayoutDashboardMain;