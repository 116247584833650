import React, {Component} from "react";
import moment from "moment";

export default class PublicLayout extends Component {

    constructor(props) {
        super(props);
        this.state = {}
    }

    render() {
        return (
            <React.Fragment>
                <div className="bg-gradient-to-r from-primary to-secondary min-h-full text-default">
                    <div>
                        {this.props.children}
                    </div>
                    <div className="text-white py-3 text-center text-sm px-4">
                        <p className="mb-3">©{moment().year()} Productivity | Developed in partnership with, LUKASA, a modernization business technology consulting firm. </p>

                        <p>
                            All rights reserved.
                        </p>
                    </div>
                </div>
            </React.Fragment>
        )
    }
}