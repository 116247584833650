import SocketAPI from "./socket";
import {pushNotification} from "../../data/actions/ui";
import {addNotification, setNotifications} from "./actions/notifications";

const socket = new SocketAPI();

const OnNotificationEvent = "OnNotification";
const OnNotificationsEvent = "OnNotifications";

const notificationMiddleware = store => next => action => {
    switch (action.type) {
        case notificationMiddleware.LoginEvent:
            connect(socket, store)
            break;
        case notificationMiddleware.LogoutEvent:
            disconnect(socket, store)
            break;
        default: break;
    }

    return next(action);
};

notificationMiddleware.getUrl = () => {
    return null;
}

notificationMiddleware.getToken = () => {
    return null;
}

notificationMiddleware.run = (store, getUrl, getToken, loginEvent = 'DONE_LOGIN', logoutEvent = 'LOGOUT') => {
    notificationMiddleware.getUrl = getUrl;
    notificationMiddleware.getToken = getToken;

    notificationMiddleware.LoginEvent = loginEvent;
    notificationMiddleware.LogoutEvent = logoutEvent;

    if (!!getToken()) {
        connect(socket, store)
    }
}

const connect = (socket, store) => {
    socket.connect(notificationMiddleware.getUrl(), notificationMiddleware.getToken())
        .then(() => {
            console.log("notificationMiddleware:connect")

        })
        .catch((e) => {
            console.log("notificationMiddleware:error", e)
        });

    socket.on(OnNotificationEvent, (data) => {
        console.log(OnNotificationEvent, data);

        store.dispatch(pushNotification({
            data: {
                notificationTitle: data.Title,
                notificationMessage: data.Message,
                notificationMetadata: data.Metadata
            }
        }));

        let unreadCount = store.getState()?.notifications?.data?.unreadCount;
        let notifications = store.getState()?.notifications?.data?.notifications ?? {
            list: [],
            count: 0
        };
        unreadCount += 1;
        notifications.list.push(data);
        store.dispatch(addNotification({
            unreadCount: unreadCount,
            notifications: notifications
        }));
    });
    socket.on(OnNotificationsEvent, (data) => {
        console.log(OnNotificationsEvent, data);
        store.dispatch(setNotifications({
            unreadCount: 0,
            notifications: data
        }));
    });
}

const disconnect = (socket, store) => {
    socket.disconnect()
        .then(() => {
            console.log("notificationMiddleware:disconnect");
        })
        .catch((e) => {
            console.log("notificationMiddleware:error", e)
        });
}

export default notificationMiddleware