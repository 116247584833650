export function getThirdResource(data) {
    return {
        type: 'GET_THIRD_RESOURCE',
        data: data
    };
}

export function doneGetThirdResource(data) {
    return {
        type: 'DONE_GET_THIRD_RESOURCE',
        data: data
    };
}

export function errorGetThirdResource(data) {
    return {
        type: 'ERROR_GET_THIRD_RESOURCE',
        data: data
    };
}

export function clearThirdResource() {
    return {
        type: 'CLEAR_THIRD_RESOURCE',
    };
}
