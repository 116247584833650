import React from 'react'
import Loader from "../loader";

const LayoutDashboardHeader = (props) => (
    <div className="bg-inverse border-b">
        <div className="px-4 sm:px-6 lg:mx-auto">
            <div
                className="py-4 sm:flex items-center justify-between">
                <div className=" min-w-0">
                    <div className="flex items-center">
                        <div>
                            <div className="flex items-center">
                                <h1 className="flex-1 text-lg font-medium">
                                    {!!props.isLoading && (
                                        <Loader />
                                    )}
                                    {props.title}
                                </h1>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="flex mt-3 sm:mt-0 space-x-3 mt-0 md:ml-4">
                    <div className="md:ml-3 flex-shrink-0">
                        {props.children}
                    </div>
                </div>
            </div>
        </div>
    </div>
);

export default LayoutDashboardHeader;