import React, {useEffect} from "react";

const FieldTimeDropdown = (props) => {
    const {randomID, dropdownRef, renderOptions, formatTime, hideDropdown} = props;

    const outsideClick = (event) => {
        if (event.target.dataset.id !== randomID && !event.target.parentElement.dataset.id !== randomID) {
            formatTime();
            hideDropdown();
        }
    }

    const escFunction = (event) => {
        if (event.keyCode === 27) {
            formatTime();
            hideDropdown();
        }
    }

    useEffect(() => {
        document.addEventListener("keydown", escFunction, false);

        return () => document.removeEventListener("click", escFunction, false);
    }, []);

    useEffect(() => {
        document.addEventListener("click", outsideClick, false);

        return () => document.removeEventListener("click", outsideClick, false);
    }, []);

    return (
        <div data-id={randomID} ref={dropdownRef} className="absolute top-full mt-2 border border-secondary-200 rounded-md overflow-y-auto max-h-[15rem] w-36 bg-white z-10 custom-dropdown-menu" tabIndex="-1">
            {renderOptions()}
        </div>
    )
}

export default FieldTimeDropdown;