import React from "react";

const Button = (props) => {
    let {className, type, disabled, onClick, innerRef} = props;

    let typeClass;
    switch (type) {
        case "primary":
            typeClass = "btn-primary"
            break;
        case "outline":
            typeClass = "btn-outline"
            break;
        case "secondary":
            typeClass = "btn-outline"
            break;
        case "secondary-outline":
            typeClass = "btn-outline"
            break;
        case "link":
            typeClass = "btn-link"
            break;
        default:
            typeClass = ""
    }

    className = className ? " " + className : "";
    let disabledClass = disabled ? " btn-disabled" : " " + typeClass;

    return (
        <React.Fragment>
            <div
                ref={innerRef}
                className={"select-none btn" + className + disabledClass}
                onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    e.nativeEvent.stopImmediatePropagation();
                    !disabled && onClick();
                }}
            >
                {props.children}
            </div>
        </React.Fragment>
    )

}

export default Button;