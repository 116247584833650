import {logout} from "../data/actions/user";
import ReactGA from 'react-ga';
import LocalStorage from "../util/localStorage";
import {getProp} from "../util/util";

const eventsMiddleware = store => next => action => {

    if (action.data === "invalid_token" || (!!action.data && action.data.data === "invalid_token")) {
        store.dispatch(logout());
        return store.getState();
    }

    const pathname = window.location.pathname;

    switch (action.type) {
        case 'DONE_LOGIN':
            /*
            ReactGA.set({
                userId: !!LocalStorage.get('user') && LocalStorage.get('user').Contact.ContactID
            });
            ReactGA.event({
                category: "SIGN_UP",
                action: pathname + ",DONE_LOGIN",
            });
             */
            break;
        default:
            /*
            ReactGA.set({
                userId: !!LocalStorage.get('user') && LocalStorage.get('user').Contact.ContactID
            });
            ReactGA.event({
                category: "CRUD",
                action: pathname + "," + action.type + "," + getProp(action, "data.resource", ""),
            });
             */
            break;
    }

    return next(action);
};

export default eventsMiddleware