import React, {Component, Fragment} from 'react'
import {Dialog, Menu, Popover, Transition} from '@headlessui/react'
import {XIcon} from '@heroicons/react/outline'
import {Link} from "react-router-dom";
import {
    checkPasswordStrength,
    checkPerm,
    classNames,
    getProp,
    getUserLabel, READ_PERM,
    setDocumentTitle
} from "../../util/util";
import Logout from "../logout";
import LocalStorage from "../../util/localStorage";
import {logoutClear, resetUserMessage, updatePassword} from "../../data/actions/user";
import Notification from "../notification";
import {hideNotification} from "../../data/actions/ui";
import FieldPassword from "../field-password";
import {Field, FieldsManager} from "../../data/services/fields";
import Loader from "../loader";
import SearchIcon from "@heroicons/react/outline/SearchIcon";
import MenuIcon from "@heroicons/react/outline/MenuIcon";
import LayoutDashboardFooter from "./layout-dashboard-footer";
import Resources from "../../data/services/resources";
import NotificationsDropdown from "../notifications-dropdown";
import {getNotificationList} from "../../package/notification/actions/notifications";
import {deleteResource, updateResource} from "../../data/actions/resource";
import {connect} from "react-redux";

class LayoutDashboard extends Component {

    constructor(props) {
        super(props);
        this.state = {
            logout: false,
            isSidebarOpen: false,
            ThemeDark: false,
            sidebarIsCollapsed: false,
            sidebarAnimation: false,
            page: "",
            changePasswordModalOpen: false,

            fields: {
                password: new Field('password', '', ['empty']),
                password_confirm: new Field('password_confirm', '', ['empty']),
            },
            no_match: false,
            passwordStrength: {
                passed: 0,
                strength: "",
                color: "white"
            },

            selectedMenuDropdownItem: null,
        }
    }

    componentDidMount() {
        this.props.dispatch(getNotificationList({
            user: LocalStorage.get("user")
        }));
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        if (nextProps.user.logout) {
            return {logout: true};
        } else {
            return null;
        }
    }

    componentDidUpdate(prevProps) {
        if (this.state.logout) {
            LocalStorage.clearAllExcept([
                'username'
            ]);
            LocalStorage.remove('user');
            this.props.dispatch(logoutClear());
            this.props.history.push("/login");
        }

        if (this.state.page !== this.props.location.pathname) {
            this.setState({
                page: this.props.location.pathname
            }, () => setDocumentTitle(this.props.location.pathname, this.props.translate));
        }
    }

    setSidebarOpen = (open) => {
        this.setState({
            isSidebarOpen: open
        })
    }

    changePasswordSubmit = (event) => {
        event && event.preventDefault();

        this.setState({fields: FieldsManager.validateFields(this.state.fields)}, () => {
            if (this.state.passwordStrength.strength < 4) {
                return;
            }
            if (this.state.fields.password.value !== this.state.fields.password_confirm.value) {
                this.setState({
                    no_match: true
                });
            } else if (!this.state.fields.password.errorMessage && !this.state.fields.password_confirm.errorMessage) {
                this.props.dispatch(updatePassword({
                    user: LocalStorage.get('user'),
                    params: FieldsManager.getFieldKeyValues(this.state.fields)
                }))
            }
        })
    };

    handleInputChange = (name, value) => {
        this.setState({fields: FieldsManager.updateField(this.state.fields, name, value)});

        if (name === 'password') {
            this.setState({
                passwordStrength: checkPasswordStrength(value)
            })
        }
    };

    handleChangePassword = () => {
        this.setState({
            changePasswordModalOpen: !this.state.changePasswordModalOpen
        })
    }

    handleOpenMenuDropdown = (name = null) => {
        this.setState({
            selectedMenuDropdownItem: name
        })
    }

    handleActionClick = (Name, Data, id) => {
        switch (Name) {
            case 'Start':
                this.props.dispatch(updateResource({
                    user: LocalStorage.get("user"),
                    params: {
                        CalendarID: Data.ID
                    },
                    resource: Resources.CalendarStartBlock,
                    notificationMessage: "Block Started"
                }));
                break;
            case 'Snooze':
                this.props.dispatch(updateResource({
                    user: LocalStorage.get("user"),
                    params: {
                        CalendarID: Data.ID
                    },
                    resource: Resources.CalendarSnoozeBlock,
                    notificationMessage: "Block Snoozed"
                }));
                break;
            case 'Skip':
                this.props.dispatch(deleteResource({
                    user: LocalStorage.get("user"),
                    query: {
                        CalendarID: Data.ID
                    },
                    resource: Resources.CalendarSkipBlock,
                    notificationMessage: "Block Skipped"
                }));
                break;
            default:
                break;
        }

        if (!!id) {
            this.props.dispatch(hideNotification(id));
        }
    }

    render() {
        const {translate, children} = this.props;
        const user = LocalStorage.get("user")

        let userNavigation = [
            {name: 'Preferences', href: '/preferences'},
            // {name: 'Users', href: '/users'},
            {name: 'Logged devices', href: '/devices'},
            {
                name: 'Change Password', action: () => {
                    this.setState({
                        changePasswordModalOpen: true
                    })
                }
            }
        ];

        let mainNavigation = [
            {
                name: 'Users', href: '/users', perm: checkPerm(Resources.Users, READ_PERM)
            },
            {
                name: 'Calendar', href: '/calendar', perm: checkPerm(Resources.CalendarResource, READ_PERM)
            },
            {
                name: 'Templates', href: '/templates', perm: checkPerm(Resources.TemplateResource, READ_PERM)
            },
            {
                name: 'Library', href: '/library', perm: true
            },
        ];

        const notifications = getProp(this.props, "ui.messages", [
            {message: null}, {message: null}, {message: null}
        ]).map((it) => {
            let msg = it.message;
            // eslint-disable-next-line default-case
            switch (it.message) {
                case 'CREATE_RESOURCE':
                case 'CREATE_DIALOG_RESOURCE':
                    msg = "Entry created";
                    break;
                case 'UPDATE_RESOURCE':
                case 'UPDATE_DIALOG_RESOURCE':
                case 'UPDATE_SECOND_RESOURCE':
                    msg = "Entry updated";
                    break;
                case 'DELETE_RESOURCE':
                case 'DELETE_DIALOG_RESOURCE':
                    msg = "Entry deleted";
                    break;
            }

            return (
                <Notification
                    key={it.id}
                    id={it.id}
                    show={!!it.message}
                    onClose={() => {
                        this.props.dispatch(hideNotification(it.id))
                    }}
                    actions={getProp(it, "actions" , [])}
                    onActionClick={this.handleActionClick}
                    title={it.notificationTitle ?? "Notification"}
                    message={msg}
                />
            )
        });

        const userMenuBtnLabel = getUserLabel();

        return (
            <>
                <div className="min-h-full">
                    <Popover as="header" className="pb-24 bg-gradient-to-r from-primary to-secondary">
                        {({open}) => (
                            <>
                                <div className="max-w-3xl mx-auto px-4 sm:px-6 lg:max-w-7xl lg:px-8">
                                    <div
                                        className="relative flex flex-wrap items-center justify-center lg:justify-between">
                                        {/* Logo */}
                                        <div className="hidden sm:flex absolute left-0 py-5 flex-shrink-0 lg:static">
                                            <a href="#">
                                                <span className="sr-only">Productivity</span>

                                                {/*<img src="" className="text-primary-200 text-2xl font-normal" alt="Covid Reserve"/>*/}
                                                <span
                                                    className="text-primary-200 text-2xl font-normal">Productivity</span>
                                            </a>
                                        </div>

                                        {/* Right section on desktop */}
                                        <div className="hidden lg:ml-4 lg:flex lg:items-center lg:py-5 lg:pr-0.5">
                                            <NotificationsDropdown
                                                onClick={() => {

                                                }}
                                                onActionClick={this.handleActionClick}
                                                notifications={this.props?.notifications?.data?.notifications}
                                                unreadCount={this.props?.notifications?.data?.unreadCount ?? 0}
                                            />
                                            {/* Profile dropdown */}
                                            <Menu as="div" className="ml-3 relative dark relative z-10">
                                                {({open}) => (
                                                    <>
                                                        <div>
                                                            <Menu.Button
                                                                className="max-w-xs bg-transparent flex items-center text-sm rounded-full focus:outline-none focus:ring-2 focus:ring-offset-0 focus:ring-primary-500 ring-offset-secondary-100 text-primary-50">
                                                                <span className="sr-only">Open user menu</span>
                                                                <div
                                                                    className="w-10 h-10 bg-gold rounded-full flex justify-center items-center mr-2 select-none">
                                                                    <div
                                                                        className="text-primary-600 font-black">{userMenuBtnLabel.split(/\s/).reduce((response, word) => response += word.slice(0, 1), '')}</div>
                                                                </div>

                                                                <span className="select-none">
                                                                    {userMenuBtnLabel}
                                                                </span>

                                                                <svg xmlns="http://www.w3.org/2000/svg" width="10"
                                                                     height="6"
                                                                     viewBox="0 0 10 6" className="ml-2">
                                                                    <path fill="currentColor"
                                                                          d="M8.292893.292893c.390525-.390524 1.023689-.390524 1.414214 0 .390524.390525.390524 1.023689 0 1.414214l-4 4c-.390525.390524-1.023689.390524-1.414214 0l-4-4c-.390524-.390525-.390524-1.023689 0-1.414214.390525-.390524 1.023689-.390524 1.414214 0L5 3.585786 8.292893.292893z"/>
                                                                </svg>
                                                            </Menu.Button>
                                                        </div>
                                                        <Transition
                                                            show={open}
                                                            as={Fragment}
                                                            enter="transition ease-out duration-100"
                                                            enterFrom="transform opacity-0 scale-95"
                                                            enterTo="transform opacity-100 scale-100"
                                                            leave="transition ease-in duration-75"
                                                            leaveFrom="transform opacity-100 scale-100"
                                                            leaveTo="transform opacity-0 scale-95"
                                                        >
                                                            <Menu.Items
                                                                static
                                                                className="theme-dark-popup origin-top-right absolute right-0 mt-2 w-48 rounded-md shadow-lg py-1 bg-inverse ring-1 ring-black ring-opacity-5 focus:outline-none"
                                                            >
                                                                {userNavigation
                                                                    .map((item) => (
                                                                        <Menu.Item key={item.name}>
                                                                            {({active}) => {
                                                                                if (item.action) {
                                                                                    return (
                                                                                        <div
                                                                                            onClick={item.action}
                                                                                            className={classNames(
                                                                                                active ? 'bg-secondary-100' : '',
                                                                                                'cursor-pointer block px-4 py-2 text-sm text-secondary-700'
                                                                                            )}
                                                                                        >
                                                                                            {item.name}
                                                                                        </div>
                                                                                    )
                                                                                }
                                                                                return (
                                                                                    <Link
                                                                                        to={item.href}
                                                                                        className={classNames(
                                                                                            active ? 'bg-secondary-100' : '',
                                                                                            'block px-4 py-2 text-sm text-secondary-700'
                                                                                        )}
                                                                                    >
                                                                                        {item.name}
                                                                                    </Link>
                                                                                );
                                                                            }}
                                                                        </Menu.Item>
                                                                    ))}
                                                                <Menu.Item key={"logout"}>
                                                                    {({active}) => (
                                                                        <Logout
                                                                            className={classNames(
                                                                                active ? 'bg-secondary-100' : '',
                                                                                'block w-full text-left px-4 py-2 text-sm text-secondary-700 hover:bg-secondary-100'
                                                                            )}
                                                                            history={this.props.history}
                                                                            translate={this.props.translate}
                                                                            dispatch={this.props.dispatch}/>
                                                                    )}
                                                                </Menu.Item>
                                                            </Menu.Items>
                                                        </Transition>
                                                    </>
                                                )}
                                            </Menu>
                                        </div>

                                        <div className="w-full py-5 lg:border-t lg:border-white lg:border-opacity-20">
                                            <div className="lg:grid lg:grid-cols-3 lg:gap-8 lg:items-center">
                                                {/* Left nav */}
                                                <div className="hidden lg:block lg:col-span-2">
                                                    <nav className="flex space-x-4">
                                                        {mainNavigation.filter(it => !!it.perm).map((item) => (
                                                            <React.Fragment>
                                                                <Link
                                                                    key={item.name}
                                                                    to={item.href}
                                                                    className={classNames(
                                                                        item.current ? 'text-white' : 'text-primary-50',
                                                                        'text-sm font-medium rounded-md bg-white bg-opacity-0 px-3 py-2 hover:bg-opacity-10'
                                                                    )}
                                                                    aria-current={item.current ? 'page' : undefined}
                                                                >
                                                                    {item.name}
                                                                </Link>
                                                            </React.Fragment>
                                                        ))}
                                                    </nav>
                                                </div>
                                                <div className="px-12 lg:px-0">
                                                    {/* Search */}
                                                    {!!this.props.onHandleQueryChange && (
                                                        <div className="max-w-xs mx-auto w-full lg:max-w-md">
                                                            <label htmlFor="search" className="sr-only">
                                                                Search
                                                            </label>
                                                            <div
                                                                className="relative text-white focus-within:text-gray-600">
                                                                <div
                                                                    className="pointer-events-none absolute inset-y-0 left-0 pl-3 flex items-center">
                                                                    <SearchIcon className="h-5 w-5" aria-hidden="true"/>
                                                                </div>
                                                                <input
                                                                    onChange={(event) => {
                                                                        this.props.onHandleQueryChange("query", event.target.value);
                                                                    }}
                                                                    id="search"
                                                                    className="block w-full text-white bg-white bg-opacity-20 py-2 pl-10 pr-3 border border-transparent rounded-md leading-5 focus:text-gray-900 placeholder-white focus:outline-none focus:bg-opacity-100 focus:border-transparent focus:placeholder-gray-500 focus:ring-0 sm:text-sm"
                                                                    placeholder="Search"
                                                                    type="search"
                                                                    name="search"
                                                                />
                                                            </div>
                                                        </div>
                                                    )}
                                                </div>
                                            </div>
                                        </div>

                                        {/* Menu button */}
                                        <div className="absolute right-0 flex-shrink-0 lg:hidden">
                                            {/* Mobile menu button */}
                                            <Popover.Button
                                                className="bg-transparent p-2 rounded-md inline-flex items-center justify-center text-cyan-200 hover:text-white hover:bg-white hover:bg-opacity-10 focus:outline-none focus:ring-2 focus:ring-white">
                                                <span className="sr-only">Open main menu</span>
                                                {open ? (
                                                    <XIcon className="block h-6 w-6" aria-hidden="true"/>
                                                ) : (
                                                    <MenuIcon className="block h-6 w-6" aria-hidden="true"/>
                                                )}
                                            </Popover.Button>
                                        </div>
                                    </div>
                                </div>

                                <Transition.Root as={Fragment}>
                                    <div className="lg:hidden">
                                        <Transition.Child
                                            as={Fragment}
                                            enter="duration-150 ease-out"
                                            enterFrom="opacity-0"
                                            enterTo="opacity-100"
                                            leave="duration-150 ease-in"
                                            leaveFrom="opacity-100"
                                            leaveTo="opacity-0"
                                        >
                                            <Popover.Overlay className="z-20 fixed inset-0 bg-black bg-opacity-25"/>
                                        </Transition.Child>

                                        <Transition.Child
                                            as={Fragment}
                                            enter="duration-150 ease-out"
                                            enterFrom="opacity-0 scale-95"
                                            enterTo="opacity-100 scale-100"
                                            leave="duration-150 ease-in"
                                            leaveFrom="opacity-100 scale-100"
                                            leaveTo="opacity-0 scale-95"
                                        >
                                            <Popover.Panel
                                                focus
                                                className="z-30 absolute top-0 inset-x-0 max-w-3xl mx-auto w-full p-2 transition transform origin-top"
                                            >
                                                <div
                                                    className="rounded-lg shadow-lg ring-1 ring-black ring-opacity-5 bg-white divide-y divide-gray-200">
                                                    <div className="pt-3 pb-2">
                                                        <div className="flex items-center justify-between px-4">
                                                            <div>
                                                                <div className="flex-shrink-0">
                                                                    <img className="h-10 w-10 rounded-full"
                                                                         src={user.imageUrl} alt=""/>
                                                                </div>
                                                            </div>
                                                            <div className="-mr-2">
                                                                <Popover.Button
                                                                    className="bg-white rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-cyan-500">
                                                                    <span className="sr-only">Close menu</span>
                                                                    <XIcon className="h-6 w-6" aria-hidden="true"/>
                                                                </Popover.Button>
                                                            </div>
                                                        </div>
                                                        <div className="mt-3 px-2 space-y-1">
                                                            {mainNavigation.filter(it => !!it.perm).map((item) => (
                                                                <Link
                                                                    key={item.name}
                                                                    to={item.href}
                                                                    className="block rounded-md px-3 py-2 text-base text-gray-900 font-medium hover:bg-gray-100 hover:text-gray-800"
                                                                >
                                                                    {item.name}
                                                                </Link>
                                                            ))}
                                                            {userNavigation
                                                                .map((item) => (
                                                                    <div key={item.name}>
                                                                        {item.action ? (
                                                                            <div
                                                                                onClick={item.action}
                                                                                className={classNames(
                                                                                    "block rounded-md px-3 py-2 text-base text-gray-900 font-medium hover:bg-gray-100 hover:text-gray-800"
                                                                                )}
                                                                            >
                                                                                {item.name}
                                                                            </div>
                                                                        ) : (
                                                                            <Link
                                                                                to={item.href}
                                                                                className={classNames(
                                                                                    "block rounded-md px-3 py-2 text-base text-gray-900 font-medium hover:bg-gray-100 hover:text-gray-800"
                                                                                )}
                                                                            >
                                                                                {item.name}
                                                                            </Link>
                                                                        )}
                                                                    </div>
                                                                ))}
                                                            <Logout
                                                                className={"block rounded-md px-3 py-2 text-base text-gray-900 font-medium hover:bg-gray-100 hover:text-gray-800"}
                                                                history={this.props.history}
                                                                translate={this.props.translate}
                                                                dispatch={this.props.dispatch}/>
                                                        </div>
                                                    </div>
                                                </div>
                                            </Popover.Panel>
                                        </Transition.Child>
                                    </div>
                                </Transition.Root>
                            </>
                        )}
                    </Popover>

                    {children}

                    <LayoutDashboardFooter/>
                </div>

                {/* Update Password Dialog */}
                <Transition show={this.state.changePasswordModalOpen} as={Fragment}>
                    <Dialog
                        as="div"
                        id="modal"
                        className="fixed inset-0 z-20 overflow-y-auto"
                        static
                        open={this.state.changePasswordModalOpen}
                        onClose={this.handleChangePassword}
                    >
                        <div className="min-h-screen px-4 text-center">
                            <Transition.Child
                                as={Fragment}
                                enter="ease-out duration-300"
                                enterFrom="opacity-0"
                                enterTo="opacity-100"
                                leave="ease-in duration-200"
                                leaveFrom="opacity-100"
                                leaveTo="opacity-0"
                            >
                                <Dialog.Overlay className="fixed inset-0 bg-black bg-opacity-30 transition-opacity"/>
                            </Transition.Child>

                            {/* This element is to trick the browser into centering the modal contents. */}
                            <span
                                className="inline-block h-screen align-middle"
                                aria-hidden="true"
                            >
                                &#8203;
                                </span>
                            <Transition.Child
                                as={Fragment}
                                enter="ease-out duration-300"
                                enterFrom="opacity-0 scale-95"
                                enterTo="opacity-100 scale-100"
                                leave="ease-in duration-200"
                                leaveFrom="opacity-100 scale-100"
                                leaveTo="opacity-0 scale-95"
                            >
                                <div
                                    className="theme-dark-popup inline-block w-full max-w-md p-4 my-6 overflow-hidden text-left align-middle transition-all transform bg-inverse shadow-xl rounded-2xl">
                                    <div className="mt-4 sm:mt-3 space-y-4 sm:space-y-3">
                                        {this.props.user.isLoading && (
                                            <div className={"inset-center block w-full m-4"}>
                                                <Loader/>
                                            </div>
                                        )}
                                        {!this.props.user.isLoading && !this.props.user.reset && (
                                            <>
                                                <p>Enter new password:</p>

                                                <FieldPassword
                                                    onChange={this.handleInputChange} {...this.state.fields.password}
                                                    className="h-9 max-w-lg block w-full shadow-sm focus:ring-primary-500 focus:border-primary-500 sm:max-w-xs sm:text-sm border-secondary-300 rounded-md bg-inverse text-secondary-700"
                                                    placeholder={translate("field.placeholder.new_password")}/>

                                                <FieldPassword
                                                    onChange={this.handleInputChange} {...this.state.fields.password_confirm}
                                                    className="h-9 max-w-lg block w-full shadow-sm focus:ring-primary-500 focus:border-primary-500 sm:max-w-xs sm:text-sm border-secondary-300 rounded-md bg-inverse text-secondary-700"
                                                    placeholder={translate("field.placeholder.password_confirm")}/>

                                                <span className={"passwordStrength"}
                                                      style={{backgroundColor: this.state.passwordStrength.color}}></span>

                                                {this.state.no_match && (
                                                    <div
                                                        className={"text-red-500"}>{translate("reset_password.no_match")}</div>
                                                )}
                                            </>
                                        )}

                                        {!this.props.user.isLoading && this.props.user.reset && (
                                            <div>
                                                <p
                                                    className={"text-lg m-3"}
                                                >
                                                    {translate("reset_password.success_new_pass")}
                                                </p>
                                                <button
                                                    type="button"
                                                    className="m-auto mt-2 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500 sm:mt-0 sm:w-auto sm:text-sm"
                                                    onClick={() => {
                                                        this.props.dispatch(resetUserMessage());
                                                        this.handleChangePassword();
                                                    }}
                                                >
                                                    Close
                                                </button>
                                            </div>
                                        )}
                                    </div>

                                    {!this.props.user.isLoading && !this.props.user.reset && (
                                        <div className="pt-5">
                                            <div className="flex justify-end">
                                                <div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
                                                    <button
                                                        type="button"
                                                        className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-primary-600 text-base font-medium text-white hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 sm:ml-3 sm:w-auto sm:text-sm"
                                                        onClick={this.changePasswordSubmit}
                                                    >
                                                        Confirm
                                                    </button>
                                                    <button
                                                        type="button"
                                                        className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500 sm:mt-0 sm:w-auto sm:text-sm"
                                                        onClick={this.handleChangePassword}
                                                    >
                                                        Cancel
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    )}
                                </div>
                            </Transition.Child>
                        </div>
                    </Dialog>
                </Transition>

                {/* Global notification live region, render this permanently at the end of the document */}
                <div
                    aria-live="assertive"
                    className="fixed inset-0 flex items-end px-4 py-6 pointer-events-none sm:p-6 sm:items-start z-30 top-55"
                >
                    <div className="w-full flex flex-col items-center space-y-4 sm:items-end">
                        {notifications}
                    </div>
                </div>
            </>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        ui: state.ui,
        user: state.user,
        notifications: state.notifications
    }
}

export default connect(mapStateToProps)(LayoutDashboard);