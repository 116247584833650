import React, {Component} from "react";
import {connect} from "react-redux";
import LayoutDashboard from "../../components/layout-dashboard";
import LocalStorage from "../../util/localStorage";
import Resources from "../../data/services/resources";
import {
    DEFAULT_CRUD_STATE,
    getProp,
    getUserLabel
} from "../../util/util";
import Calendar from "../../components/calendar";
import moment from "moment";
import {FieldsManager} from "../../data/services/fields";
import Loader from "../../components/loader";
import LayoutDashboardMain from "../../components/layout-dashboard/layout-dashboard-main";
import LayoutDashboardHeader from "../../components/layout-dashboard/layout-dashboard-header";
import {getDataSelect} from "../../data/selectors/resourceSelectors";

class CalendarView extends Component {

    constructor(props) {
        super(props);
        this.state = {
            ...DEFAULT_CRUD_STATE,
            sort: "DESC",
            sortBy: "Date",

            currMonth: moment().month(),
            currYear: moment().year(),

            isWeekView: true,
            weekView: this.getWeekOfMonth(),

            StartDate: moment().startOf('week'),
            EndDate: moment().endOf('week'),

            selectedItem: null,
            viewModalOpen: false,
            createModalOpen: false,
            editModalOpen: false,

            createModalTaskOpen: false,
            editModalTaskOpen: false,
            ContactIDs: [],

            remountFields: 0,
        };
    }

    /** Lifecycle
     ================================================================= */
    componentDidMount() {
        this.fetchData();
    };

    /** Data Events
     ================================================================= */
    fetchData = () => {
        this.props.getData({query: this.getQuery(), resource: Resources.CalendarResource});
    };

    /** UI Events
     ================================================================= */
    handleUpdateSort = (sortBy) => {
        this.setState({
            sortBy: sortBy,
            sort: (this.state.sortBy === sortBy) ? (this.state.sort === "ASC" ? "DESC" : "ASC") : "ASC"
        }, () => this.fetchData())
    };

    handleUpdateOffset = (offset, page) => {
        this.setState({
            offset: offset,
            paginationPage: page
        }, () => this.fetchData());
    };

    handleInputChange = (fields, name, value) => {
        return FieldsManager.updateField(fields, name, value);
    };

    handleNextMonth = () => {
        if (this.state.isWeekView) {
            this.setState({
                StartDate: this.state.StartDate.add(7, 'days'),
                EndDate: this.state.EndDate.add(7, 'days'),
            }, this.fetchData)
        } else {
            // Zero based month counting, 11 is December
            this.setState({
                currMonth: (this.state.currMonth + 1 > 11) ? 0 : this.state.currMonth + 1,
                currYear: (this.state.currMonth + 1 > 11) ? (this.state.currYear + 1) : this.state.currYear
            }, this.fetchData)
        }
    };

    handlePrevMonth = () => {
        if (this.state.isWeekView) {
            this.setState({
                StartDate: this.state.StartDate.add(-7, 'days'),
                EndDate: this.state.EndDate.add(-7, 'days'),
            }, this.fetchData)
        } else {
            this.setState({
                currMonth: (this.state.currMonth - 1 < 0) ? 11 : this.state.currMonth - 1,
                currYear: (this.state.currMonth - 1 < 0) ? (this.state.currYear - 1) : this.state.currYear,
            }, this.fetchData);
        }
    };

    handleWeekChangeClick = () => {
        this.setState({
            isWeekView: !this.state.isWeekView
        });
    };

    handleCalendarDayTap = (date) => {
        this.props.history.push("/calendar-items/" + date);
    };

    /** Helpers
     ================================================================= */
    getWeekOfMonth = () => {
        let date = new Date();
        let adjustedDate = date.getDate() + date.getDay();
        let prefixes = ['0', '1', '2', '3', '4', '5'];
        return (parseInt(prefixes[0 | adjustedDate / 7]));
    };

    setCurrentWeek = () => {
        this.setState({
            StartDate: moment().startOf('week'),
            EndDate: moment().endOf('week'),
        }, this.fetchData);
    };

    getQuery = () => {
        return {
            offset: 0,
            limit: 500,
            searchFields: JSON.stringify({
                StartDate: [
                    "Date", ">=", this.state.StartDate.format('YYYY-MM-DD'),
                ],
                EndDate: [
                    "Date", "<=", this.state.EndDate.format('YYYY-MM-DD'),
                ]
            })
        };
    }

    /** Render
     ================================================================= */
    render() {
        const {translate, history, location, resource} = this.props;
        const userMenuBtnLabel = getUserLabel();

        const eventList = getProp(resource, "data.list", []);

        let events = {};

        eventList.forEach(it => {
            if (it.Date) {
                const date = it.Date.split(" ")[0];
                if (!events[date]) {
                    events[date] = []
                }

                events[date].push(it);
            }
        })

        return (
            <LayoutDashboard
                history={history}
                translate={translate}
                location={location}
            >

                {/** Page content
                 ============================================================*/}
                <LayoutDashboardMain>
                    {/* Page header */}
                    <LayoutDashboardHeader
                        title={translate("text.welcome") + ", " + userMenuBtnLabel}
                    />

                    <div className="">
                        {!!this.props.resource.isLoading && (
                            <div className="h-80 absolute text-center w-full">
                                <div className={"inset-center"}>
                                    <Loader/>
                                </div>
                            </div>
                        )}

                        {/*Calendar*/}
                        <div className="sm:mb-6 lg:mb-8">
                            <Calendar
                                className=""
                                month={this.state.currMonth}
                                year={this.state.currYear}
                                onNextMonth={this.handleNextMonth}
                                onPrevMonth={this.handlePrevMonth}
                                onEventTap={this.handleCalendarDayTap}
                                onDayTap={this.handleCalendarDayTap}

                                StartDate={this.state.StartDate}
                                EndDate={this.state.EndDate}

                                isWeekView={this.state.isWeekView}
                                onWeekViewChange={this.handleWeekChangeClick}
                                events={events}
                                thisWeek={this.setCurrentWeek}
                                isThisWeekDisabled={
                                    this.state.StartDate.format('YYYY-MM-DD') === moment()
                                        .startOf('week')
                                        .format('YYYY-MM-DD')
                                }
                            />
                        </div>
                    </div>
                </LayoutDashboardMain>

            </LayoutDashboard>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        resource: state.resource
    };
};

const mapDispatchToProps = (dispatch) => {
    const user = LocalStorage.get("user")

    return {
        getData: getDataSelect({dispatch, user}),
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(CalendarView);
