import React, {Component} from 'react'
import LayoutDashboard from "../../components/layout-dashboard";
import {connect} from "react-redux";
import {getResource} from "../../data/actions/resource";
import LocalStorage from "../../util/localStorage";
import {Field, FieldsManager} from "../../data/services/fields";
import Resources from "../../data/services/resources";
import {
    fillFieldsFromData,
    getProp,
} from "../../util/util";
import {
    CheckIcon,
    ExclamationIcon,
    XIcon,
    BellIcon
} from "@heroicons/react/solid";
import LayoutDashboardHeader from "../../components/layout-dashboard/layout-dashboard-header";
import LayoutDashboardMain from "../../components/layout-dashboard/layout-dashboard-main";
import moment from "moment";
import Loader from "../../components/loader";

class NotificationsPage extends Component {

    constructor(props) {
        super(props);

        this.state = {
            offset: 0,
            limit: 100,
            paginationPage: 1,
            sort: "ASC",
            sortBy: "Name",

            query: "",
            archived: false,

            fields: this.getFields(),
        };
    }

    /** Lifecycle
     ================================================================= */
    componentDidMount() {
        this.fetchData();
    };

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (!this.props.resource.isLoading && !!prevProps.resource.isLoading) {

        }
    }

    /** Data Events
     ================================================================= */
    fetchData = () => {
        this.props.dispatch(getResource({
            user: LocalStorage.get("user"),
            query: this.getQuery(),
            resource: this.getResourceName()
        }))
    };

    /** UI Events
     ================================================================= */
    handleUpdateSort = (sortBy) => {
        this.setState({
            sortBy: sortBy,
            sort: (this.state.sortBy === sortBy) ? (this.state.sort === "ASC" ? "DESC" : "ASC") : "ASC"
        }, () => this.fetchData())
    };

    handleUpdateOffset = (offset, page) => {
        this.setState({
            offset: offset,
            paginationPage: page
        }, () => this.fetchData());
    }

    handleToggleCreateModal = () => {
        this.setState({
            createModalOpen: !this.state.createModalOpen
        })
    }

    handleToggleConfirmModal = (item = null) => {
        this.setState({
            selectedItem: item,
            confirmModalOpen: !this.state.confirmModalOpen
        })
    }

    handleQueryChange = (name, value) => {
        this.setState({
            query: value,
            offset: 0,
            paginationPage: 1
        }, this.fetchData)
    }

    handleTemplateClick = item => {
        this.props.history.push( "template/" + item.TemplateID);
    }


    handleInputChange = (fields, name, value) => {
        return FieldsManager.updateField(fields, name, value);
    }

    /** Helpers
     ================================================================= */
    getPrimaryKey = () => {
        return "TemplateID";
    }

    getResourceName = () => {
        return Resources.NotificationsResource;
    }

    getQuery = () => {
        return {
            limit: this.state.limit,
            offset: this.state.offset,
            sort: this.state.sort,
            sortBy: this.state.sortBy,
            query: this.state.query,
            archived: this.state.archived ? 1 : 0
        }
    }

    getFields = (item = null, excludeFields = null, includeFields = null) => {
        const fieldTemplates = {
            Name: new Field("Name", '', ['empty'], false, "text"),
            Description: new Field("Description", '', [''], false, "textarea"),
        };

        if (excludeFields) {
            excludeFields.forEach((item) => {
                delete fieldTemplates[item];
            });
        }

        if (includeFields) {
            for (const [key] of Object.entries(fieldTemplates)) {
                if (!includeFields.includes(key)) {
                    delete fieldTemplates[key];
                }
            }
        }

        return fillFieldsFromData(fieldTemplates, item);
    };



    /** Render
     ================================================================= */
    render() {
        const {translate} = this.props;

        const data = getProp(this.props, "resource.data.list", []);
        const count = getProp(this.props, "resource.data.count", 0);

        const isLoading = getProp(this.props, "resource.isLoading", false);

        function classNames(...classes) {
            return classes.filter(Boolean).join(' ')
        }

        return (
            <LayoutDashboard
                {...this.props}
            >
                <LayoutDashboardMain>
                    <LayoutDashboardHeader
                        title={translate("page.title.notifications")}
                    >
                    </LayoutDashboardHeader>

                    <div className="p-8">
                        <div className="flow-root">
                            <ul role="list" className="-mb-8">
                                {!isLoading && (
                                    <>
                                        {data.map((event, eventIdx) => (
                                            <li key={eventIdx}>
                                                <div className="relative pb-8">
                                                    {eventIdx !== data.length - 1 ? (
                                                        <span className="absolute top-4 left-4 -ml-px h-full w-0.5 bg-gray-200" aria-hidden="true" />
                                                    ) : null}
                                                    <div className="relative flex space-x-3">
                                                        <div>
                                                          <span
                                                              className={classNames(
                                                                  (event?.Metadata?.Type == 1 ? "bg-yellow-400" : (event?.Metadata?.Type == 2 ? "bg-green-500" : ( event?.Metadata?.Type == 3 ? "bg-red-500" : "bg-primary-500"))),
                                                                  'h-8 w-8 rounded-full flex items-center justify-center ring-8 ring-white'
                                                              )}
                                                          >
                                                              {event?.Metadata?.Type === 1 && (
                                                                  <ExclamationIcon className="h-5 w-5 text-white" aria-hidden="true" />
                                                              )}
                                                              {event?.Metadata?.Type === 2 && (
                                                                  <CheckIcon className="h-5 w-5 text-white" aria-hidden="true" />
                                                              )}
                                                              {event?.Metadata?.Type === 3 && (
                                                                  <XIcon className="h-5 w-5 text-white" aria-hidden="true" />
                                                              )}
                                                              {event?.Metadata?.Type > 3 && (
                                                                  <BellIcon className="h-5 w-5 text-white" aria-hidden="true" />
                                                              )}
                                                          </span>
                                                        </div>
                                                        <div className="min-w-0 flex-1 pt-1.5 flex justify-between space-x-4">
                                                            <div>
                                                                <p className="text-sm text-gray-500">
                                                                    <span className="font-medium text-gray-900">
                                                                        {!!event?.Metadata?.href && (
                                                                            <a href={event.href}>
                                                                                <h4>{event.Title}</h4>
                                                                                <p>{event.Message}</p>
                                                                            </a>
                                                                        )}
                                                                        {!event?.Metadata?.href && (
                                                                            <>
                                                                                <h4>{event.Title}</h4>
                                                                                <p>{event.Message}</p>
                                                                            </>
                                                                        )}
                                                                    </span>
                                                                </p>
                                                            </div>
                                                            <div className="text-right text-sm whitespace-nowrap text-gray-500">
                                                               {moment(event.DateTime).format("MM/DD/yyyy h:mm A")}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </li>
                                        ))}
                                    </>
                                )}

                                {isLoading && (
                                    <div className={"text-center block w-full m-4"}>
                                        <Loader/>
                                    </div>
                                )}
                            </ul>
                        </div>
                    </div>

                </LayoutDashboardMain>
            </LayoutDashboard>
        )
    }
}

export default connect(state => state)(NotificationsPage);
